@import '../../../../../../assets/styles/variables.scss';
@import '../../../../../../assets/styles/mixin.scss';

.step {
  padding-top: 159px;
  overflow: hidden;

  &__container {
    background-color: $color-background-primary;
  }

  @media (max-width: 1023px) {
    padding: 59px 50px 0;
  }

  @media (max-width: 767px) {
    padding: 59px 20px 0;
  }

  &__header {
    display: flex;
    margin-bottom: 152px;

    @include for-size(fullhd) {
      width: 1920px;
      margin: 0 auto 152px;
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      text-align: center;
      margin-bottom: 63px;
    }

    &-title {
      padding: 0 0 0 115px;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.01em;
      color: $color-text-secondary;
      margin-right: 70px;

      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 23px;
        padding: 0;
        margin: 0 11px 0 0;
      }
    }

    &-subtitle {
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      letter-spacing: -0.01em;

      @media (max-width: 1023px) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    &-button {
      display: none;

      @media (max-width: 1023px) {
        display: block;
        font-size: 16px;
        line-height: 23px;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #fff;
        background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
        border-radius: 6px;
        padding: 18px 37px;
        margin: 25px auto 0;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  &__content {
    &-block {
      margin-right: 150px;

      &:nth-child(1) {
        margin-left: 285px;
      }

      @media (max-width: 1023px) {
        margin: 0 0 44px 0;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-number {
        margin-bottom: 21px;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #afb3ce;

        @media (max-width: 1023px) {
          font-size: 16px;
          line-height: 23px;
          margin-bottom: 12px;
          text-align: center;
        }
      }

      &-title {
        width: 580px;
        font-weight: 700;
        font-size: 55px;
        line-height: 68px;
        letter-spacing: -0.01em;
        margin-bottom: 50px;

        @media (max-width: 1023px) {
          font-size: 26px;
          line-height: 32px;
          margin-bottom: 0px;
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  &__wave {
    width: 100%;
    margin-bottom: 38px;
    position: relative;
    z-index: 1;
  }
}

.slider {
  display: flex;
  cursor: pointer;
  transition: transform 0.6s ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__container {
    position: relative;

    &-prev {
      display: none;
      left: 0;
      bottom: 1px;
      position: absolute;
      width: 50px;
      background: #afb3ce50;
      height: 30%;
      z-index: 10;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      @media screen and (min-width: 1024px) {
        display: flex;
      }

    }

    &-next {
      display: none;
      z-index: 10;
      right: 0;
      bottom: 1px;
      border-bottom: 1px;
      position: absolute;
      width: 50px;
      background: #afb3ce50;
      height: 30%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      @media screen and (min-width: 1024px) {
        display: flex;
      }

    }

    &_icon {
      width: 50%;
      margin: auto;
    }
  }

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }
  position: relative;

  &__button {
    display: block;
    margin-left: 287px;
    margin-bottom: 19px;
    cursor: pointer;

    @include for-size(fullhd) {
      width: 1920px;
      margin: 0 auto 19px;
      padding-left: 287px;
    }

    @media (max-width: 1023px) {
      display: none;
    }

    &:hover {
      opacity: 0.9;
    }

    &-content {
      padding: 18px 37px;
      position: relative;
      background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
      border-radius: 6px;
      font-weight: 500;
      color: #fff;
    }
  }
}
