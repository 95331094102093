.react-timekeeper {
  position: relative;
  z-index: 1000;
  margin-top: 10px;
  border: 1px solid #e3e4ec;
  box-shadow: none !important;
  border-radius: 3px !important;
  color: #332256 !important;

  &__clock {
    &-wrapper {
      background: #e3e4ec !important;
    }

    &-hand {
      stroke: #ff2a5e !important;
    }

    &-minutes,
    &-hours {
      span {
        color: #332256 !important;
      }
    }
  }

  &__tb-minute,
  &__tb-hour,
  &__tb-colon {
    color: #332256 !important;
  }
  &__tb-hour--active,
  &__tb-minute--active {
    color: #ff2a5e !important;
  }

  &-button-reset {
    color: #ff2a5e !important;
  }

  &__time-dropdown,
  &__dropdown-numbers {
    display: none !important;
  }

  &__hand-circle-outer,
  &__hand-circle-center {
    fill: #ff2a5e !important;
  }

  &__meridiem {
    &-toggle {
      background: #fff !important;
      color: #332256 !important;
    }

    &--active {
      background: #ff2a5e !important;
      color: #fff !important;
    }
  }
}

.custom-time-picker {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto;
  color: #332256;
  width: 150px;
  height: 50px;
  border: 1px solid #e3e4ec;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fff url('../../../../assets/images/vectors/time-picker.svg')
    right 20px center no-repeat;
  cursor: pointer;
  text-align: center;
  margin: -1px 0 0 -1px;

  @media (max-width: 767px) {
    width: calc(100% + 2px);
    text-align: left;
  }

  &__disabled {
    outline: none;
    box-sizing: border-box;
    border-radius: 6px;
    width: 150px;
    height: 50px;
    background: #fafafa;
    border-right: 1px solid #e3e4ec;
    border-bottom: 1px solid #e3e4ec;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &:focus {
    outline: none;
    border: 1px solid #6443a7;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;
  }

  &__errors {
    outline: none;
    border: 2px solid red;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;
    width: 150px;
    height: 50px;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &-value,
  &-value-empty {
    padding: 14px 15px 0 0;
    display: block;

    @media (max-width: 767px) {
      padding: 12px 8px;
    }

    &-empty {
      color: #b8bac8;
    }
  }

  &__btn {
    display: none;
  }
}
