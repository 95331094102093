@import '../../../../../../assets/styles/mixin';

.attach {
  width: 600px;
  min-height: 600px;
  @include for-size(tablet-phone) {
    width: 100%;
  }

  &-pick {
    margin-bottom: -30px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      border: 1px solid #e3e4ec;
      margin-top: 20px;
      padding: 5px 10px;
      transition: all 0.3s ease-in-out;

      &:hover {
        box-shadow: 0px 0px 10px 0px #708090;
      }

      &__title {
        width: 60%;
        padding-left: 15px;
        word-break: break-word;
      }

      &__icon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
        @include for-size(tablet-phone) {
          max-width: 100%;
        }
      }

      &__btn {
        width: 100px;
        height: 40px;
        background: #fff;
        color: #ff2a5e;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        outline: none;
        border: 1px solid #e3e4ec;
        padding: 0;
      }

      &-empty {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }

  &-create {
    &__item,
    &__item-header {
      margin-top: 20px;

      &-btn {
        width: 100px;
        height: 40px;
        background: #fff;
        color: #ff2a5e;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        outline: none;
        border: 1px solid #e3e4ec;
        display: block;
        padding: 0;
        margin: 30px auto -30px;
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.tab-attach {
  display: flex;
  justify-content: space-between;

  @include for-size(tablet-phone) {
    flex-direction: column;
  }

  &__item,
  &__item-active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49%;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
    background: white;
    border: 0;
    outline: 0;
    border-radius: 10px;
    border: 1px solid #e3e4ec;
    color: #ff2a5e;
    transition: all 0.1s ease-in-out;

    &:hover {
      box-shadow: 0px 0px 10px 0px #708090;
    }
    @include for-size(tablet-phone) {
      width: 100%;
      margin: 5px 0;
    }
    &-active {
      background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
      border: 1px solid #ff1b71;
      color: #fff;
    }
  }
}
