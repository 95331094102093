.open-ticket {
  width: 740px;
  border-radius: 10px;
  background: #fff;

  @media (max-width: 767px) {
    width: 345px;
  }

  @media (max-width: 374px) {
    width: 280px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 40px 24px;
    border-bottom: 1px solid #e3e4ec;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding: 20px;
    }

    &-left {
      &-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 5px;

        @media (max-width: 767px) {
          font-size: 17px;
          line-height: 28px;
          margin: 24px 0 2px 0;
        }
      }

      &-data {
        font-size: 13px;
        line-height: 22px;
        color: #757995;
      }
    }

    &-right {
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      background: #34b458;
      border-radius: 4px;
      padding: 6px 20px;

      &.closed {
        background: #757995;
      }
    }
  }

  &__bottom {
    padding: 46px 40px 40px;

    @media (max-width: 767px) {
      padding: 20px;
    }

    &-message {
      font-size: 15px;
      line-height: 22px;
      color: #757995;
      margin-bottom: 48px;

      @media (max-width: 767px) {
        margin-bottom: 33px;
      }
    }

    &-comment {
      margin-bottom: 32px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }

      &__item {
        display: flex;
        background: #f7f8fb;
        border-radius: 10px;
        padding: 30px;
        margin-bottom: 32px;

        @media (max-width: 767px) {
          padding: 30px 20px;
          margin-bottom: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-left {
          margin-right: 16px;
          margin-top: 3px;

          &-avatar {
            width: 48px;
            height: 48px;
          }
        }

        &-right {
          &-name {
            font-weight: 500;
            font-size: 15px;
            line-height: 30px;
            margin-bottom: 2px;
          }

          &-data {
            font-size: 13px;
            line-height: 22px;
            color: #a1a5bf;
            margin-bottom: 26px;
          }

          &-message {
            font-size: 15px;
            line-height: 22px;
            color: #757995;

            @media (max-width: 767px) {
              margin-left: -60px;
            }
          }
        }
      }
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 767px) {
        justify-content: center;
      }

      &-open {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        border: 1px solid #b8bac8;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        padding: 16px 31px;
        background: #fff;
        cursor: pointer;
        margin-right: 19px;

        @media (max-width: 767px) {
          width: 50%;
        }

        &:hover {
          opacity: 0.9;
        }
      }

      &-reply {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        border: none;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
        padding: 17px 32px;
        cursor: pointer;
        color: #fff;
        background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);

        @media (max-width: 767px) {
          width: 50%;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
