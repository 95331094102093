@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/mixin';

.work-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1920px) {
    overflow: hidden;
  }

  @media (max-width: 1200px) {
    display: none;
  }

  &__img {
    transition: all 0.3s ease-in-out;

    &-second {
      transition: all 0.3s ease-in-out;
      margin-left: -100px;

      @include for-size(fullhd) {
        margin-left: 0;
      }

      &:hover {
        transform: translateY(-50px);
      }
    }

    &-first,
    &-third {
      transition: all 0.3s ease-in-out;
      margin-right: -100px;

      @include for-size(fullhd) {
        margin-right: 0;
      }

      &:hover {
        transform: translateY(-50px);
      }
    }

    &:hover {
      transform: translateY(-50px);
    }
  }

  &-left {
    width: 46%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 159px;
    text-align: left;

    &-first,
    &-second {
      margin-left: 115px;
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 21px;
      margin-top: 81px;
    }

    &-second {
      margin-top: 150px;
    }

    &-subtitle {
      margin-left: 115px;
      margin-bottom: 110px;
      white-space: pre-wrap;
      overflow: auto;
      width: 53%;

      p {
        color: $color-text-light;
        font-size: 17px;
        line-height: 26px;
        font-weight: 400;

      }
    }
  }

  &-center {
    width: 8%;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-first {
      width: 94px;
      height: 94px;
      background: #ffffff;
      box-shadow: 0px 0px 16px rgba(135, 124, 156, 0.22);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-bebasneue;
      font-size: 50px;
      line-height: 60px;
      color: $color-text-secondary;
      margin: 10px;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }
    }

    &-line {
      border-right: 4px dotted #afb3ce;
      height: 285px;
      margin: 15px 0 15px 0;
    }
  }

  &-right {
    width: 43%;
    margin-top: 118px;
    margin-left: 4.5%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-title {
      align-items: flex-start;
      display: flex;
      align-self: start;
      margin-bottom: 21px;
      p{
        font-size: 28px;
        line-height: 35px;
        text-align: left;
      }
    }

    &-subtitle {
      align-self: start;
      text-align: left;
      margin-bottom: 166px;
      white-space: pre-wrap;
      overflow: auto;
      width: 53%;
      p{
        font-size: 17px;
        line-height: 26px;
        color: $color-text-light;
        font-weight: 400;
      }
    }
  }
}
