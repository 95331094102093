.pricing-main {
    width: 100%;
    height:  fit-content;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
}

.pricing-block-text{
    font-size: 15px;
    position: relative;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
    margin-top: 15px;
}
.pricing-block {
    position: relative;
   
    border-radius: 100px;
    border: 1px solid black;
    height: 54px;
    width: 165px;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
}
.pricing-title {
    position: relative;
    font-size: 55px;
    line-height: 66px;
    text-align: center;
    font-weight: 600;
    color: rgba(51, 34, 86, 1);
    height: 66px;
    width: 918px;
    margin-left: auto;
    margin-right: auto;
}


.pricing-img {
    width: 387px;
    height: 800px;
    position: relative;
}

.pricing-cards{
    margin: auto;
    width: 1201px;
    height: 800px;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 88px;
    gap: 20px;
}


@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .pricing-title {
        font-size: 55px;
        line-height: 66px;
    }
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .pricing-title {
        font-size: 55px;
        line-height: 66px;
    }
}

@media screen and (min-width: 392px) and (max-width: 639px) {
    .pricing-title {
        font-size: 40px;
        line-height: 48px;
    }
    .pricing-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 320px) and (max-width: 391px) {
    .pricing-title {
        font-size: 40px;
        line-height: 48px;
    }
    .pricing-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
}

@media screen and (min-width: 320px) and (max-width: 1200px) {

    .pricing-main{
        text-align: left;
    }
    .pricing-cards{
        margin: auto;
        width: 80%;
        height: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 88px;
        gap: 20px;
    }
    .pricing-img {
        width: 387px;
        height: 800px;
        position: relative;
        margin: auto;
    }
    .pricing-title {
        position: relative;
        text-align: left;
        font-weight: 600;
        color: rgba(51, 34, 86, 1);
        height: fit-content;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}