@import "../../../../../assets/styles/mixin";

.choice-bloggers-form {
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 44px;
  }

  &__item {
    flex-basis: 48%;

    &:nth-last-child(n + 3) {
      margin-bottom: 26px;
    }

    @include for-size(tablet-phone) {
      flex-basis: 100%;
      margin-bottom: 26px;

    }
  }

  &__description {
    margin-bottom: 19px;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;

    color: #9599b5;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    @include for-size(phone) {
    flex-direction: column-reverse;
      button{
        margin: 10px 0;
      }
    }
  }
}
