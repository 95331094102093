@import '../../../../assets/styles/mixin';

.instagram-statistics + .modal-close {
  top: 35px;
  right: 35px;
  @include for-size(phone) {
    top: 25px;
  }
}

.instagram-statistics {
  background: #fff;
  padding: 35px;

  display: flex;
  flex-direction: column;
  @include for-size(phone) {
    padding: 10px;
  }

  &-title {
    font-family: Gordita;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #332256;
    margin-bottom: 40px;
    @include for-size(phone) {
      margin-bottom: 35px;
      margin-top: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 80px;
      height: 1px;
      width: 100%;
      background: #e3e4ec;
      @include for-size(phone) {
        top: 70px;
      }
    }
  }

  &__label {
    border: 1px solid #e3e4ec;
    margin: 12px 0;
    padding: 15px;
    border-radius: 5px;
    position: relative;

    p {
      width: 70%;
      @include for-size(phone) {
        width: 60%;
        font-size: 14px;
      }
    }

    width: 35vw;

    @include for-size(laptop) {
      width: 40vw;

    }
    @include for-size(tablet) {

      width: 60vw;
    }
    @include for-size(phone) {
      width: 90vw;
    }

    &:after {
      top: 9px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      color: #fff;
      content: attr(data-content);
      height: 30px;
      width: 100px;
      border-radius: 5px;
      background: linear-gradient(
                      90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
    }

    &-all {
      width: 100%;
    }
  }

  &__input {
    display: none;
  }

  button {
    margin: 30px auto 0;
    width: 30%;
  }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-close {
      padding: 0 10px;
      cursor: pointer;
      transition: all 0.3s ease-in;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &-error {
      font-size: 13px;
      color: #f22;
    }
  }

  &__input-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;

    div {
      width: 45%;
      @include for-size(phone) {
        width: 100%;
        margin-top: 5px;
      }

      div {
        width: 100%;

      }
    }
  }
}