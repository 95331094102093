.react-select {
  &-container {
    width: 100%;
  }

  &__control {
    height: 50px;
    border: 1px solid #e3e4ec !important;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0;

    &--is-focused {
      border: 1px solid #6443a7 !important;
      box-sizing: border-box;
      border-radius: 6px;
      box-shadow: none !important;
    }
  }

  &__option {
    padding: 0 !important;

    &--is-selected {
      background: white !important;
      .payment-item__pointer {
        border: 3px solid #ff2a5e;
      }
    }

    &:last-child {
      .payment-item-container {
        border-bottom: none;
      }
    }
  }

  &__value {
    &-container {
      .payment-item__pointer {
        display: none;
      }

      .payment-item__brand-logo-container {
        min-width: unset;
      }
    }
  }

  &__menu-list {
    padding: 0 !important;
    border-radius: 6px;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    svg,
    path {
      color: #ff1b71 !important;
    }
  }
}

.payment-item {
  &-container {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e3e4ec;
    width: 100%;
    padding: 0;
  }
  &__pointer {
    min-width: 14px;
    min-height: 14px;
    margin: 0px 15px;

    border: 1px solid #b8bac8;
    border-radius: 50%;
  }

  &__brand-logo {
    &-container {
      min-width: 65px;
      margin-right: 15px;
      display: flex;
      align-items: center;
    }
  }

  &__brand-name {
    font-size: 14px;
    position: relative; // If you can do vertical align to text inside <p> tag - you can remove it :)
    top: 2.5px;
  }
}

.react-select__menu-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.react-select__menu-list::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
}
