@import '../../../../../../assets/styles/variables.scss';
@import '../../../../../../assets/styles/mixin.scss';

.profitable {
  letter-spacing: -0.01em;
  padding: 0 115px;

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  &__container {
    background-color: $color-text-primary;
  }

  @media (max-width: 1023px) {
    padding: 0 50px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  &__top {
    display: flex;

    @media (max-width: 1150px) {
      flex-direction: column;
      text-align: center;
    }

    &-right {
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }

      @media (max-width: 1150px) {
        margin-top: 47px;
      }
      @media (max-width: 1023px) {
        display: none;
      }
    }

    &-left {
      &-title {
        color: $color-text-secondary;
        margin-bottom: 21px;
        font-size: 18px;
        line-height: 26px;

        @media (max-width: 1023px) {
          font-size: 16px;
          line-height: 23px;
        }
      }

      &-subtitle {
        color: #fff;
        font-weight: 700;
        font-size: 40px;
        line-height: 55px;
        word-wrap: break-word;
        width: 70%;

        @media (max-width: 1150px) {
          font-size: 22px;
          width: 100%;
          line-height: 26px;
        }
      }
    }
  }

  &__wave {
    margin: 141px 0 -6px;
    margin-bottom: -7px;
    width: 100%;

    @media (max-width: 1023px) {
      margin-top: 50px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin-top: 90px;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
      margin-top: 53px;
    }

    &-block {
      margin-bottom: 150px;
      line-height: 24px;
      letter-spacing: -0.01em;
      margin-right: 5%;

      @media (max-width: 1023px) {
        width: 45%;
        text-align: center;
        margin-bottom: 35px;
        margin-right: 10%;

        &:nth-child(2),
        &:nth-child(4) {
          margin-right: 0;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-child(4) {
        margin-right: 0;
      }

      &-title {
        font-weight: 500;
        font-size: 17px;
        margin: 30px 0 19px;
        word-wrap: break-word;
        width: 180px;
        color: #fff;

        @media (max-width: 1023px) {
          font-size: 18px;
          line-height: 24px;
          margin: 15px 0 8px;
          width: 100%;
        }
      }

      &-subtitle {
        font-size: 15px;
        color: #fff;
      }
    }
    &-img {
      display: none;

      @media (max-width: 1023px) {
        display: block;
        margin: 12px auto 80px;
        width: 70%;
        height: 70%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }

        @media (max-width: 767px) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
