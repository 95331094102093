.confirmation-code {
  display: flex;
  justify-content: center;

  &__description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: #332256;
  }

  &-input {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
