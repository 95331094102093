@import '../../../assets/styles/mixin';

.new-company {
  min-width: 1128px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 34px;

  @include for-size(tablet-phone) {
    margin-top: 20px;
    min-width: 100%;
  }
  @include for-size(laptop) {
    min-width: 830px;
  }

  &__left {
    max-width: 740px;
    width: 65.6%;
    display: flex;
    flex-direction: column;
    .new-company__cards-list{
      .blogger-card{
        width: 45%;
        @include for-size(phone) {
          width: 100%;
        }
        }
    }
    @include for-size(tablet-phone) {
      padding: 0 20px;
      max-width:100%;
      width: 100%;

    }

  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 349px;
    width: 30.9%;
    @include for-size(tablet-phone) {
      width: 100%;
      min-width: 100%;
    }
    @include for-size(laptop) {
      min-width: 285px;

    }
  }


  &__cards-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
