@import '../../../../assets/styles/mixin';
.custom-picker,
.custom-picker__errors {
  position: relative;
  padding: 14px 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #332256;
  background: #ffffff;
  border: 1px solid #e3e4ec;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff url('../../../../assets/images/vectors/calendar.svg')
    right 20px center no-repeat;
  cursor: pointer;

  @media (max-width: 767px) {
    max-width: 100%;
    padding: 12px 8px;
    background: #ffffff url('../../../../assets/images/vectors/calendar.svg')
      right 8px center no-repeat;
    font-size: 13px;
    line-height: 19px;
  }

  &::placeholder {
    color: #b8bac8;
  }

  &__container {
    &:nth-child(1) {
      margin-right: 25px;
    }

    @media (max-width: 767px) {
      width: 100%;

      .react-datepicker-wrapper {
        width: 100%;
      }

      &:nth-child(1) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  @include for-size(phone) {
    width: 100%;
  }

  &:focus {
    outline: none;
    border: 1px solid #6443a7;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;
  }

  &__errors {
    outline: none;
    border: 2px solid red;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;

    &:focus {
      outline: none;
      border: 2px solid red;
      box-sizing: border-box;
      box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
      border-radius: 6px;
    }
  }
}

.react-datepicker {
  border: 1px solid #e3e4ec;

  &-popper {
    z-index: 8;
  }

  &__day {
    color: #332256;

    &:hover {
      background: #ff2a5e;
      color: #fff;
    }

    &-name {
      color: #332256;
    }

    &--selected,
    &--keyboard-selected {
      background: #ff2a5e;
      color: #fff !important;

      &:hover {
        background: #ff2a5e;
      }
    }
  }

  &__header {
    background: #e3e4ec;
    border-bottom: none;
    border-top-right-radius: 0px;

    &:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 0.2em;
      border-top-left-radius: 0.2em;
    }
  }

  &__triangle {
    display: none;
  }

  &__current-month {
    color: #332256;
  }

  &__navigation:hover *::before {
    border-color: #fff;
  }

  &__navigation-icon::before,
  &__year-read-view--down-arrow,
  &__month-read-view--down-arrow,
  &__month-year-read-view--down-arrow {
    border-color: #ff2a5e;
  }

  &__day--disabled,
  &__month-text--disabled,
  &__quarter-text--disabled,
  &__year-text--disabled {
    color: #e3e4ec;

    &:hover {
      background: none;
      color: #e3e4ec;
    }
  }
}
