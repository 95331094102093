@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/mixin';

.work {
  text-align: center;
  margin-bottom: 15px;
  font-weight: 500;

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto 15px;
  }

  &-title {
    font-size: 18px;
    line-height: 26px;
    color: $color-text-secondary;
    margin-bottom: 21px;

    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 23px;
      font-weight: 500;
      margin-bottom: 11px;
    }
  }

  &-subtitle {
    line-height: 50px;
    font-size: 40px;
    max-width: 750px;
    margin: auto;
    @media (max-width: 1023px) {
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
    }
  }

  &-button {
    padding: 18px 37px;
    line-height: 26px;
    font-size: 18px;
    background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
    border-radius: 6px;
    font-weight: 500;
    color: #fff;
    position: relative;
    cursor: pointer;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 23px;
    }

    &:hover {
      opacity: 0.9;
    }
  }
}
