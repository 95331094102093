@import '../../../assets/styles/mixin';

.error-component {
  margin: 150px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include for-size(laptop) {
    margin: 15% 0;
  }
  @include for-size(tablet) {
    margin: 10% 0;
  }
  @include for-size(phone) {
    margin: 5% 0;
  }

  &__image {
    @include for-size(phone) {
      width: 100%;
    }
  }

  &__title {
    margin-top: 50px;
    text-align: center;
    color: #332256;
    font-family: Gordita;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
  }

  &__description {
    margin: 5px 0 30px;
    font-family: Gordita;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #9599b5;
  }

  .custom-button {
    font-size: 17px;
    line-height: 24px;
    width: 260px;
    margin: auto;
  }
}
