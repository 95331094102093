@font-face {
  font-family: 'Gordita';
  font-weight: 400;
  font-style: normal;
  src: local('Gordita'),
    url(../fonts/Gordita/Gordita-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Gordita';
  font-weight: 500;
  font-style: normal;
  src: local('Gordita'),
    url(../fonts/Gordita/Gordita-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gordita';
  font-weight: 700;
  font-style: normal;
  src: local('Gordita'),
    url(../fonts/Gordita/Gordita-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 400;
  font-style: normal;
  src: local('Bebas Neue'),
    url(../fonts/BebasNeue/BebasNeue-Regular.ttf) format('truetype');
}
