.faq-landing-new-main {
    width: 100%;
    height:  fit-content;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
}

.faq-landing-new-block-text{
    font-size: 15px;
    position: relative;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
    margin-top: 15px;
    color: 
    rgba(51, 34, 86, 1);
}
.faq-landing-new-block {
    position: relative;
    border-radius: 100px;
    border: 1px solid black;
    height: 54px;
    width: 223px;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
}
.faq-landing-new-title {
    position: relative;
    font-size: 70px;
    line-height: 77px;
    text-align: center;
    font-weight: 700;
    color: rgba(51, 34, 86, 1);
    height: 132px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}


.faq-landing-new-img {
    width: 600.5px;
    height: 592px;
    position: relative;
}

.faq-landing-new-cards {
    margin: auto;
    margin-top: 75px;
    width: 1201px;
    height: 1926px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Используем repeat() для установки 2 столбцов */
    grid-template-rows: repeat(3, 1fr); /* Используем repeat() для установки 3 строк */
}

.faq-landing-new-container {
    position: relative;
    width: 1200px;
    height: fit-content;
    margin: auto;
}

.faq-landing-new-item {
    margin-bottom: 20px;
    width: 1200px;
    position: relative;
    height: 120px;
}

.faq-landing-new-question {
    display: block;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    color: rgba(51, 34, 86, 1);
    text-align: left;
}

.faq-landing-new-answer {
    display: none;
    padding: 10px;
    font-size: 15px;
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    color: rgba(51, 34, 86, 1);
    text-align: left;

}

.faq-landing-new-checkbox{
    position: absolute;
    right: 0px;
}

.faq-landing-new-answer-show {
    display: block;
}

.faq-landing-new-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ccc;
    cursor: pointer;
}

#toggle {
    display: none;
}

#toggle:checked + .icon::before {
    content: '✖'; /* Символ "✖" при активированном чекбоксе */
}

.faq-landing-new-icon::before {
    content: '+'; /* Исходный символ "+" */
    display: block;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: #333;
}


@media screen and (min-width: 1024px) and (max-width: 1439px) {

}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .faq-landing-new-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 392px) and (max-width: 639px) {
    .faq-landing-new-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 320px) and (max-width: 391px) {
    .faq-landing-new-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
}

@media screen and (min-width: 320px) and (max-width: 1200px) {

    .faq-landing-new-main{
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .faq-landing-new-answer{
        font-size: 18px;
    }
    .faq-landing-new-question{
        font-size: 20px;
    }
    .faq-landing-new-container {
        position: relative;
        width: 95%;
        height: fit-content;
        margin: auto;
    }

    .faq-landing-new-item {
        margin-bottom: 20px;
        width: 100%;
        position: relative;
        height: fit-content;
    }

    .faq-landing-new-title {
        position: relative;
        font-size: 40px;
        line-height: 48px;
        text-align: left;
        font-weight: 700;
        color: rgba(51, 34, 86, 1);
        height: fit-content;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 80px;
    }
}