.news__body {
  &-item {
    width: 31.625%;
    max-width: 370px;
    height: 528px;
    background: #fff;
    margin-right: 2.5625%;
    margin-bottom: 2.5625%;
    border-radius: 15px;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    @media (max-width: 1150px) {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 4%;
      max-width: 100%;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }

      &:nth-child(3n + 3) {
        margin-right: 4%;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        margin-bottom: 4%;
      }

      &:nth-child(6) {
        margin-right: 0;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &:nth-child(4),
      &:nth-child(5) {
        margin-bottom: 20px;
      }
    }

    &-img {
      width: 100%;
      height: 212px;
      object-fit: cover;
      border-radius: 15px 15px 0 0;
    }

    &-content {
      padding: 24px 40px 34px 29px;

      &-header {
        display: flex;
        align-items: center;

        img {
          width: 12px;
          height: 12px;
        }

        &-data,
        &-category {
          font-size: 13px;
          line-height: 25px;
          color: #757995;
          margin: 0 0 0 4px;

          @media (max-width: 330px) {
            font-size: 12px;
          }
        }

        &-data {
          margin: 0 21px 0 8px;
        }
      }

      &-body {
        margin-top: 18px;
        height: 146px;

        &-title {
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #332256;
          max-height: 52px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }

        &-subtitle {
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: #757995;
          max-height: 88px;
          margin-top: 9px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
      }

      &-button {
        font-size: 14px;
        line-height: 25px;
        outline: none;
        color: #332256;
        padding: 9px 19px;
        background-color: #fff;
        border: 1px solid #d9dbe7;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s ease-in;
        margin-top: 24px;

        &:hover {
          background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
          color: #fff;
          border: 1px solid #fff;
        }
      }
    }
  }
}
