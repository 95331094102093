@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/mixin';

.footer {
  border-bottom: 1px solid #ebecf1;

  &-container {
    padding: 69px 115px 0px;
    display: flex;
    margin-bottom: 54px;

    @include for-size(fullhd) {
      width: 1920px;
      margin: 0 auto 54px;
    }

    @media (max-width: 1023px) {
      padding: 49px 50px 0;
      margin-bottom: 67px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 767px) {
      padding: 49px 20px 0;
    }
  }

  &-left {
    width: 40%;
    margin-right: 10%;

    @media (max-width: 1023px) {
      margin-right: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }

    &__text {
      padding-top: 25px;
      padding-bottom: 49px;
      font-size: 18px;
      line-height: 28px;
      width: 370px;

      @media (max-width: 1023px) {
        width: 100%;
        font-size: 15px;
        line-height: 22px;
        padding: 15px 0 19px;
      }
    }

    &__social {
      &-item {
        cursor: pointer;
        margin-right: 14px;

        &:nth-child(4) {
          margin-right: 0;
        }

        &:hover {
          filter: invert(93.8%) sepia(83%) saturate(2100%) hue-rotate(220deg)
            brightness(140%) contrast(130%);
        }
      }

      @media (max-width: 1023px) {
        margin-bottom: 51px;
      }
    }
  }

  &-center {
    width: 20%;
    margin-right: 10%;
    position: relative;

    @media (max-width: 1023px) {
      width: 45%;
      margin-right: 10%;
      text-align: center;
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.01em;
    }

    &-section {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      line-height: 34px;
      margin: 15px 0;

      @media (max-width: 1023px) {
        align-items: center;
        justify-content: center;
      }

      &-payment {
        margin-left: -10px;
        height: 0;

        @media (max-width: 1023px) {
          margin-right: -120%;
        }

        &-img {
          cursor: pointer;
          transition: all 0.2s linear;
          width: 74px;
          height: 55px;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      &__item {
        &:nth-child(4) {
          margin-bottom: 20px;
        }

        &-btn {
          cursor: pointer;
          color: #8d91ab;
          background: none;
          border-radius: 0px;
          font-weight: 400;
          transition: all 0.1s linear;

          &:hover {
            color: #ff3f74;
          }
        }
      }
    }
  }

  &-right {
    width: 20%;

    @media (max-width: 1023px) {
      width: 45%;
      text-align: center;
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.01em;
    }

    &-section {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      line-height: 34px;
      color: #8d91ab;
      margin: 15px 0;

      @media (max-width: 1023px) {
        align-items: center;
        justify-content: center;
      }

      &__item {
        &:nth-child(4) {
          margin-bottom: 20px;
        }

        &-btn {
          cursor: pointer;
          color: #8d91ab;
          transition: all 0.1s linear;

          &:hover {
            color: #ff3f74;
          }
        }
      }
    }
  }

  &-confirmation {
    padding: 45px 115px 0;
    margin-bottom: 47px;
    display: flex;
    align-items: center;

    @include for-size(fullhd) {
      width: 1920px;
      margin: 0 auto 47px;
    }

    @media (max-width: 1023px) {
      padding: 23px 50px 0;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 43px;
      padding: 23px 20px 0;
    }

    @media (max-width: 375px) {
      padding: 23px 20px 0;
    }

    &__item {
      width: 50%;
      font-size: 15px;
      line-height: 34px;
      color: #9599b5;
      display: flex;
      align-items: center;

      &:nth-child(2) {
        margin-right: 10%;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 20%;
      }

      &-btn {
        cursor: pointer;
        color: #9599b5;
        transition: all 0.1s linear;

        &:hover {
          color: #ff3f74;
        }
      }

      @media (max-width: 1023px) {
        display: flex;
        justify-content: space-around;
        width: 33%;

        &:nth-child(2),
        &:nth-child(3) {
          margin-right: 0;
          width: 33%;
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          margin-bottom: 10px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }
}
.footer-confirmation__item-ts{
  cursor: pointer;
  color: #8d91ab;
  background: none;
  border-radius: 0px;
  font-weight: 500;
  cursor: auto;
}