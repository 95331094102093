.support__right-bottom {
  height: auto;
  border-radius: 10px;
  background: #fff;

  &-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &__img {
      width: 43px;
      height: 43px;
      margin: 0 auto;
    }

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin: 23px 0 11px;
    }

    &__subtitle {
      font-size: 15px;
      line-height: 22px;
      color: #757995;
      margin: 0 0 31px 0;
    }

    &__button {
      background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
      border-radius: 6px;
      outline: none;
      border: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #ffffff;
      padding: 17px 0;
      cursor: pointer;
      max-width: 280px; // remove after added support AddTicket
      width: 100%; // remove after added support AddTicket
      margin: 0 auto; // remove after added support AddTicket

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
