@import '../../../../assets/styles/variables.scss';

.support__left {
  &-bloggerly {
    @media (max-width: 1023px) {
      display: none;
    }
  }

  &-faq {
    margin: 83px 0 0 0;

    @media (max-width: 1023px) {
      margin: 0;
    }

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 20px 0;
    }

    &__plus {
      content: url('../../../../assets/images/support/minus.svg');
      transition-timing-function: ease-in-out;
      transform: rotate(180deg);
      transition: all 0.5s;
      position: absolute;
      left: 12px;
      top: 18px;
    }

    &__minus {
      content: url('../../../../assets/images/support/plus.svg');
      transition-timing-function: ease-in-out;
      transition: all 0.5s;
      position: absolute;
      left: 12px;
      top: 12px;
    }

    &__panel-plus {
      border-radius: 0 0 15px 15px;
      color: $color-text-light;
      box-sizing: border-box;
      padding: 0 29px 38px 29px;
      background: #fff;
      overflow: hidden;
      opacity: 1;
      width: 100%;
      font-size: 15px;
      line-height: 24px;
    }

    &__panel-minus {
      overflow: hidden;
      height: 0;
    }

    &__content {
      position: absolute;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      right: 14px;
      top: 8px;
    }

    &__container {
      background-color: #fff;
      letter-spacing: -0.01em;
      border-radius: 14px;
      padding: 17px 29px;
      cursor: pointer;
      border: none;
      font-size: 17px;
      line-height: 24px;

      @media (max-width: 1023px) {
        padding: 17px 50px 17px 29px;
      }
    }

    &__block-active,
    &__block-inactive {
      border-radius: 14px;
      border-radius: 14px;
      margin-bottom: 10px;
      position: relative;
      border: 1px solid;
      transition-timing-function: ease-out;
      transition: all 0.3s;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__block-active {
      border-color: $color-text-secondary;
    }

    &__block-inactive {
      border-color: #e3e4ec;
    }
  }
}
