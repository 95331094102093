@import '../../../../../../assets/styles/mixin';

.custom-time,
.custom-time__errors {
  position: relative;
  padding: 14px 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto;
  color: #332256;

  background: #ffffff;
  border: 1px solid #e3e4ec;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff
    url('../../../../../../assets/images/vectors/time-picker.svg') right 20px
    center no-repeat;

  cursor: pointer;

  &__label {
    margin-bottom: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #332256;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__dual {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &-item {
      width: 30%;
      margin-right: 10px;
    }

    &-from,
    &-to {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #9599b5;
      margin-right: 20px;

      @media (max-width: 767px) {
        margin: 0 0 6px;
      }
    }

    &-to {
      margin: 0 20px;

      @media (max-width: 767px) {
        margin: 6px 0;
      }
    }
  }

  &__single {
    &-item {
      flex-grow: 1;
    }
  }

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 2.5rem;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;

    opacity: 0;
    cursor: pointer;
  }
}

.custom-time {
  @include for-size(phone) {
    width: 100%;
  }

  &:focus {
    outline: none;
    border: 1px solid #6443a7;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;
  }
}

.custom-time__errors {
  &:focus {
    outline: none;
    border: 2px solid red;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;
  }
}

input[type='time']::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute-field {
  background-color: #fff;
  color: #332256;
}
