@import './_fonts.scss';
@import './_variables.scss';
@import './_mixin.scss';

// appearance landing
@keyframes fromRight {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fromLeft {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.container {
  padding: 36px 44px;
  box-sizing: content-box;
  width: 1128px;
  @include for-size(phone) {
    width: 100%;
    padding: 0;
  }
  @include for-size(laptop) {
    width: 100%;
    padding: 36px 20px;
  }
}

// paginaition
.pagination {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 15px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
}

.pagination > li {
  user-select: none;
  outline: none;
  display: inline-block;
  padding-left: 0;
  padding: 0 5px;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: #202438;
  color: #fff7e8;
  margin-right: 10px;

  @media (max-width: 767px) {
    margin: 0 5px 5px 0;
  }
}

.pagination > li {
  list-style: none;
  background-color: #fff;
  border: 1px solid #e3e4ec;
}

.pagination > li.break {
  display: none;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  outline: none;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #fff7e8;
  color: #ff2a5e;
  font-weight: 500;
  font-size: 18px;
  line-height: 135%;
}

.pagination > li.active {
  background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}

.pagination > li.disabled {
  display: none;
}

.pagination > li.active > a {
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 135%;
  color: #fff;

  letter-spacing: -0.01em;
}

.pagination > li > a:hover {
  cursor: pointer;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  outline: none;
  color: #fff;
}

.pagination > li:last-child,
.pagination > li:first-child {
  background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: -0.01em;
  color: #fff7e8;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}