.bloggers-filtering-form {
  &__buttons {
    &-first {
      margin-right: 16px;
    }
    display: flex;
    justify-content: flex-end;
    margin: 20px auto auto;
  }
}
