@import '../../../../assets/styles/mixin';
.field__description {
  flex-basis: 52%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #9599b5;
  box-sizing: border-box;
  padding: 0 24px;
  @include for-size(tablet-phone) {
    flex-basis: 100%;
    padding: 0;
    margin: 10px 0 0;
  }
  @include for-size(phone) {
    padding: 0;
    margin: 10px 0 0;
  }
  &-money {
    padding: 20px 69px 0 0;
  }
}
