.landing-new-footer-main {
    width: 100%;
    display: flex;
    height:  fit-content;
    padding-bottom: 55px;
    flex-direction: column;
    background-image: linear-gradient(to left,rgba(255, 64, 114, 1), rgba(255, 111, 71, 1));
}


.landing-new-footer-button {
    background: rgba(255, 255, 255, 1);
    width: 1200px;
    height: 140px;
    border: 1px solid black;
    border-radius: 50px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    
}
.landing-new-footer-button:hover {
    background: rgb(242, 242, 242);
}
.landing-new-footer-button-text {
    font-size: 32px;
    line-height: 38.4px;
    font-weight: 700;
    color: rgba(35, 35, 35, 1);
    margin-top: 50px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    
}
.landing-new-footer-content{
    display: flex;
    flex-direction: row;
    color: white;
    margin-left: auto;
    margin-right: auto;
    gap: 50px;
    width: 1200px;
    margin-top: 50px;
}

.landing-new-footer-nav{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 10px;
    width: fit-content;
    margin-left: 300px;
}
.landing-new-footer-nav-header{
    display: flex;
    flex-direction: column;
    color: white;
    line-height: 19.2px;
    font-size: 16px;
    text-align: left;
    font-weight: 500;
}
.landing-new-footer-nav-element{
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    width: 400px;
    line-height: 28.8px;
    font-size: 24px;
    font-weight: 500;
}
.landing-new-footer-about{
    display: flex;
    flex-direction: column;
    color: white;
    width: 385px;
    gap: 20px;
    margin-left: 0px;
}

.landing-new-footer-socials{
    display: flex;
    flex-direction: row;
    color: white;
    width: 199px;
    height: 59px;
    gap: 20px;
}

.landing-new-footer-icon{
    width: 285px;
    height: 66.93px;
}

.landing-new-footer-content-text{
    line-height: 28px;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
}

.landing-new-footer-delimiter{
    background-color: white;
    height: 2px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}


.landing-new-footer-last{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

.landing-new-footer-last-text{
    color: white;
    line-height: 19.5px;
    font-size: 16px;
    font-weight: 500;
}

@media screen and (min-width: 320px) and (max-width: 1200px) {

    .landing-new-footer-main {
        width: 100%;
        display: flex;
        height:  fit-content;
        padding-bottom: 55px;
        flex-direction: column;
        background-image: linear-gradient(to left,rgba(255, 64, 114, 1), rgba(255, 111, 71, 1));
    }
    .landing-new-footer-content{
        display: flex;
        flex-direction: column;
        color: white;
        margin-left: 50px;
        margin-right: auto;
        gap: 50px;
        width: 40%;
        margin-top: 50px;
    }

    .landing-new-footer-nav{
        margin-left: 0px;
    }
    .landing-new-footer-nav-element{
        text-align: left;
    }
    .landing-new-footer-about{
        margin-left: 0px;
    }

    .landing-new-footer-button {
        background: rgba(255, 255, 255, 1);
        width: auto;
        height: 140px;
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {

}

@media screen and (min-width: 640px) and (max-width: 1023px) {

}

@media screen and (min-width: 392px) and (max-width: 639px) {
    .landing-new-footer-icon{
        width: 200px;
        height: 46.97px;
    }    
    .landing-new-footer-content{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 320px) and (max-width: 391px) {
    .landing-new-footer-icon{
        width: 200px;
        height: 46.97px;
    }

    .landing-new-footer-content{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .landing-new-footer-nav{
        width: 100%;

    }
    .landing-new-footer-about{
        width: 100%;
    }
}
