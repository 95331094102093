@import '../../../../assets/styles/mixin';

.form-container {
  background: #fff;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 10px 0px #708090;
  }

  &__stories-list,
  &__stories-list-center {
    display: flex;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #332256;

    &-center {
      margin: 15px auto 0;
      text-align: center;
      justify-content: center;
    }
  }

  &-inner {
    padding: 33px 38px;
    @include for-size(tablet-phone) {
      padding: 25px 20px;
    }

    &-money {
      padding: 0;
    }
  }

  &-head {
    padding: 20px 38px;
    min-height: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ededf3;
    @include for-size(phone) {
      overflow: scroll;
      padding: 20px 10px;
      max-width: calc(95vw - 40px);
      margin: auto;
    }

    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;

      color: #332256;
    }
  }
}
