.info-text {
  background: #fff;
  padding: 25px 10px 10px;
  border-radius: 5px;
  max-width: 350px;

  &__text {
    margin: 10px;
    padding: 10px 0;
    position: relative;
    text-align: center;
  }
  &__title {
    margin: 5px;
    padding: 10px 0;
    position: relative;
    border-bottom: 1px solid #afb2c0;
    text-align: center;
    font-size: '3vw'
  }
  button {
    max-width: 150px;
    margin: auto;
  }
}