@import '../../../../../../assets/styles/variables.scss';
@import '../../../../../../assets/styles/mixin.scss';

.partnership {
  padding: 114px 115px 113px;
  text-align: center;
  letter-spacing: -0.01em;
  background-color: $color-background-primary;

  @media (max-width: 1023px) {
    padding: 49px 50px 62px;
  }

  @media (max-width: 767px) {
    padding: 49px 20px 62px;
  }

  &__title {
    color: $color-text-secondary;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;

    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 23px;
    }
  }

  &__subtitle {
    margin: 21px 0 47px;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;

    @media (max-width: 1023px) {
      font-size: 22px;
      line-height: 26px;
      margin: 11px 0 36px;
    }
  }

  &__bottom {
    &-break {
      position: absolute;
      left: 0;
      margin-top: -40px;
      width: 257px;
      height: 119px;

      @media (max-width: 1023px) {
        margin-top: -110px;
      }
    }

    &-title {
      margin: 65px 0 28px;
      font-size: 28px;
      line-height: 55px;
      letter-spacing: -0.01em;

      @media (max-width: 1023px) {
        font-size: 20px;
        line-height: 55px;
        margin: 38px 0 8px;
      }
    }

    &-button {
      padding: 18px 37px;
      background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
      border-radius: 6px;
      font-weight: 500;
      color: #fff;
      cursor: pointer;

      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 23px;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include for-size(fullhd) {
      width: 1690px;
      margin: 0 auto;
    }

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    &-block {
      position: relative;
      z-index: 1;
      width: 30.6%;
      background: #fff;
      box-shadow: 0px 0px 32px rgba(51, 34, 86, 0.07);
      height: 432px;
      border-radius: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0 55px;
      transition: all 0.3s ease-in-out;

      @media (max-width: 1023px) {
        width: 335px;
        height: 315px;
        padding: 0 40px;
        margin: 0 auto 20px;

        &:last-child {
          margin: 0 auto;
        }
      }

      @media (max-width: 767px) {
        width: 100%;

        &:last-child {
          margin: 0 auto;
        }
      }

      &:hover {
        transform: scale(1.05);
      }

      &-title {
        margin: 24px 0 20px;
        font-weight: bold;
        font-size: 25px;
        line-height: 29px;
        letter-spacing: -0.01em;
        color: #9599b5;

        @media (max-width: 1023px) {
          font-size: 20px;
          margin: 16px 0 4px;
        }
      }

      &-text {
        font-size: 16px;
        line-height: 25px;
        text-align: center;

        @media (max-width: 1023px) {
          font-size: 15px;
          line-height: 23px;
        }

        &:after {
          content: '';
          display: block;
          height: 1px;
          background-color: #e3e4ec;
          margin: 30px 0;
          width: 100%;
        }
      }

      &-percent {
        font-weight: bold;
        font-size: 45px;
        line-height: 29px;
        letter-spacing: -0.01em;
        color: #9599b5;

        @media (max-width: 1023px) {
          font-size: 36px;
          line-height: 29px;
        }
      }

      &-subtitle {
        font-size: 16px;
        line-height: 25px;
        margin-top: 10px;
        text-align: center;
        color: #332355;

        @media (max-width: 1023px) {
          font-size: 15px;
          line-height: 23px;
          margin-top: 6px;
        }
      }
    }
  }
}
