@import '../../../assets/styles/mixin';

.news-item {
  padding: 38px 0 0;
  min-height: 100vh;
  cursor: default;
  overflow: hidden;

  @media (max-width: 1023px) {
    padding: 52px 0 0;
  }

  &__navigation {
    padding: 0 165px;

    @media (max-width: 1365px) {
      padding: 0 115px;
    }

    @media (max-width: 1023px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  &__banner {
    width: 100%;

    &-img {
      width: 100%;
      margin: 41px auto 76px 0;
      height: 487px;
      object-fit: cover;

      @media (max-width: 1023px) {
        margin: 25px auto 29px;
        height: 186px;
      }
    }
  }

  &__body {
    padding: 0 165px;

    @include for-size(fullhd) {
      width: 1920px;
      margin: 0 auto;
    }

    @media (max-width: 1365px) {
      padding: 0 115px;
    }

    @media (max-width: 1023px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }

    &-title {
      font-weight: 500;
      font-size: 42px;
      line-height: 60px;

      @media (max-width: 1023px) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      margin-top: 16px;

      @media (max-width: 1023px) {
        margin-top: 18px;
      }

      img {
        width: 15px;
        height: 15px;
      }

      &-data,
      &-category {
        font-size: 15px;
        line-height: 25px;
        color: #757995;
        margin: 0 0 0 4px;
      }

      &-data {
        margin: 0 21px 0 8px;
      }
    }

    &-description {
      margin: 48px 0 121px;
      li {
        list-style-type: unset;
      }
      section {
        min-height: 100%;
        max-width: 90vw;
        word-break: break-word;

        figure,
        img,
        video,
        iframe {
          max-width: 100%;
          border-radius: 40px;
          object-fit: cover;

          img {
            height: 100% !important;
          }

          @media (max-width: 1023px) {
            border-radius: 30px;
          }
        }

        p,
        span,
        h1,
        h2,
        h3,
        strong,
        h4,
        ul,
        li,
        h5,
        h6,
        u,
        div {
          line-height: 26px;

          @media (max-width: 1023px) {
            line-height: 22px;
          }
        }
      }

      @media (max-width: 1023px) {
        margin: 29px 0 47px;
      }
    }
  }

  &__related {
    height: 790px;
    background: #f0f2f8;
    display: flex;
    flex-direction: column;
    padding: 0 165px;
    width: 100%;

    @media (max-width: 1365px) {
      padding: 0 115px;
    }

    @media (max-width: 1150px) {
      height: auto;
    }

    @media (max-width: 1023px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    &-content {
      display: flex;
      flex-wrap: wrap;
      max-width: 1170px;
      width: 100%;
      margin: 0 auto;

      @media (max-width: 767px) {
        width: 100%;
      }

      &-title {
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        margin: 90px auto 33px;
        max-width: 1170px;
        width: 100%;

        @media (max-width: 1023px) {
          font-size: 18px;
          line-height: 26px;
          margin: 33px auto 12px;
        }
      }
    }
  }
}
