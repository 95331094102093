@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/mixin';

.benefits {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 115px;
  font-weight: 500;

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  &__container {
    background: $color-text-primary;
  }

  @media (max-width: 1023px) {
    padding: 0 50px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  &-title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: $color-text-secondary;

    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 23px;
    }
  }

  &-subtitle {
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin: 21px auto 230px auto;
    max-width: 690px;
    @media (max-width: 1023px) {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      margin: 11px 0 184px 0;
    }
  }

  &-break {
    position: absolute;
    right: 0;
    margin-top: -205px;
  }

  &__center {
    margin: 134px auto 0px;
    text-align: center;

    &-break {
      position: absolute;
      left: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      letter-spacing: -0.01em;
      color: #ffffff;
      margin-bottom: 54px;
    }

    &-button {
      padding: 18px 37px;
      background: $color-text-primary;
      border: 2px solid;
      border-image-source: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
      border: 2px solid;
      display: inline-block;
    }
  }
}

.benefits-content {
  margin: 130px auto 430px;

  @media (max-width: 1023px) {
    margin: 51px auto 311px;

    @media (max-width: 500px) {
      position: relative;
    }
  }

  &-break {
    position: absolute;
    left: 0;

    @media (max-width: 500px) {
      left: -13%;
      top: 170px;
    }
  }

  &-title {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #ffffff;
    margin-bottom: 50px;

    @media (max-width: 1023px) {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 31px;
    }
  }

  &-button {
    color: #fff;
    background: none;
    border: 1px solid #ff2a5e;
    cursor: pointer;
    border-radius: 6px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 267px;
    height: 62px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #fff;
      border: 1px solid #fff;
      color: #ff2a5e;
    }
  }
}

.benefits-break-second {
  position: absolute;
  right: 0;
  margin-top: 590px;
  z-index: 1;

  @media (max-width: 1200px) {
    margin-top: 995px;
  }

  @media (max-width: 650px) {
    margin-top: 1650px;
  }
}
