.notification {
  margin-right: 30px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: default;

  &__loader {
    border-top: 1px solid #f0f2f8;
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;

    &:after {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #ff1b71;
      border-color: #ff1b71 transparent #ff1b71 transparent;
      animation: notification-loader 1.2s linear infinite;
    }
  }

  &__img.active::before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    width: 8px;
    height: 8px;
    right: 0;
    background: #ff2a5e;
    border: 1px solid #f0f2f8;
  }

  &__dropdown {
    position: absolute;
    z-index: 1000;
    top: 31px;
    background-color: #fff;
    width: 339px;
    height: auto;
    box-shadow: 0px 4px 16px rgba(161, 165, 191, 0.3);
    border-radius: 6px;
    z-index: 10;
    transform: translate(-50%, 0);

    @media (max-width: 767px) {
      transform: translate(-50%, 0);

      @media (max-width: 374px) {
        width: 280px;
        transform: translate(-40%, 0);
      }
    }

    &-item {
      width: 20px;
      height: 40px;
      position: absolute;
      align-self: center;
      cursor: pointer;

      &-title {
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        padding: 20px;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      height: auto;
      max-height: 470px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
      }

      &-load {
        display: flex;
        justify-content: center;
        margin: 12px 0 30px;
        font-weight: 400;

        &-btn {
          font-weight: 500;
          font-size: 15px;
          line-height: 21px;
          text-align: center;
          color: #757995;
          border-radius: 6px;
          background: #e5e8f2;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.3s linear;
          background: #e5e8f2;
          padding: 10px 20px;
          position: relative;

          &:hover {
            background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
            color: #fff;
          }
        }
      }

      &-item {
        display: flex;
        align-items: center;
        border-top: 1px solid #e3e4ec;
        transition: all;
        padding: 20px;
        cursor: pointer;
        font-weight: 500;

        &:hover {
          background: rgba(244, 245, 249, 0.5);
        }

        &-information {
          display: flex;
          flex-direction: column;

          &-time {
            font-size: 13px;
            line-height: 14px;
            letter-spacing: 0.01em;
            margin-top: 3px;
            font-weight: 400;
            color: #9599b5;
          }
        }

        &-image {
          min-width: 42px;
          min-height: 42px;
          border-radius: 50%;
          background: rgba(90, 57, 247, 0.13);
          margin-right: 13px;
          position: relative;

          &.bell {
            &::before {
              content: '';
              display: inline-block;
              border-radius: 50%;
              position: absolute;
              top: -10px;
              left: -10px;
              width: 6px;
              height: 6px;
              background: #ff2a5e;
            }
          }

          img {
            position: absolute;
            margin: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

@keyframes notification-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
