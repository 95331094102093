.mobile-statistic {
  display: none;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 767px) {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

  }

  &__item {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;

    @media (max-width: 767px) {
      width: 48%;
      margin-right: 2%;
      margin-bottom: 2%;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media (max-width: 620px) {
      padding: 20px;
      width: 100%;
      margin-right: 0;
      width: 100%;
      margin-bottom: 20px;
    }

    &-header {
      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5px;

        &-title {
          display: flex;
          align-items: center;

          &-header {
            color: #9599b5;
            margin-right: 10px;
            min-width: 32px;
          }
        }

        &-id {
          border: 1px solid #9599b5;
          padding: 5px;
          border-radius: 10px;
          margin-left: 10px;
          min-width: 64px;
          text-align: center;
        }
      }

      &-body {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;

        &-content {
          text-transform: capitalize;
          display: flex;
          align-items: center;

          &-title {
            margin-right: 10px;
            color: #9599b5;
          }
        }

        &-status {
          border: 1px solid #9599b5;
          padding: 5px;
          border-radius: 10px;
          margin-left: 10px;
          min-width: 59px;
        }
      }

      &-bottom {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 20px;
        position: relative;

        &::after {
          border-bottom: 1px solid #ededf3;
          content: '';
          position: absolute;
          height: 1px;
          display: inline-block;
          width: calc(100% + 40px);
          margin: 40px 0 0 -20px;
        }

        &-date {
          &:last-child {
            margin-left: 5px;
          }

          &-space {
            color: #9599b5;
          }
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: center;
      position: relative;

      &::before {
        width: calc(100% + 40px);
        height: 1px;
        border-bottom: 1px solid #ededf3;
        display: inline-block;
        content: '';
        position: absolute;
      }

      &-btn {
        color: #ff2a5e;
        border: 1px solid #e3e4ec;
        font-weight: 500;
        padding: 8px 14px;
        transition: all 0.2s linear;
        border-radius: 6px;
        margin-left: 5px;
        transition: all 0.4s ease;
        position: relative;
        display: inline-block;
        outline: none;
        cursor: pointer;
        width: 100%;
        margin-top: 20px;

        &[disabled] {
          background-color: #fff;
          transition: all 0.3s linear;

          &:hover {
            cursor: not-allowed;
            background: #a1a5bf;
            color: #fff;
          }
        }
      }
    }

    &-body {
      &-bloggers {
        padding: 15px 0;

        &-title {
          color: #9599b5;
          margin-right: 10px;
        }
      }
      &-spent {
        padding-bottom: 15px;

        &-title {
          color: #9599b5;
          margin-right: 10px;
        }
      }
    }
  }
}
