@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/mixin';

.header {
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include for-size(fullhd) {
      width: 1690px;
      margin: 0 auto;
    }

    @media (max-width: 1023px) {
      display: none;
    }

    &-logo {
      width: 12%;
      margin-right: 68px;
    }

    &-auth {
      position: relative;
      display: flex;
      align-items: center;
      z-index: 3;
      min-width: 220px;
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        min-width: 200px;

      }

      &-login {
        cursor: pointer;
        text-decoration: none;
        margin-right: 31px;
        background: none;
        color: $color-text-primary;
        font-size: 18px;
        font-weight: 500;

        &:hover {
          color: #ff3f74;
        }

        @media screen and (min-width: 1024px) and (max-width: 1200px) {
          font-size: 16px;
          margin-right: 20px;
        }
        @media (min-width: 1024px) and (max-width: 1090px) {
          margin-right: 11px;
        }
      }

      &-login-dark {
        cursor: pointer;
        text-decoration: none;
        margin-right: 31px;
        background: none;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        @media screen and (min-width: 1024px) and (max-width: 1200px) {
          font-size: 16px;
          margin-right: 20px;
        }
        &:hover {
          color: #ff3f74;
        }
      }

      &-registration {
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        padding: 11px 24px;
        background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
        border-radius: 6px;
        font-weight: 500;
        color: #fff;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
