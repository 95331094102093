@import '../../../../../../assets/styles/variables.scss';
@import '../../../../../../assets/styles/mixin.scss';

.advantages-referrals {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 115px;
  font-weight: 500;

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  &__container {
    background: $color-background-primary;
  }

  @media (max-width: 1023px) {
    padding: 0 50px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  &-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.01em;
    padding: 0 115px;
    margin-bottom: 50px;
    color: #fff;
    position: relative;
    z-index: 2;

    @include for-size(fullhd) {
      width: 1920px;
      margin: 0 auto 50px;
    }

    @media (max-width: 1023px) {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      margin-top: 11px;
      text-align: center;
      padding: 0;
      margin-bottom: 29px;
    }
  }

  &-break {
    position: absolute;
    right: 0;
    margin-top: 650px;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  &-content {
    margin-top: -200px;
  }
}
