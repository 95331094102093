@import '../../../../assets/styles/mixin';

.conditions {
  width: 100%;
  padding: 0 115px;
  margin-bottom: -29px;

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto -29px;
  }

  @media (max-width: 1023px) {
    padding: 0 50px;
    margin-top: 60px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  &__title,
  &__subtitle {
    color: #ff2a5e;
    font-size: 21px;
    font-weight: 400;
    margin: 40px 0;

    @media (max-width: 1023px) {
      margin: 20px 0;
    }
  }

  &__subtitle {
    color: #332256;
    font-weight: 600;
  }

  &__item {
    &-text {
      margin: 15px 0;
    }

    &-active {
      display: inline-block;
      font-weight: 600;

      &-point {
        margin-bottom: 15px;
        display: inline-block;
        font-weight: 600;
      }
    }

    &-point {
      padding-left: 45px;
      position: relative;
      margin: 2px 0;

      &::before {
        display: flex;
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        left: 30px;
        top: 6px;
        border-radius: 50%;
        background: #332256;
      }
    }
  }
}
