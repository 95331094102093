@import '../../../assets/styles/mixin';


.account-blogger {
  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 30%;
    background: #fff;
    border-radius: 10px;
    margin: 20px 1.6%;
    &:hover {
      box-shadow: 0 0 10px 0 #708090;
    }
    @include for-size(tablet) {
      width: 45%;
      margin: 20px 2%;
    }
    @include for-size(phone) {
      width: 95%;
      margin: 20px 2.5%;
    }
    &-head {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 30px 25px;
      border-bottom: 1px solid #E3E4EC;
      @include for-size(phone) {
        flex-direction: row;
        justify-content: flex-start;
      }
    }

    &-active {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #34B458;
      right: 21px;
      top: 21px;

      &:before {
        content: '\2713';
        position: absolute;
        font-size: 12px;
        top: 3px;
        font-weight: 900;
        color: #fff;
        text-align: center;
        width: 100%;

      }
    }

    &-rejected {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #F22;
      right: 21px;
      top: 21px;

      &:before {
        content: '\2716';
        position: absolute;
        font-size: 12px;
        top: 3px;
        font-weight: 900;
        color: #fff;
        text-align: center;
        width: 100%;

      }
    }

    &-pending {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #656;
      right: 21px;
      top: 21px;
      animation: rotating 4s linear 0s infinite;
      @keyframes rotating {
        0% {
          transform: rotate(360deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }

      &:before {
        content: '\21BA';
        position: absolute;
        font-size: 14px;
        top: 2px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        width: 100%;

      }
    }

    &-statistics {
      position: absolute;
      right: 50px;
      top: 21px;
      svg{
        width: 20px;
        height: 20px;
      }
    }
    &-profile{
      position: absolute;
      right: 75px;
      top: 21px;
      img{
        width: 20px;
        height: 20px;
      }
    }

    &-logo {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      @include for-size(phone) {
        margin-right: 20px;
      }
    }

    &-name {
      margin-top: 10px;
      font-family: Gordita;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      height: fit-content;
      width: 80%;
      text-align: center;
      word-wrap: break-word; /* Перенос слов */ 
      color: #332256;

      @include for-size(phone) {
        margin: 0;
      }
    }


    &-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
    }


    &-button {
      width: 80%;
      background: linear-gradient(90deg, #FF502C 0%, #FF1B71 100%), #FF1B71;
      border-radius: 4px;
      border: none;
      color: #fff;
      font-size: 15px;
      padding: 10px 28px;

      margin: 10px 0;

      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }

      &-remove {
        background: #F0F2F8;
        color: #757995;
      }

      &-disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }

}