@import './fonts.scss';
@import './variables';

*,
html,
body {
  margin: 0;
  font-family: $font-gordita;
  color: $color-text-primary;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

ul {
  margin-left: 0;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: #000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

p {
  margin: 0;
}

select[readonly],
input[readonly],
textarea[readonly] {
  pointer-events: none;
  touch-action: none;
  background: rgb(250, 250, 250);
  border: 1px solid #e3e4ec;
}

body {
  overflow-y: scroll;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
}
