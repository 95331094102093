.tooltip {
  display: inline-block;
  position: relative;

  &-content {
    position: absolute;
    border-radius: 40px;
    left: 40%;
    transform: translateX(-50%);
    padding: 15px;
    color: #ff3f74;
    background: #fff;
    z-index: 100;
    width: 275px;
    text-align: center;
    animation: fadeInTooltip 0.5s ease-in-out;

    @media (max-width: 767px) {
      width: 150px;
      padding: 10px;
      font-size: 12px;
      left: 45%;
    }
  }
}

@keyframes fadeInTooltip {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
}
