.header-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 20px;
}

.header-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
}

.header-nav-element{
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 15px;
}
.header-logo{
    width: 132px;
    height: 31px;
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
    .header-nav{
        width: 0px;
        height: 0px;
        display: none;
    }


}


.header-burger-main{
    width: 100%;
    display: flex;
    height: fit-content;
    flex-direction: column;
    padding-top: 20px;
    background-color: white;
    position: fixed;
    top: 0px;
    z-index: 1000;
}

.header-burger-content{
    width: 100%;
    display: flex;
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    top: 0px;
    z-index: 1000;
}
.header-burger-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.33s ease-out;
    gap: 60px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 40px;

}
.header-burger-nav-open {
    max-height: 400px;
    padding-bottom: 100px;
    
}
.header-burger-nav-element{
    font-family: Inter;
    font-size: 40px;
    text-align: left;

    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
}


@media screen and (min-width: 1024px) and (max-width: 1439px) {

}

@media screen and (min-width: 640px) and (max-width: 1023px) {

}

@media screen and (min-width: 392px) and (max-width: 639px) {

}

@media screen and (min-width: 320px) and (max-width: 391px) {

    
}