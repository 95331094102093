@import '../../../../../assets/styles/mixin';
.compaign-launch {
  width: 734px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 10px;
  }

  &__section {
    display: flex;
    flex-wrap: wrap;
    @include for-size(phone) {
      flex-direction: column;
    }
  }

  &-banner {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__btn {
    &-edit,
    &-save {
      width: 130px;
      height: 40px;
      color: #fff;
      border-radius: 6px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.4s ease;
      position: relative;
      display: inline-block;
      outline: none;
      padding: 0;
    }

    &-save {
      width: 130px;
      height: 40px;
      color: #fff;
      border-radius: 6px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.4s ease;
      position: relative;
      display: inline-block;
      outline: none;
      display: block;
      margin: 15px auto 0;
      padding: 0;
    }

    &-disabled {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 40px;
      outline: none;
      border: none;
      font-style: normal;
      font-weight: 500;
      border-radius: 6px;
      max-height: 56px;
      background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
      color: #fff;

      &[disabled] {
        cursor: not-allowed;

        &:hover {
          background: #e5e8f2;
          color: #757995;
        }
      }
    }
  }

  &__residence {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #332256;
  }
}

.custom-btn {
  background: #fff;
  color: #ff2a5e;
  border: 1px solid #e3e4ec;
  z-index: 0;
}

.custom-btn:after {
  position: absolute;
  content: '';
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
  color: #fff;
  border-radius: 6px;
  transition: all 0.4s ease;
  border-radius: 3px;
}

.custom-btn:hover {
  color: #fff;
}

.custom-btn:hover:after {
  left: 0;
  width: 100%;
}

.success-create {
  text-align: center;
  font-size: 14px;
  margin: 15px;
}
