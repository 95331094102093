@import '../../../../assets/styles/mixin';

.balance {
  margin-right: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  &__arrow {
    margin-left: 7px;
    transition: all 0.2s linear;

    &-active {
      margin-left: 7px;
      transition: all 0.2s linear;
      transform: rotate(180deg);
    }
  }

  &__dropdown {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 25px;
    cursor: default;

    &-item {
      width: 50px;
      height: 40px;
      position: absolute;
      align-self: start;

      &-title {
        @media (max-width: 767px) {
          font-size: 12px;
        }

        &:hover {
          color: #ff2a5e;
          cursor: pointer;
        }
      }
    }
  }
}
