@import '../../../../../../assets/styles/variables.scss';
@import '../../../../../../assets/styles/mixin.scss';

.question {
  padding: 115px 115px 149px;

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  @media (max-width: 1023px) {
    padding: 56px 50px 81px;
  }

  @media (max-width: 767px) {
    padding: 56px 20px 81px;
  }

  &__title {
    letter-spacing: -0.01em;
    justify-content: center;
    margin-bottom: 38px;
    line-height: 55px;
    font-weight: 700;
    font-size: 40px;
    display: flex;

    @media (max-width: 1023px) {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 33px;
    }
  }

  &__plus {
    content: url('../../../../../../assets/images/landing/questions/minus.svg');
    transition-timing-function: ease-in-out;
    transform: rotate(180deg);
    transition: all 0.5s;
    position: absolute;
    left: 12px;
    top: 18px;

    @media (max-width: 1023px) {
      width: 12px;
      height: 12px;
      left: 10px;
      top: 9px;
    }
  }

  &__minus {
    content: url('../../../../../../assets/images/landing/questions/plus.svg');
    transition-timing-function: ease-in-out;
    transition: all 0.5s;
    position: absolute;
    left: 12px;
    top: 12px;

    @media (max-width: 1023px) {
      width: 12px;
      left: 10px;
      top: 9px;
      height: 12px;
    }
  }

  &__panel-plus {
    border-radius: 0 0 15px 15px;
    color: $color-text-light;
    box-sizing: border-box;
    padding: 0 35px 70px;
    background: #fff;
    overflow: hidden;
    opacity: 1;
    width: 90%;

    @media (max-width: 1023px) {
      width: 100%;
      padding: 0 20px 40px;
    }
  }

  &__panel-minus {
    overflow: hidden;
    height: 0;
  }

  &__content {
    background: $color-text-secondary;
    position: absolute;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    right: 24px;
    top: 23px;

    @media (max-width: 1023px) {
      width: 30px;
      height: 30px;
      top: 30%;
    }
  }

  &__container {
    background-color: #fff;
    letter-spacing: -0.01em;
    border-radius: 14px;
    padding: 16px 35px;
    line-height: 55px;
    font-weight: 500;
    cursor: pointer;
    font-size: 22px;
    border: none;

    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 24px;
      padding: 20px 71px 20px 20px;
    }
  }

  &__block-active,
  &__block-inactive {
    transition-timing-function: ease-out;
    transition: all 0.5s;
    border-radius: 14px;
    border-radius: 14px;
    margin-bottom: 17px;
    position: relative;
    border: 3px solid;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 1023px) {
      margin-bottom: 15px;
    }
  }

  &__block-active {
    border-color: $color-text-secondary;
  }

  &__block-inactive {
    border-color: #f5f6fa;
  }
}

.question__active {
  .question__content {
    top: 23px;
  }
}
