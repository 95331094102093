.campaigns-advertisements {
  &__item {
    width: 48.87%;
    height: 316px;
    margin: 0 2.13% 2.13% 0;
    background: #fff;
    border-radius: 10px;
    padding: 26px;
    display: flex;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    @media (max-width: 1250px) {
      flex-direction: column;
      height: auto;
    }

    @media (max-width: 800px) {
      margin-bottom: 20px;
      width: 100%;
      margin-right: 0;
      padding: 15px;

      @media (max-width: 374px) {
        padding: 15px;
      }
    }

    &:hover {
      box-shadow: 0px 0px 10px 0px #708090;
    }

    &:nth-child(2n + 2) {
      margin-right: 0;
    }

    &-left {
      margin-right: 25px;

      @media (max-width: 1250px) {
        display: flex;
        margin-right: 0;
      }

      &__content {
        display: none;
        width: 100%;

        &-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #332256;

          &-text {
            word-break: break-word;
          }

          &-copy {
            color: #ff2a5e;
            border: 1px solid #e3e4ec;
            font-weight: 500;
            padding: 8px 14px;
            transition: all 0.2s linear;
            border-radius: 6px;
            margin-left: 5px;
            transition: all 0.4s ease;
            position: relative;
            display: inline-block;
            outline: none;
            cursor: pointer;

            @media (max-width: 1250px) {
              display: none;
            }

            &:hover {
              background: #ff2a5e;
              color: #fff;
            }

            &[disabled] {
              background: #a1a5bf;
            }
          }
        }
        &-content {
          margin: 23px 0 11px;

          @media (max-width: 1250px) {
            margin: 11px 0;
          }

          @media (max-width: 374px) {
            margin: 0;
          }

          &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-id {
              font-size: 13px;
              line-height: 19px;
              text-align: center;
              padding: 5px 14px;
              color: #9599b5;
              background: #ffffff;
              border: 1px solid #9599b5;
              box-sizing: border-box;
              border-radius: 55px;

              @media (max-width: 1250px) {
                padding: 5px 8px;
                font-size: 12px;
              }

              @media (max-width: 424px) {
                padding: 5px 3px;
                font-size: 12px;
              }
              @media (max-width: 374px) {
                padding: 5px 3px;
                font-size: 8px;
              }
            }

            &-approved,
            &-approved-none {
              font-size: 14px;
              line-height: 20px;
              color: #9599b5;
              display: flex;
              align-items: center;

              @media (max-width: 1250px) {
                font-size: 12px;
              }

              @media (max-width: 374px) {
                font-size: 8px;
              }

              &-none {
                margin-left: -5px;
              }
            }

            &-approved-img {
              margin-right: 6px;
            }
          }

          &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;

            &-date {
              font-size: 13px;
              line-height: 19px;
              color: #9599b5;

              @media (max-width: 1250px) {
                font-size: 12px;
              }

              @media (max-width: 374px) {
                font-size: 8px;
                margin-top: 0;
              }
            }

            &-status {
              font-size: 13px;
              line-height: 19px;
              text-align: center;
              padding: 5px 14px;
              color: #332256;
              background: #ffffff;
              border: 1px solid #332256;
              box-sizing: border-box;
              border-radius: 55px;

              @media (max-width: 1250px) {
                padding: 5px 8px;
                font-size: 12px;
              }

              @media (max-width: 424px) {
                padding: 5px 3px;
              }

              @media (max-width: 374px) {
                padding: 5px 3px;
                font-size: 8px;
              }
            }
          }
        }

        @media (max-width: 1250px) {
          display: block;
          margin-left: 15px;
        }
      }

      &__image {
        width: 169px;
        height: 262px;
        object-fit: cover;
        border-radius: 4px;

        @media (max-width: 1250px) {
          width: 83px;
          height: 130px;
        }
      }
    }

    &-right {
      width: 100%;

      @media (max-width: 1250px) {
        padding-top: 15px;
        display: flex;
        flex-direction: column-reverse;

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: #ededf3;
          margin-bottom: 15px;

          @media (max-width: 1250px) {
            display: none;
          }
        }

        &::after {
          content: '';
          display: none;
          width: 100%;
          height: 1px;
          background: #ededf3;
          margin-bottom: 15px;

          @media (max-width: 1250px) {
            content: '';
            display: block;
            width: calc(100% + 52px);
            margin-left: -26px;
            margin-bottom: 15px;
            height: 1px;
            background: #ededf3;

            @media (max-width: 800px) {
              width: calc(100% + 30px);
              margin-left: -15px;
            }
          }
        }
      }

      &__header {
        @media (max-width: 1250px) {
          &:before {
            content: '';
            display: block;
            width: calc(100% + 52px);
            margin-left: -26px;
            height: 1px;
            background: #ededf3;

            @media (max-width: 800px) {
              width: calc(100% + 30px);
              margin-left: -15px;
            }
          }
        }

        &-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #332256;

          &-text {
            word-break: break-word;

            @media (max-width: 1250px) {
              display: none;
            }
          }

          &-copy {
            color: #ff2a5e;
            border: 1px solid #e3e4ec;
            font-weight: 500;
            padding: 8px 14px;
            transition: all 0.2s linear;
            border-radius: 6px;
            margin-left: 5px;
            transition: all 0.4s ease;
            position: relative;
            display: inline-block;
            outline: none;
            cursor: pointer;

            @media (max-width: 1250px) {
              width: 100%;
              margin: 15px 0 0 0;
            }

            &:hover {
              background: #ff2a5e;
              color: #fff;
            }

            &[disabled] {
              background-color: #fff;
              transition: all 0.3s linear;

              &:hover {
                cursor: not-allowed;
                background: #a1a5bf;
                color: #fff;
              }
            }
          }
        }
        &-content {
          margin: 23px 0 11px;

          @media (max-width: 1250px) {
            display: none;
          }

          &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ededf3;
            padding-bottom: 15px;

            &-id {
              font-size: 13px;
              line-height: 19px;
              text-align: center;
              padding: 5px 14px;
              color: #9599b5;
              background: #ffffff;
              border: 1px solid #9599b5;
              box-sizing: border-box;
              border-radius: 55px;
            }

            &-approved,
            &-approved-none {
              font-size: 14px;
              line-height: 20px;
              color: #9599b5;
              display: flex;
              align-items: center;

              &-none {
                margin-left: -5px;
              }

              &-img {
                margin-right: 6px;
              }
            }
          }

          &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ededf3;
            padding-bottom: 15px;
            margin-top: 15px;

            &-date {
              font-size: 13px;
              line-height: 19px;
              color: #9599b5;
            }

            &-status {
              font-size: 13px;
              line-height: 19px;
              text-align: center;
              padding: 5px 14px;
              color: #332256;
              background: #ffffff;
              border: 1px solid #332256;
              box-sizing: border-box;
              border-radius: 55px;
            }
          }
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;

        &-btn {
          color: #ff2a5e;
          border: 1px solid #e3e4ec;
          font-weight: 500;
          padding: 8px 14px;
          transition: all 0.2s linear;
          border-radius: 6px;
          transition: all 0.4s ease;
          position: relative;
          outline: none;
          cursor: pointer;
          display: none;

          @media (max-width: 1250px) {
            display: inline-block;
          }

          &:hover {
            background: #ff2a5e;
            color: #fff;
          }

          &[disabled] {
            background: #a1a5bf;
          }
        }

        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &-title {
            font-size: 15px;
            line-height: 21px;
            color: #9599b5;
          }

          &-subtitle {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            color: #332256;
          }
        }
      }
    }
  }
}
