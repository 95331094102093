.how-it-works-main {
    width: 100%;
    height:  fit-content;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
}

.how-it-works-block-text{
    font-size: 15px;
    position: relative;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
    margin-top: 15px;
}
.how-it-works-block {
    position: relative;
   
    border-radius: 100px;
    border: 1px solid black;
    height: 54px;
    width: 165px;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
}
.how-it-works-title {
    position: relative;
    font-size: 55px;
    line-height: 66px;
    text-align: center;
    font-weight: 600;
    color: rgba(51, 34, 86, 1);
    height: 132px;
    width: 80%;
    width: 604px;

    margin-left: auto;
    margin-right: auto;
}


.how-it-works-img {
    width: 387px;
    height: 500px;
    position: relative;
}

.how-it-works-cards{
    margin: auto;
    margin-top: 75px;
    width: 1201px;
    height: 500px;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
}


@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .how-it-works-title{
        font-size: 55px;
        line-height: 66px;
    }
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .how-it-works-title{
        font-size: 55px;
        line-height: 66px;
    }
}

@media screen and (min-width: 392px) and (max-width: 639px) {
    .how-it-works-title{
        font-size: 40px;
        line-height: 48px;
    }
}

@media screen and (min-width: 320px) and (max-width: 391px) {
    .how-it-works-title{
        font-size: 40px;
        line-height: 48px;
        width: 80%;
        text-align:left;
    }
    
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
    .how-it-works-title{
        font-size: 40px;
        line-height: 48px;
        width: 80%;
        text-align:left;

    }
}


@media screen and (min-width: 640px) and (max-width: 1200px) {

    .how-it-works-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .how-it-works-cards{
        margin: auto;
        margin-top: 75px;
        width: 100%;
        height: fit-content;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: 1fr 1fr;
        gap: 20px;
    }
}

@media screen and (min-width: 320px) and (max-width: 639px) {

    .how-it-works-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .how-it-works-cards{
        margin: auto;
        margin-top: 75px;
        width: 100%;
        height: fit-content;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .how-it-works-img {
        width: 387px;
        height: 500px;
        position: relative;
        margin-left: auto; margin-right: auto;
    }
    .how-it-works-title {
        position: relative;
        text-align: left;
        font-weight: 600;
        color: rgba(51, 34, 86, 1);
        height: fit-content;
        width: 80%;
        overflow-wrap: normal;
    
        margin-left: auto;
        margin-right: auto;
    }
}