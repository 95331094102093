@import '../../../assets/styles/mixin';

.home {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 60px 0;
  @include for-size(phone) {
    padding: 0;
    width: 95%;
    margin: auto;
  }

  &-sub-title {
    font-family: Gordita;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #332256;
    margin: 20px 0;
  }

  &-left__tablet-center {
    display: none;
    @include for-size(tablet-phone) {
      display: flex;
    }
    @include for-size(tablet) {
      justify-content: space-between;
    }
    @include for-size(phone) {
      flex-direction: column;
    }
  }

  &-left {
    width: 70%;
    @include for-size(tablet-phone) {
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include for-size(phone) {
        flex-direction: column;
      }

      &-content {
        width: 50%;
        @include for-size(phone) {
          width: 100%;
        }
      }

      &-title {
        font-family: Gordita;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 46px;
        color: #332256;
        margin: 20px 0;
        @include for-size(phone) {
          font-size: 26px;
          text-align: center;
        }
      }

      &-image {
        margin: 0 5%;
        width: 200px;
        @include for-size(tablet-phone) {
          width: 200px;
        }
        @include for-size(phone) {
          margin: 30px;
          width: 70%;
        }
      }

      &-description {
        font-family: Gordita;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        color: #757995;
        @include for-size(phone) {
          text-align: center;
          font-size: 15px;
        }
      }
    }

    &__news {
      &-container {
        display: flex;
        justify-content: space-between;

        .news__body-item {
          &:hover {
            box-shadow: 0 0 10px 0 #708090;
          }
        }

        .news__body-item {
          width: 47%;
          max-width: 100%;
          height: 100%;
          @include for-size(phone) {
            width: 100%;
          }

          .news__body-item-content {
            @include for-size(laptop) {

              padding: 24px 14px 25px 10px
            }
          }

          .news__body-item-content-body {
            height: 100%;
          }
        }

        @include for-size(tablet-phone) {
          justify-content: center;
          flex-wrap: wrap;
        }
        @include for-size(phone) {
          flex-direction: column;
        }
      }
    }

    &__steps {
      &-body {
        display: flex;
      }

      &__head-mobile {
        display: none;
        @include for-size(phone) {
          display: flex;
          align-items: center;
          padding: 15px 15px;
          border-bottom: 1px solid #f0f2f8;
        }

        &-image {
          width: 40px;
          margin-right: 15px;
        }

        &-title {
          font-family: Gordita;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          color: #332256;
        }
      }

      &-button {
        margin: auto;
        margin: auto 20px auto 0;
        background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
        height: 40px;
        width: 125px;
        font-size: 14px;
        border: none;
        border-radius: 6px;
        font-family: Gordita;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #ffffff;
        cursor: pointer;
        padding: 0;
        @include for-size(laptop) {
          font-size: 13px;
        }
        @media (max-width: 1365px) {
          margin: auto 30px auto 0;
        }

        @include for-size(phone) {
          margin: 15px auto 0 15px;
        }

        &:hover {
          opacity: 0.5;
        }
      }

      &__description {
        display: none;
        font-family: Gordita;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 25px;
        color: #332256;
        @include for-size(phone) {
          display: block;
          margin: 10px 15px 0;
        }
      }

      &-item {
        display: flex;
        background: #fff;
        border-radius: 10px;
        padding: 30px 0;
        margin: 20px 0;
        position: relative;

        &:hover {
          box-shadow: 0 0 10px 0 #708090;
        }

        @include for-size(phone) {
          flex-direction: column;

          border-radius: 10px;
          padding: 15px 0;
        }

      }

      &-text-container {
        width: 65%;
        @include for-size(phone) {
          display: none;
        }
      }

      &-text-title {
        font-family: Gordita;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #332256;
      }

      &-image {
        padding: 0 30px;
        @include for-size(phone) {
          display: none;
        }
      }
    }
  }

  &-right {
    width: 30%;
    @include for-size(tablet-phone) {
      display: none;
    }

    &__item {
      padding: 27px 30px;
      background: #fff;
      width: 80%;
      margin: auto;

      &:hover {
        box-shadow: 0 0 10px 0 #708090;
      }

      @include for-size(laptop) {
        width: 95%;
        padding: 15px 15px;
      }

      &:last-child {
        margin-top: 30px;
      }

      @include for-size(tablet-phone) {
        width: 45%;
        height: 100%;
        &:last-child {
          margin-top: 0;
        }
      }
      @include for-size(phone) {
        width: 100%;
        height: 100%;
        &:last-child {
          margin-top: 30px;
        }
      }

      &-title {
        border-bottom: 1px solid #f0f2f8;
        padding: 0 0 20px;
        font-family: Gordita;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #332256;
      }

      &-description {
        font-family: Gordita;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        color: #757995;
      }

      &-value {
        font-family: Gordita;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        text-align: right;
        color: #332256;
      }

      &-container {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #f0f2f8;
      }

      &-button {
        width: 100%;
        margin: 25px auto auto;
        border-radius: 6px;
        padding: 15px 15px;
        cursor: pointer;
        outline: none;
        background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
        border: none;
        font-family: Gordita;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        color: #ffffff;
        @include for-size(laptop) {
          font-size: 14px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
