.custom-slider {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 20px;

  ul.slick-dots {
    bottom: 0;
    position: relative;

    & > li {
      background: #d9dbe7;
      border-radius: 50%;
      width: 9px;
      height: 9px;

      &.slick-active {
        background-color: #ff2a5e;
      }

      & > button {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        border: none;

        &:before {
          content: none;
        }
      }
    }
  }
}

.slider-container {
  background: #f0f2f8;
  height: 673px;
  display: flex;
  align-items: center;
  flex-direction: column;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }

  &-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    padding: 33px 0 12px;
    text-align: center;
  }
}
