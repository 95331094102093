@import '../../../assets/styles/mixin';

.campaigns-advertisements {
  max-width: 1128px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @include for-size(laptop) {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }
}
