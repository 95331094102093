.ads-search-form {
  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__checkbox-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__checkbox-item {
    flex-basis: 33%;

    &:nth-last-child(1n + 4) {
      margin-bottom: 20px;
    }
  }
}
