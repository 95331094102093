.lds-dual-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  //margin: 2px;
  border-radius: 50%;
  border: 2px solid #ff1b71;
  border-color: #ff1b71 transparent #ff1b71 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.statistics-blogger {
  background: #fff;
  padding: 20px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  &__container-loader {
    width: 180px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__place {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
  }

  button {
    width: 180px;
    height: 60px;
  }

  &_border {
    border: 1px solid #ededf3;
    border-radius: 10px;
  }

  &-title {
    text-align: center;
    font-family: Gordita;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 37px;
    margin: 0 0 20px;
  }

  &__body {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  &__left {
    width: 340px;

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-logo {
        width: 108px;
        height: 108px;
        border-radius: 50%;
        margin: 55px 0 19px;
      }

      &-nickname {
        font-size: 17px;
        line-height: 24px;
        color: #332256;
        text-align: center;
        font-family: Gordita;
        font-style: normal;
        font-weight: 500;
      }

      &-type-page {
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        color: #9599b5;
        font-family: Gordita;
        font-style: normal;
        font-weight: normal;
      }

      &-container {
        display: flex;
        justify-content: space-between;
        padding: 25px 20px 10px;
        width: 100%;
        border-bottom: 1px solid #ededf3;
      }

      &-icon {
        img {
          margin: 0 5px;
        }
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ededf3;
      padding: 20px 20px;

      &:last-child {
        border: 0;
      }

      &-name {
        font-size: 14px;
        line-height: 20px;
        color: #9599b5;
        font-family: Gordita;
        font-style: normal;
        font-weight: normal;
      }

      &-value {
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: #332256;
        font-family: Gordita;
        font-style: normal;
        font-weight: 500;
        span {
          color: #ff2a5e;
        }
      }
    }
  }

  &__right {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 340px;

    &__table {
      border-radius: 10px;
      background: #fff;
      width: 100%;
      padding: 0 12px;
      border: 1px solid #ededf3;

      tbody {
        tr {
          height: 58px;

          th,
          td {
            width: 25%;
            text-align: center;
          }
        }
      }

      th,
      td {
        border: none;
        border-bottom: 1px solid #ededf3;
        background: #fff;
        width: 20%;
        padding: 3px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #332256;
        text-align: center;
        margin: auto;
        display: table;
        height: 36px;
      }

      td {
        &:first-child {
          color: #9599b5;
          font-weight: 400;
        }
      }

      &:first-child {
        th {
          margin-top: 10px;
          color: #9599b5;
          background: #fff;
          width: 20%;
          text-align: center;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 15px 20px;

      &-title {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        color: #332256;
        font-family: Gordita;
        font-style: normal;
        font-weight: 500;
      }

      &-text {
        color: #9599b5;
        width: 50%;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        font-family: Gordita;
        font-style: normal;
        font-weight: normal;
        margin: 5px 0 0 0;

        &-value {
          color: #332256;
          text-align: right;
        }
      }
    }
  }
}
