.show-content {
  width: 325px;
  height: 580px;
  border-radius: 26px;
  background: #fff;

  &-info {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 26px;
    padding: 10px;
    position: relative;
    background: #fff;
    z-index: 10;
  }
}
