@import '../../../../../assets/styles/mixin';

.base-info-form {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__button {
    align-self: flex-end;

    &.approved {
      min-width: 28.415%;
    }

    @include for-size(phone) {
      width: 100%;
      margin-bottom: 30px;
      button {
        width: 100%;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    @media (max-width: 374px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-banner {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 50%;
    }

    &-attach {
      display: flex;
      align-items: center;

      @media (max-width: 374px) {
        width: 100%;
      }

      &-campaign {
        width: 250px;
        text-align: center;

        @media (max-width: 374px) {
          text-align: left;
        }

        &-name {
          justify-content: center;
          margin-top: 10px;
          display: flex;
          font-weight: 500;
          word-break: break-word;

          @media (max-width: 374px) {
            justify-content: flex-start;
          }
        }
      }

      &-btn {
        width: 180px;
        height: 40px;
        border-radius: 6px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.4s ease;
        position: relative;
        display: inline-block;
        outline: none;
        padding: 0;

        @media (max-width: 374px) {
          width: 100%;
        }
      }

      &-icon {
        min-width: 20px;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        fill: #332256;
        cursor: pointer;
      }
    }
  }
}

.validation-banner {
  border-radius: 6px;
  border: 2px solid red;
  margin: 3px;
  padding: 3px;
  width: calc(100% - 100px);
}

.validation-campaign {
  border-radius: 6px;
  border: 2px solid red !important;
}
