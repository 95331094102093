.our-bloggers-main {
    width: 100%;
    height:  fit-content;
    position: relative;
    background-image: linear-gradient(to left,rgba(255, 64, 114, 1), rgba(255, 111, 71, 1));
    padding-top: 120px;
    padding-bottom: 120px;
}

.our-bloggers-block-text{
    font-size: 15px;
    position: relative;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
    color: white;
    margin-top: 15px;
}
.our-bloggers-block {
    position: relative;
   
    border-radius: 100px;
    border: 1px solid white;
    height: 54px;
    width: 165px;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
}
.our-bloggers-title {
    position: relative;
    font-size: 55px;
    line-height: 66px;
    text-align: center;
    font-weight: 600;
    color: white;
    width: 575px;
    height: 132px;
    margin-left: auto;
    margin-right: auto;
}


.our-bloggers-img {
    width: 387px;
    height: 693px;
    position: relative;
}

.our-bloggers-cards{
    margin: auto;
    margin-top: 75px;
    width: 1201px;
    height:  fit-content;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 20px;
}



@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .our-bloggers-title {
        font-size: 55px;
        line-height: 66px;
    }
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .our-bloggers-title {
        font-size: 55px;
        line-height: 66px;
    }
}

@media screen and (min-width: 392px) and (max-width: 639px) {
    .our-bloggers-title {
        font-size: 40px;
        line-height: 48px;
    }
    .our-bloggers-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .our-bloggers-img{
        Width:
        260px;
        Height:
        477px;
    }
}

@media screen and (min-width: 320px) and (max-width: 391px) {
    .our-bloggers-title {
        font-size: 40px;
        line-height: 48px;
    }
    .our-bloggers-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .our-bloggers-img{
        Width:
        260px;
        Height:
        477px;
    }
}


@media screen and (min-width: 335px) and (max-width: 639px) {
    .our-bloggers-img {
        width: 330px;
        margin: auto;
        height: 640px;
        position: relative;
    }
    .our-bloggers-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
    
    .our-bloggers-img {
        width: 387px;
        margin: auto;
        height: 693px;
        position: relative;
    }
}
@media screen and (min-width: 320px) and (max-width: 1200px) {


    .our-bloggers-cards{
        margin: auto;
        margin-top: 75px;
        width: 90%;
        height:  fit-content;
        position: relative;
        display: flex;
        height: fit-content;
        flex-direction: column ;
        justify-content: center;
        gap: 20px;
    }
    .our-bloggers-title {
        position: relative;
        text-align: left;
        font-weight: 600;
        color: white;
        width: 80%;
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}