.uploaded {
  &-file {
    width: 75px;
    height: 134px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    object-fit: cover;
    cursor: pointer;

    &__watch {
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #fff;
      border-radius: 4px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 6px solid #ff2a5e;
        top: 5px;
        left: 7px;
      }
    }
  }
}
