.download {
  background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
  border-radius: 30px;
  width: 84%;
  max-width: 1215px;
  margin: 0 auto;
  height: 468px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-top: -234px;

  @media (max-width: 1023px) {
    display: none;
  }

  &-container {
    display: flex;
    flex-direction: column;
    margin-left: -110px;
    margin-bottom: 10px;

    &-title {
      max-width: 360px;
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      letter-spacing: -0.01em;
      color: #ffffff;
    }

    &-subtitle {
      max-width: 390px;

      font-size: 18px;
      line-height: 28px;
      margin-top: 20px;
      color: #ffffff;
    }
  }

  &-phone {
    margin-top: -100px;
  }

  &-apple,
  &-android {
    cursor: pointer;
  }

  &-apple,
  &-android {
    margin-right: 23px;
    margin-top: 36px;
  }
}
