@import '../../../assets/styles/mixin';

.referral {
  background: #fff;
  padding: 60px;
  margin: 50px 0;
  @include for-size(phone) {
    padding: 20px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-link {
      padding: 10px 10px;
      outline: none;
      width: 85%;
      @include for-size(phone) {
        width: 70%;
      }
      border: #afb2c0 1px solid;
      border-right: 0;
      border-radius: 5px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      font-family: system-ui !important;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }



    &-copy {
      background: linear-gradient(
                      90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
      width: 15%;
      padding: 11px;
      outline: none;
      border: 0;
      border-radius: 5px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      cursor: pointer;
      color: #fff;
      @include for-size(phone) {
        width: 30%;
      }
      position: relative;

      &:hover {
        opacity: 0.9;
        .referral__info-item-link-description {
          opacity: 1;
          display: block;
        }
      }
    }

    &__item-container {
      width: 49%;
      @include for-size(phone) {
        width: 100%;
      }
    }

    &-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 10px;
      background: #f0f2f8;
      margin: 20px auto;
      padding: 30px;

      &:hover {
        box-shadow: 0 0 10px 0 #708090;
      }

      &-link {
        display: flex;
        flex-direction: column;
        padding: 15px;
        position: relative;

        p {
          font-size: 15px;
          color: #332256;
          font-weight: 600;
          margin-bottom: 14px;
        }

        &-description {
          display: none;
          position: absolute;
          width: 160px;
          top: -30px;
          right: 10px;
          padding: 5px 10px;
          background: #33225635;
          border-radius: 5px;
          font-size: 13px;

        }
      }

      &-text {
        margin: auto 0;
        font-size: 16px;
        color: #fff;
        @include for-size(phone) {
          font-size: 15px;
        }
      }


      &-zero {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 16px;
          color: #332256;
        }

        .referral-info-item-bold {
          font-weight: 600;
          font-size: 16px;
          @include for-size(phone) {
            font-size: 15px;
          }
        }

        .referral-info-item-number {
          font-weight: 600;
          font-size: 35px;
          @include for-size(phone) {
            font-size: 25px;
          }
        }

        .referral-info-item-descriptions {
          color: #6b6b6b;
          font-size: 14px;
          @include for-size(phone) {
            font-size: 12px;
          }
        }
      }

      &-bold {
        font-weight: 600;
      }

      &-number {
        font-weight: 600;
        font-size: 35px;
        @include for-size(phone) {
          font-size: 25px;
        }
      }

      &-first {
        color: #fff;
        background: linear-gradient(
                        90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
      }

      &-second {
        background: linear-gradient(
                        90deg, #332296 0%, #332256 100%), #332256;
      }
    }

  }

}