@import '../../../../../../../assets/styles/variables.scss';

.advantages-referrals-item {
  background: #fff;
  box-shadow: 0px 0px 32px rgba(51, 34, 86, 0.07);
  border-radius: 22px;
  display: flex;
  padding: 0 66px 0 44px;
  margin-right: 2%;
  margin-bottom: 2%;
  width: 49%;
  height: 219px;
  z-index: 2;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1150px) {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 252px;
  }

  @media (max-width: 767px) {
    width: 100%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &:nth-child(2n + 2) {
    margin-right: 0;
  }

  &:nth-child(5),
  &:nth-child(6) {
    margin-bottom: 0;

    @media (max-width: 767px) {
      margin-bottom: 20px;

      &:nth-child(6) {
        margin-bottom: 0;
      }
    }
  }

  &-img {
    margin-top: 40px;
    width: 55px;
    height: 55px;

    @media (max-width: 1150px) {
      width: 42px;
      height: 34px;
      margin: 36px auto 0;
    }

    @media (max-width: 767px) {
      margin: 46px auto 0;
    }

    @media (max-width: 374px) {
      margin: 16px auto 0;
    }
  }

  &-content {
    margin-left: 41px;

    @media (max-width: 1150px) {
      margin-left: 0;
      text-align: center;
    }

    &-title {
      font-weight: 500;
      font-size: 22px;
      line-height: 29px;
      margin-top: 34px;

      @media (max-width: 1150px) {
        font-size: 18px;
        line-height: 22px;
        margin-top: 20px;
      }
    }

    &-subtitle {
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      margin-top: 14px;
      color: $color-text-light;

      @media (max-width: 1150px) {
        font-size: 15px;
        line-height: 21px;
        margin-top: 13px;
      }
    }
  }
}
