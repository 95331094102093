.campaign-item {
  display: flex;
  width: 48.87%;
  min-height: 306px;
  height: auto;
  background: #fff;
  border-radius: 10px;
  margin: 0 2.26% 2.26% 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    margin-bottom: 20px;
    width: 100%;
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0px 0px 10px 0px #708090;
  }

  &:nth-child(2n + 2) {
    margin-right: 0;
  }

  &__banner {
    width: 152px;
    height: 152px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__container {
    width: 100%;

    &-btns {
      &-item,
      &-disabled {
        width: 70px;
        padding: 7px;
        color: #ff2a5e;
        border-radius: 6px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.4s ease;
        position: relative;
        display: inline-block;
        outline: none;
        position: absolute;
        left: 10px;
        &:nth-child(2) {
          margin-left: 75px;
        }

        @media (max-width: 1200px) {
          left: 168px;
          bottom: 35px;
        }

        @media (max-width: 800px) {
          left: 98px;
          bottom: 5px;
        }
      }

      &-disabled {
        border: 1px solid #e3e4ec;
        background: #fff;
        cursor: not-allowed;

        &:hover {
          background: #e5e8f2;
          color: #757995;
        }
      }
    }

    &-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      margin: 10px 0;
      word-break: break-word;

      @media (max-width: 1200px) {
        margin: 0 0 45px 15px;
        text-align: left;
        padding-right: 20px;
      }
    }

    &-left {
      width: 41.35%;
      margin: 25px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 15px;
      line-height: 21px;
      position: relative;
      margin-left: 25px;

      @media (max-width: 1200px) {
        flex-direction: initial;
        margin: 15px 0 15px 15px;
        width: 100%;
      }
    }

    &-right {
      border: 1px solid #f0f2f8;
      border-radius: 4px;
      margin: 25px;
      padding: 20px;

      @media (max-width: 800px) {
        margin: 0;
        padding: 15px;
        border: none;
        border-top: 1px solid #f0f2f8;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 12px;

        @media (max-width: 800px) {
          margin-bottom: 11px;
        }

        &-title {
          color: #9599b5;
        }

        &-budget {
          color: #9599b5;
          display: flex;
          flex-direction: column;

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #ededf3;
            margin: 8px 0 20px 0;

            @media (max-width: 800px) {
              width: 100%;
              margin: 4px 0 15px 0;
            }
          }

          &-subtitle {
            font-weight: 500;
            font-size: 24px;
            line-height: 34px;
          }
        }

        &-subtitle {
          color: #332256;
          font-weight: 500;

          &-active {
            color: #ff2a5e;
            font-weight: 500;
          }
        }
      }
    }
  }
}

#campaignDummy {
  @media (max-width: 800px) {
    width: 83px;
    height: 83px;
  }
}
