.auth-form-container {
  &__wrapper {
    background-color: #ffffff;
    border-radius: 6px;
  }
  &__inner {
    padding: 25px 40px 25px 40px;
  }

  &__top-decoration {
    width: 100%;
    height: 9px;
    background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
    border-radius: 6px 6px 0px 0px;
  }
}
