@import '../../../assets/styles/mixin';

.staps-bar {
  max-width: 1128px;

  &__container {
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: 45px;
    @include for-size(phone) {
      border-radius: 0;
    }
  }

  &__item {
    box-sizing: inherit;
    padding: 22px 0;
    flex-basis: 25%;
    display: flex;
    justify-content: center;
    align-items: center;

    border-right: 1px solid #ededf3;
    @include for-size(tablet-phone) {
      padding: 14px 0;
      flex-direction: column;
      justify-content: end;
    }
    @include for-size(small-phone) {
      padding: 10px 0;
    }

    &:last-child {
      border-right: none;
    }

    &-active {
      background-color: #332256;
    }

    &-done {
      cursor: pointer;
    }

    &-text {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;

      color: #9599b5;
      @include for-size(tablet-phone) {
        line-height: 20px;
        text-align: center;
        font-size: 13px;
      }
      @include for-size(small-phone) {
        line-height: 15px;
        font-size: 11px;
      }

      &-active {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;

        color: #ffffff;
        @include for-size(tablet-phone) {
          line-height: 20px;
          text-align: center;
          font-size: 13px;
        }
        @include for-size(small-phone) {
          line-height: 15px;
          font-size: 11px;
        }
      }

      &-done {
        color: #332256;
      }
    }

    &-number {
      width: 29px;
      height: 29px;
      margin-right: 13px;

      background-color: transparent;
      border: 1px solid #9599b5;
      border-radius: 50%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      color: #9599b5;
      @include for-size(tablet-phone) {
        font-size: 11px;
        text-align: center;
        margin: 0 auto 10px;
      }

      &-active {
        background: #6745ac;
        border: 1px solid #6745ac;
        color: #fff;
        padding: 0;
      }

      &-done {
        background-image: url('../../../assets/images/vectors/done.svg'),
          linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
        background-repeat: no-repeat;
        background-position: center;

        color: transparent;
        border: transparent;
      }
    }
  }
}
