@import '../../../assets/styles/mixin';

.selected-bloggers-container {
  box-sizing: border-box;
  padding: 31px 24px 22px 24px;
  background: #332256;
  border-radius: 10px;
  width: 349px;
  display: flex;
  flex-direction: column;
  @include for-size(tablet-phone) {
    width: 100%;
  }
  @include for-size(laptop) {
    width: 100%;
  }
}

.selected-bloggers {
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;

    color: #ffffff;
    margin-bottom: 20px;
    @include for-size(tablet-phone) {
      font-size: 16px;
    }
  }

  &__info {
    background: #ffffff;
    border-radius: 6px;
    padding: 24px 22px;
    margin-bottom: 22px;

    &-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 11px;
    }

    &-desc {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;

      color: #9599b5;
    }

    &-result {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #ededf3;
      padding: 21px 0px 0px 0px;
      //   margin-bottom: 8px;
    }

    &-value {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;

      text-align: right;

      color: #332256;
    }

    &-price {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 31px;
      text-align: right;
      color: #ff2a5e;
      @include for-size(tablet-phone) {
        font-size: 16px;
      }
    }
  }

  &__button {
    justify-self: center;
    width: 100%;
    @include for-size(tablet-phone) {
      display: flex;
      flex-direction: row;
      button {
        width: 50%;
        height: 50px;
        margin: 0;
      }
    }

    &-back {
      cursor: pointer;
      margin-top: 7px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      padding: 18px 33px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;

      color: #ffffff;
    }
  }
}

.insufficient-funds {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  &__title {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;

    @include for-size(tablet-phone) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
