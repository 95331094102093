.input-range {
  &__label--min,
  &__label--max {
    display: none;
  }

  &__label--value {
    position: absolute;
    top: -2.2rem;
  }

  &__label {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #332256;

  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__slider {
    background: #ff2a5e;
    border: 1px solid #ff2a5e;
  }

  &__track--active {
    background: #ff2a5e;
  }

  &__label-container {
    color: #9599b5;
  }
}

.first-element-range{
  position: relative;
  z-index: 10;
}
