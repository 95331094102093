@import '../../../assets/styles/mixin';

#menuToggle {
  display: none;

  &:checked {
    & ~ .left-bar__mobile-background {
      width: 100vw;
      height: 100vh;
      background: #00000060;
      position: absolute;
      top: 0;
      z-index: 99;
    }

    & ~ .left-bar {
      transform: translate(0%, 0);
    }

    & ~ .left-bar {
      .left-bar__mobile-close {
        display: block;
      }
    }
  }
}

.left-bar {
  background: #fff;
  min-width: 284px;
  box-sizing: border-box;
  position: relative;
  @include for-size(laptop) {
    min-width: 250px;
  }
  @include for-size(tablet-phone) {
    position: fixed;
    min-height: 100vh;
    width: 284px;
    z-index: 100;
    transform: translate(-100%, 0);
    transition: all 0.5s ease-in-out;
  }
  @include for-size(tablet-phone) {
    &__mobile-key {
      position: absolute;
      top: 31px;
      left: 31px;

      &-icon {
        span {
          display: block;
          width: 34px;
          height: 5px;
          margin-bottom: 6px;
          background: #000;
        }
      }
    }
    &__mobile-close {
      display: none;
      position: absolute;
      z-index: 10;
      top: 50px;
      width: 30px;
      height: 30px;
      left: 80%;

      span {
        &:first-child {
          transform: rotate(45deg);
        }
        width: 20px;
        height: 2px;
        margin: 0;
        background: #757995;
        transform: translate(0px, -2px) rotate(-45deg);
      }
    }
  }

  .custom-button-primary {
    width: calc(100% - 55px);
    margin: auto auto 55px;
    @media screen and (max-width:1629px) {
      width: 80%;
    }
    @include for-size(laptop) {
      width: 90%;
    }
  }

  &__logo {
    margin: 40px 40px;
  }

  &__first-menu {
    &-line {
      width: calc(100% - 80px);
      height: 1px;
      background: #e3e4ec;
      margin: 57px auto;
    }

    &-item,
    &-item-active {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 40px;
      position: relative;

      &:hover {
        opacity: 0.7;
      }
    }

    &-item-active {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 4px;
        height: 22px;
        border-radius: 2px 0px 0px 2px;
        background-color: #ff2a5e;
      }
    }

    &-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &-icon {
      margin: 0 23px 0 40px;
    }

    &-name {
      font-family: Gordita;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #a1a5bf;

      &-no-icon {
        margin: 0 23px 0 40px;
      }
    }
  }

  &__company-info {
    margin: 0 23px 0 40px;

    span {
      font-family: Gordita;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #a1a5bf;
    }
  }
}
