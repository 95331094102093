.paywall-content {
  background: #fff;
  position: relative;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  max-width: 500px;
  height: auto;
  margin: 20px auto 30px;
  padding-bottom: 20px;
  position: relative;
  border: 1px solid #ededf3;

  @media (max-width: 1023px) {
    margin: 55px auto 20px;
  }

  @media (max-width: 767px) {
    margin: 55px 20px 20px;
  }

  &:hover {
    box-shadow: 0px 0px 10px 0px #708090;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    padding: 65px 20px 20px;
    border-bottom: 1px solid #ededf3;
    text-align: center;
  }

  &__text {
    text-align: center;
    font-size: 15px;
    line-height: 21px;
    color: #989eaa;
    padding: 20px 20px 0;
  }

  &__btn {
    color: #ff2a5e;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s ease;
    position: relative;
    display: inline-block;
    outline: none;
    margin: 20px auto 5px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;

    @media (max-width: 1023px) {
      font-size: 14px;
    }
  }

  &__img {
    position: absolute;
    width: 90px;
    height: 90px;
    text-align: center;
    top: 1%;
    left: 50%;
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    border: 1px solid #ededf3;
    transition: all 0.3s ease-in-out;

    img {
      position: absolute;
      width: 65px;
      height: 65px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.paywall-content:hover > .paywall-content__img {
  box-shadow: 0px 0px 10px 0px #708090;
}
