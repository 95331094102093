@import "../../../../../assets/styles/mixin";
.auto-search__head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1128px;
  margin-bottom: 25px;

  &-button {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    height: 47px;
    outline: none;

    padding: 11px 22px;
    background: #ffffff;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;

    color: #332256;

    &-icon {
      margin-right: 11px;
    }
  }

  &-select {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-desc {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #9599b5;
      margin-right: 16px;
    }
  }
}

.auto-search__selected-bloggers {
  margin-bottom: 59px;
  @include for-size(tablet-phone){
    width: 90%;
    margin: auto auto 20px;
  }
  @include for-size(laptop){
    width: 100%;
  }
}

.auto-search__show-more {
  margin-top: 47px;
  display: flex;
  justify-content: center;
  &-button {
    padding: 22px 33px;
    border: none;
    cursor: pointer;
    background: #e5e8f2;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    text-align: center;

    color: #757995;

    &-icon {
      margin-right: 7px;
    }
  }
}
