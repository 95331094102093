@import '../../../../../assets/styles/mixin';

.choice-budget {
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    margin-top: 20px;
  }

  @include for-size(phone) {
    align-items: start;
    flex-direction: column;
  }
  &__title,
  &__money {
    font-size: 20px;
    line-height: 28px;
  }

  &__title {
    width: 100%;
    font-size: 15px;
    line-height: 21px;
    color: #9599b5;
    @include for-size(phone) {
      width: 100%;
    }
  }

  &__money {
    // width: 45%;
    color: #ff2a5e;
    font-weight: 500;
    @include for-size(phone) {
      width: 100%;
    }
  }

  &__balance {
    display: flex;
    @include for-size(phone) {
      flex-direction: column;
    }

    &-content {
      width: 50%;
      @include for-size(phone) {
        width: 100%;
      }

      &-title {
        font-size: 15px;
        line-height: 21px;
        color: #9599b5;
      }

      &-subtitle {
        font-size: 18px;
        line-height: 26px;
        color: #332256;
      }
    }

    &-btn {
      text-align: center;
      width: 45%;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #ff2a5e;
      border: 1px solid #ff2a5e;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      cursor: pointer;
      transition: all 0.6s ease;
      position: relative;
      outline: none;
      @include for-size(phone) {
        width: 100%;
        margin-top: 20px;
        height: 47px;
      }
    }
  }
}
