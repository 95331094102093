.menu,
.menu-dark {
  display: none;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.4s ease-in-out;
  z-index: 10000;

  @media (max-width: 1023px) {
    display: flex;
  }

  &__open {
    transform: translateX(0);

    &-img {
      margin: 35px auto 0;
      top: 0;
      cursor: pointer;
      width: 125px;
      height: 30px;
      margin-bottom: 130px;
    }

    &-login,
    &-login-dark,
    &-registration {
      margin: 0 auto;
      outline: none;
      text-decoration: none;
      cursor: pointer;
      border: none;
      transition: color 0.2s linear;
    }

    &-login,
    &-login-dark {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 23px;
      color: #342253;
      background: none;

      &:hover {
        color: #ff3f74;
      }
    }

    &-login-dark {
      color: #fff;
    }

    &-registration {
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.01em;
      color: #ffffff;
      background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
      border-radius: 6px;
      padding: 17px 91px;

      &:hover {
        opacity: 0.9;
      }
    }

    &-link,
    &-link-dark {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #332355;
      text-decoration: none;
      transition: color 0.2s linear;
      margin-bottom: 30px;
      position: relative;

      &:nth-child(5) {
        margin-bottom: 190px;
      }

      &:hover {
        color: #ff3f74;
      }

      &.active {
        &:hover {
          color: #332355;
        }
      }
    }

    &-link-dark {
      color: #fff;

      &.active {
        &:hover {
          color: #fff;
        }
      }
    }

    &-link > .active {
      &::after {
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        position: absolute;
        z-index: 5;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #ff3f74;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__close {
    transform: translateX(-100%);
  }

  &-dark {
    background: #342253;
    color: #fff;
  }
}

.burger {
  position: fixed;
  top: 0px;
  right: 50px;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  top: 40px;
  z-index: 1000000;
  width: 24px;
  height: 24px;

  @media (max-width: 1023px) {
    display: flex;
  }

  @media (max-width: 767px) {
    right: 20px;
  }

  &:focus {
    outline: none;
  }

  &__item {
    height: 3px;
    transition: all 0.4s ease-in-out;
    position: relative;
    transform-origin: 1px;

    &-open-white,
    &-open-black {
      background: #332355;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }

    &-open-black {
      background: #fff;
    }

    &-close-white,
    &-close-black {
      background: #332355;

      &:nth-child(1) {
        transform: rotate(0);
      }

      &:nth-child(2) {
        opacity: 1;
      }

      &:nth-child(3) {
        transform: rotate(0);
      }
    }

    &-close-black {
      background: #fff;
    }
  }

  &-container-logo,
  &-container-logo-show,
  &-container-logo-dark,
  &-container-logo-dark-show {
    display: none;
    position: fixed;
    top: 0px;
    padding-top: 40px;
    padding-left: 50px;
    left: 0;
    cursor: pointer;
    height: 100px;
    z-index: 1000000;
    width: 100vw;

    &-hide {
      display: none;
    }

    @media (max-width: 1023px) {
      display: block;
    }

    @media (max-width: 767px) {
      padding-left: 20px;
    }
  }

  &-container-logo-show {
    background: #fff;
  }

  &-container-logo-dark-show {
    background: #332355;
  }
}
