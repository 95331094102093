@import '../../../assets/styles/mixin';

.campaign {
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
  width: 1128px;

  @media (max-width: 1600px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
    flex-direction: column;
  }

  &__left {
    width: calc(68.4% - 39px);
    display: flex;
    flex-direction: column;
    margin-right: 39px;

    @media (max-width: 1300px) {
      margin-right: 20px;
      width: calc(68.4% - 20px);
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }

    &-top {
      width: 100%;
      height: 966px;
      height: auto;
      background: #ffffff;
      border-radius: 10px;
      padding: 45px 38px;
      transition: all 0.3s ease-in-out;

      @media (max-width: 767px) {
        padding: 22px 20px 30px;
      }

      &:hover {
        box-shadow: 0px 0px 10px 0px #708090;
      }

      &-title {
        word-break: break-word;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;

        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 27px;
        }

        &:after {
          border-bottom: 1px solid #ededf3;
          content: '';
          display: block;
          width: calc(100% + 76px);
          margin: 33px 0 0 -38px;

          @media (max-width: 767px) {
            width: calc(100% + 40px);
            margin: 13px 0 0 -20px;
          }
        }
      }

      &-container {
        &:after {
          border-bottom: 1px solid #ededf3;
          content: '';
          display: block;
          width: calc(100% + 76px);
          margin: 37px 0 0 -38px;

          @media (max-width: 767px) {
            width: calc(100% + 40px);
            margin: 29px 0 0 -20px;
          }
        }
      }

      &-container,
      &-container-stories {
        margin-top: 35px;

        @media (max-width: 767px) {
          margin-top: 26px;
        }

        &-number {
          font-weight: 500;
          margin-top: 30px;
          text-align: center;
        }

        &-title {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          display: flex;
          justify-content: space-between;

          @media (max-width: 767px) {
            font-size: 17px;
            line-height: 24px;
          }

          &-btn {
            display: flex;
            align-items: center;
          }
        }

        &-btn,
        &-btn-copy,
        &-btn-center {
          height: 40px;
          font-size: 16px;
          padding: 0 15px;
          color: #ff2a5e;
          border-radius: 6px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.4s ease;
          position: relative;
          display: inline-block;
          outline: none;

          @media (max-width: 1300px) {
            margin: 0 0 20px;
          }

          @media (max-width: 1023px) {
            font-size: 14px;
          }

          &-center {
            margin: 30px auto -15px;
          }
        }

        &-btn,
        &-btn-copy {
          @media (max-width: 767px) {
            padding: 0 8px !important;
          }

          &-disabled {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            padding: 0 15px;
            outline: none;
            border: none;
            font-style: normal;
            font-weight: 500;
            border-radius: 6px;
            background: #fff;
            color: #ff2a5e;
            font-size: 16px;
            border: 1px solid #e3e4ec;

            @media (max-width: 1300px) {
              margin: 0 0 20px;
            }

            @media (max-width: 1023px) {
              font-size: 14px;
            }

            &[disabled] {
              cursor: not-allowed;
              height: 40px;
              padding: 0 15px;

              &:hover {
                background: #e5e8f2;
                color: #757995;
              }
            }
          }
        }

        &-btn-copy {
          margin-right: 10px;

          @media (max-width: 767px) {
            margin-right: 8px;
          }

          &[disabled] {
            cursor: not-allowed;
            height: 40px;
            outline: none;
            background-color: #fff;
            border: 1px solid #e3e4ec;
            padding: 0 15px;

            &:hover {
              background: #e5e8f2;
              color: #757995;
            }
          }
        }

        &-btns {
          display: flex;
          justify-content: space-between;
          margin-top: 40px;

          @media (max-width: 1300px) {
            flex-direction: column;
            margin-top: 20px;
          }
        }

        &-confirm {
          font-weight: 500;
          color: #6745ac;
        }

        &-item {
          display: flex;
          align-items: center;
          margin-top: 30px;
          font-size: 15px;
          line-height: 21px;

          @media (max-width: 767px) {
            margin-top: 12px;
            flex-direction: column;
            text-align: left;
            width: 100%;
          }

          &-name {
            color: #9599b5;
            width: 190px;

            @media (max-width: 767px) {
              width: 100%;
            }
          }

          &-video,
          &-image {
            width: 200px;
            height: 200px;
            border-radius: 6px;
            object-fit: cover;
            cursor: pointer;
          }

          &-video {
            background: black;
          }

          &-info {
            width: 70%;
            word-break: break-word;
            letter-spacing:0.5px;
            @media (max-width: 767px) {
              width: 100%;
            }

            &-active {
              width: 70%;
              color: #ff2a5e;
              font-family: system-ui !important;

              @media (max-width: 767px) {
                text-align: left;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &__right {
    width: 31.6%;
    height: 710px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;

    @media (max-width: 767px) {
      width: 100%;
      height: auto;
      margin-top: 20px;
    }

    &-container {
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;

      &:hover {
        box-shadow: 0px 0px 10px 0px #708090;
      }

      &-banner,
      &-banner-item {
        width: 169px;
        width: 100%;
        height: 542px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 767px) {
          height: 510px;
        }

        &.border-bottom {
          border-bottom: 1px solid #ededf3;
        }

        &-item {
          display: block;
          height: 262px;
        }

        &-container {
          width: 169px;
          height: 169px;
          border: 2px solid #ff2a5e;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          &-empty {
            width: 100px !important;
            height: 100px !important;
            fill: #ff2a5e;
          }
        }
      }

      &-title {
        display: flex;
        justify-content: center;

        margin: 35px 0 13px 0;

        @media (max-width: 767px) {
          margin: 25px 0 13px 0;
        }
        p{
          font-size: 15px;
          line-height: 21px;
          color: #9599b5;
        }
      }

      &-subtitle,
      &-subtitle-disabled {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        width: 180px;
        background: #34b458;
        border-radius: 55px;
        font-size: 18px;
        line-height: 26px;
        height: 52px;
        text-align: center;
        margin-bottom: 47px;
        color: #ffffff;

        @media (max-width: 767px) {
          width: 190px;
          margin-bottom: 38px;
        }

        &-disabled {
          background: #9599b5;
        }
      }
    }
  }
}

.offer-comments {
  width: 250px;

  &__message {
    text-align: center;
    padding: 15px 0;
  }

  &__date,
  &__blogger {
    text-align: right;
  }

  &__blogger {
    font-weight: 500;
  }
}

.placement-status {
  margin: 74px 0 0 0;
  width: calc(68.4% - 39px);

  @media (max-width: 1300px) {
    width: calc(68.4% - 20px);
  }

  @media (max-width: 767px) {
    margin: 12px 0 0 0;
    padding: 0 20px;
    width: 100%;
  }

  &-title {
    font-weight: 500;
    font-size: 26px;
    line-height: 37px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
    }
  }

  &-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  &__select {
    margin: 0 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
      width: 50px;
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &-sort {
        margin: 0 0 0 30px;
        min-width: 50px;

        @media (max-width: 767px) {
          margin: 0 0 0 20px !important;
        }
      }
    }
  }
}
