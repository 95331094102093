.contact-us-popup-parent {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100000;
    background: rgba(0, 0, 0, 0.5); /* Задний фон с полупрозрачностью */
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
  }
  
  .contact-us-popup-main {
    display: inline-flex;
    padding: 50px 44px 64px 43px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 35px;
    border-radius: 50px;
    background: #FFF;
  }
  
  .contact-us-popup-title {
    color: #332256;
    margin: auto;
    width: 300px;
    font-family: Inter;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;

  }
  .contact-us-popup-title-2 {
    display: flex;
    width: 300px;
    margin: auto;
    color: 
    rgba(172, 172, 171, 1);
    align-items: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

    gap: 10px;
  }
  .contact-us-popup-input{
    display: flex;
    width: 471px;
    padding: 20px 15px;
    align-items: center;
    gap: 10px;border-radius: 10px;
    border: 1px solid #ACACAB;

  }



  @media screen and (min-width: 1024px) and (max-width: 1439px) {

  }
  
  @media screen and (min-width: 640px) and (max-width: 1023px) {
  
  }
  
  @media screen and (min-width: 392px) and (max-width: 639px) {
  
  }
  
  @media screen and (min-width: 320px) and (max-width: 391px) {
  
      
  }