.pricing-card-main {
    width: 387px;
    height:  fit-content;
    padding-top: 122px;
    padding-bottom: 122px;
    position: relative;
    border: 1px solid grey;
    display: flex;
    flex-direction: column;
    gap:30px;
    padding: 35px;
    text-align: left;
    border-radius: 30px;
    margin: auto;
}

.pricing-card-title{
    font-size: 15px;
    position: relative;
    font-weight: 500;
    line-height: 18px;
    width: 80%;
    color: rgba(51, 34, 86, 1);
}
.pricing-card-title1{
    font-size: 65px;
    position: relative;
    font-weight: 600;
    width: 80%;
    line-height: 78px;
    color: rgba(51, 34, 86, 1);
}
.pricing-card-title2{
    font-size: 25px;
    position: relative;
    font-weight: 500;
    width: 80%;


    line-height: 35px;
    color: rgba(51, 34, 86, 1);
}
.pricing-card-title3{
    font-size: 15px;
    position: relative;
    font-weight: 500;
    line-height: 22.5px;
    width: 80%;

    color: rgba(51, 34, 86, 1);
}

.pricing-card-stages{
    position: relative;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    color: rgba(51, 34, 86, 1);
    display: flex;
    flex-direction: column;
    width: 100%;

    gap:40px;
}

.pricing-card-img{
    width: 25px;
    height: 25px;
}

.pricing-card-stage{
    display: flex;
    flex-direction: row;
    gap: 40px;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
}

@media screen and (min-width: 320px) and (max-width: 359px) {
    .pricing-card-title{
        font-size: 15px;
        line-height: 18px;
    }
    .pricing-card-title1{
        font-size: 40px;
        line-height: 48px;
    }
    .pricing-card-title2{
        font-size: 25px;
        line-height: 35px;
    }
    
    .pricing-card-title3{
        font-size: 15px;
        line-height: 22.5px;
    }
}
@media screen and (min-width: 359px) and (max-width: 639px) {
    .pricing-card-main {
        width: 100%;
        margin: auto;
    }
    .pricing-card-title{
        font-size: 15px;
        line-height: 18px;
    }
    .pricing-card-title1{
        font-size: 40px;
        line-height: 48px;
    }
    .pricing-card-title2{
        font-size: 25px;
        line-height: 35px;
    }
    
    .pricing-card-title3{
        font-size: 15px;
        line-height: 22.5px;
    }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
    .pricing-card-main {
        width: 70%;
        margin: auto;
    }
}