@import "../../../assets/styles/mixin";

.header-list-blogger {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1128px;
  margin: 35px 0;
  @include for-size(laptop) {
    width: 100%;
  }

  @include for-size(tablet) {
    width: 95%;
    max-width: 1128px;

    margin: 25px auto;
  }
  @include for-size(phone) {
    width: 95%;
    margin: 30px auto;
  }

  &-sort-group {
  display: flex;
  }

  &-sort-container {
    display: flex;
    @include for-size(phone) {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 30px 0 0 0;
    }

    &-mobile {
      display: none;
      @include for-size(phone) {
        display: block;
      }

    }

  }

  &-sort {
    margin: 0 0 0 30px;
    min-width: 50px;
  }

  &-right {
    display: flex;
    align-items: center;
    width: 400px;
    @include for-size(tablet) {
      width: 300px;
    }
    @include for-size(phone) {
      width: 90vw;
    }

    input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      width: calc(100% - 50px);

      &:focus {
        border-radius: 6px 0 0 6px;
        box-shadow: none;
        border: 1px solid #e3e4ec;
      }

      label {
        margin: 0;
      }
    }

    button {
      min-width: 80px;
      height: 50px;
      padding: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      text-transform: uppercase;
      color: #fff;
    }

    &-desc {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #9599b5;
      margin-right: 16px;

    }
  }

  &-left {
    display: flex;
    align-items: center;
    margin: 15px 30px 15px 0;
    @include for-size(tablet-phone) {
      margin: 0;
    }

    &-button {
      display: flex;
      align-items: center;
      border: none;
      cursor: pointer;
      height: 47px;
      outline: none;
      margin: 0 20px 0 0;
      padding: 11px 22px;
      background: #ffffff;
      border-radius: 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: #332256;

      &-icon {
        margin-right: 11px;
      }
    }

    &-button-mobile {
      @include for-size(phone) {
        display: none;
      }
    }
  }

}
