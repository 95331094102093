.custom-input-number {
  width: 10px;
  padding: 14px 18px;
  background: #ffffff;
  border: 1px solid #e3e4ec;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  color: #332256;

  &__label {
    margin-bottom: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;

    color: #332256;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__dual {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-item {
      width: 49%;
    }
  }

  &__single {
    &-item {
      flex-grow: 1;
    }
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #b8bac8;
  }

  &:focus {
    border: 1px solid #6443a7;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;
  }

  &-container {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
