.confirmation-code__resend {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  &-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    text-align: center;
    letter-spacing: 0.02em;

    color: #9299a8;

    &-link {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      text-align: center;
      letter-spacing: 0.02em;

      color: #ff2a5e;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
