@import '../../../assets/styles/mixin';

.development-process {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 150px auto;
  @include for-size(laptop) {
    margin: 15% auto;
  }
  @include for-size(tablet) {
    margin: 10% auto;
  }
  @include for-size(phone) {
    margin: 5% auto;
  }

  &-image {
    width: 356px;
    height: 230px;
  }

  &-image {
    @include for-size(small-phone) {
      width: 100%;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    margin: 14px 0 6px 0;
  }

  &-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #9599b5;
    text-align: center;
    margin-bottom: 41px;
  }

  &-btn {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    padding: 18px 38px;
    outline: none;
    color: #ffffff;
    background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
    border-radius: 6px;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
}
