.reset-password{
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  margin-top: 5%;
  &-title {
    margin-bottom: 5%;
  }
  &__body{
    width:340px ;
  }
}
.reset-password-form{
  .auth-validation__container{
    width: 100%;
    max-width:400px;
  }
}
.error-token{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  &__text{
    margin: 30px 0;
    color:#332256;
    font-size: 17px;
  }
  &__key{
    padding: 10px 0;
    background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
    border: none;
    color:#fff;
    font-weight: bold;
    border-radius: 5px;
    font-size: 15px;
    width: 60%;
    margin: auto;
    &:hover{
      cursor: pointer;
      opacity: 0.8
    }
  }
}