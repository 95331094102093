.news {
  background: #f0f2f8;
  padding: 38px 165px 36px;
  min-height: 100vh;
  cursor: default;

  @media (max-width: 1365px) {
    padding: 38px 115px 36px;
  }

  @media (max-width: 1023px) {
    padding: 52px 50px 36px;
  }

  @media (max-width: 767px) {
    padding: 52px 20px 36px;
  }

  &__header {
    text-align: center;

    &-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 46px;
      color: #332256;
      margin-top: 32px;

      @media (max-width: 1023px) {
        font-size: 26px;
        line-height: 37px;
        margin-top: 33px;
      }
    }

    &-subtitle {
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      text-align: center;
      color: #757995;
      max-width: 450px;
      margin: 4px auto 0;

      @media (max-width: 1023px) {
        font-size: 15px;
        line-height: 22px;
        margin-top: 7px;
      }
    }
  }

  &__select {
    display: none;

    @media (max-width: 1150px) {
      display: block;
      margin: 31px auto 20px;
    }
  }

  &__category {
    display: flex;
    margin: 37px auto 36px;
    justify-content: center;

    @media (max-width: 1150px) {
      display: none;
    }

    &-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      padding: 8px 18px;
      border: 1px solid #d9dbe7;
      border-radius: 6px;
      cursor: pointer;
      transition: color 0.2s ease-in;
      margin-right: 10px;
      background: #f0f2f8;
      outline: none;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
        padding: 8px 19px;
        border: none;
        color: #fff;
      }

      &.active {
        background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
        padding: 8px 19px;
        border: none;
        color: #fff;
      }
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    max-width: 1170px;
    margin: 0 auto;

    &-empty {
      margin: 0 auto;
      margin-bottom: -15%;
    }
  }
}
