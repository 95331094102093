@import '../../../assets/styles/mixin';

.finances-page {
  @media (max-width: 1365px) {
    width: 80vw;
  }

  @include for-size(tablet) {
    width: calc(100% - 45px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px auto auto;
  }
  @include for-size(phone) {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

  &__error-text {
    text-align: center;
    padding: 5px 0;
    color: #f22;
  }

  &__error {
    width: 290px;
    padding: 20px 10px;
    text-align: center;
    margin: 17px auto;
    border: 1px solid #f22;
    color: #f22;
    border-radius: 5px;
  }

  &__balance {
    &-container {
      margin-top: 35px;
      display: flex;
      width: 100%;
      margin-bottom: 65px;
      @include for-size(tablet-phone) {
        margin: 10px 0 35px 0;
        display: flex;
        flex-direction: column-reverse;
      }

      .form-container {
        width: 70%;
        @include for-size(tablet-phone) {
          width: 100%;
          margin-top: 20px;
        }
      }
    }

    &-current {
      width: 40%;

      &-container {
        margin-left: 40px;
        width: 30%;
        @include for-size(tablet-phone) {
          width: 100%;
          margin: auto;
        }

        .form-container {
          width: 100%;
        }

        .form-container {
          margin-top: 20px;
        }

        .form-container:first-child {
          margin-top: 0;
        }

        .form-container:first-child {
          background-color: #332256;

          .user-balance__content-title {
            color: #b6a8d2;
          }

          .user-balance__content-value {
            color: #ffffff;
          }
        }
      }
    }
  }

  &__transactions {
    &-title {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 35px;
    }

    &-filters {
      &-container {
        width: 40%;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        min-width: 50vw;

        p {
          font-family: Gordita;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #9599b5;
          margin: 0 5px;
        }

        @include for-size(phone) {
          p {
            font-family: Gordita;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: #9599b5;
            margin: 0 2px;
          }
          div {
            div {
              input {
                padding: 14px 16px 14px 5px;
                background-position-x: 97%;
                background-size: 18px;
                font-size: 12px;
                @include for-size(small-phone) {
                  background-size: 15px;
                }
              }
            }
          }
        }
        @include for-size(tablet) {
          width: 100%;
          .custom-button {
            min-width: 60px;
          }
        }
        @include for-size(phone) {
          width: 100%;
          .custom-button {
            min-width: 35px;
            font-size: 12px;
          }
        }

        button {
          margin-left: 15px;
          @include for-size(phone) {
            margin-left: 5px;
          }
        }
      }
    }

    &-container {
      width: 100%;
    }

    &-table {
      width: 100%;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

.user-balance {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icon {
    width: 53px;
    height: 53px;
  }

  &__content {
    &-title {
      color: #757995;
      font-size: 15px;
      margin-bottom: 10px;
    }

    &-value {
      color: #332256;
      font-size: 25px;
      font-weight: 500;
    }
  }
}

.purchase {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    padding: 30px 0;

    &__go-first-container {
      position: absolute;
      width: 25px;
      height: 25px;
      left: 0;
      top: 5px;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    @include for-size(phone) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  &__input {
    width: 47%;
    margin: 0 1.5%;

    position: relative;
    @include for-size(phone) {
      width: 100%;
    }
    @include for-size(phone-tabled) {
      &:last-child {
        margin-top: 30px;
      }
    }

    .payment-item__brand-logo {
      width: 50px;
    }

    &:first-child {
      .custom-input-container {
        position: relative;
      }

      .custom-input-container::after {
        content: '$';
        position: absolute;
        right: 13px;
        top: 50%;
        transform: translateY(-50%);
        color: #332256;
      }
    }

    &-amount-info {
      font-size: 13px;
      color: #ff1b71;
      font-weight: 500;
      position: absolute;
      right: 1px;
      top: 3px;
      @include for-size(small-phone) {
        font-size: 12px;
      }
    }
  }

  &__buy-button {
    margin-top: 55px;
    display: flex;
    justify-content: center;

    &-key {
      padding: 13px;

      &-error {
        @include for-size(tablet-phone) {
          cursor: no-drop;
          background: #8d91ab;
        }

        &:hover {
          cursor: no-drop;
          background: #8d91ab;
        }
      }
    }
  }
}
