@import '../../../../../../../assets/styles/variables';

.benefits-item {
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(51, 34, 86, 0.07);
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
  text-align: center;
  justify-content: flex-start;
  margin-right: 2%;
  width: 32%;
  height: 381px;
  margin-bottom: 169px;
  margin-top: -139px;
  z-index: 2;

  @media (min-width: 1201px) and (max-width: 1400px) {
    margin-bottom: 162px;
  }

  @media (max-width: 1200px) {
    width: 48%;
    margin-right: 2%;

    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }

  @media (max-width: 650px) {
    width: 100%;
    margin-right: 0;
    padding: 0 20px;
    margin-bottom: 159px;
    height: 294px;
  }

  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &:nth-child(3n + 3) {
    margin-right: 0;
  }

  &:nth-child(6) {
    position: relative;
  }

  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    margin-bottom: 0px;

    @media (max-width: 650px) {
      margin-bottom: 22%;
      margin-bottom: 159px;

      &:nth-child(6) {
        margin-bottom: 30px;
      }
    }
  }

  &-img {
    margin-top: 58px;

    @media (min-width: 1201px) and (max-width: 1300px) {
      margin-top: 48px;
    }

    @media (max-width: 800px) {
      margin-top: 35px;
    }

    @media (max-width: 325px) {
      margin-top: 10px;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    margin-top: 34px;

    @media (min-width: 1201px) and (max-width: 1300px) {
      margin-top: 24px;
    }

    @media (max-width: 800px) {
      margin-top: 20px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    margin-top: 21px;
    color: $color-text-light;

    @media (min-width: 1201px) and (max-width: 1300px) {
      margin-top: 16px;
    }

    @media (max-width: 800px) {
      margin-top: 13px;
      line-height: 21px;
    }
  }
}
