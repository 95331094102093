@import '../../../../assets/styles/mixin';

.loading {
  font-size: 84px;
  font-weight: 800;
  text-align: center;

  width: calc(100% - 284px);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  background-color: #f0f2f8;

  @include for-size(laptop) {
    width: calc(100% - 250px);
  }

  @include for-size(tablet-phone) {
    width: calc(100% - 284px);
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    font-size: 64px;
  }

  @media (max-width: 374px) {
    font-size: 54px;
  }

  &-item {
    display: inline-block;
    margin: 0 -0.05em;
  }
}

.loading-bloggerly {
  .loading-item {
    position: relative;
    color: rgba(#ff2a5e, 0.5);
    animation: loading-bloggerly-parent 4s infinite;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: attr(data-text);
      color: #fff;
      opacity: 0;
      animation: loading-bloggerly-child 4s infinite;
    }
    @for $i from 1 through 8 {
      &:nth-child(#{$i + 1})::after {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
}
@keyframes loading-bloggerly-parent {
  0%,
  35%,
  100% {
    color: rgba(#ff2a5e, 0.5);
  }
  60%,
  80% {
    color: #fff;
  }
}
@keyframes loading-bloggerly-child {
  0% {
    opacity: 1;
  }
  25%,
  100% {
    opacity: 0;
  }
}

.loading + .backdrop {
  background: none;
}
