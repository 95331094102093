@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/mixin';

.advantages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 115px;
  font-weight: 500;

  @media (max-width: 1920px) {
    position: relative;
  }

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  @media (max-width: 1023px) {
    padding: 0 50px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  &__container {
    background: $color-text-primary;
  }

  &-wave {
    width: 100%;
    margin-bottom: 56px;
    position: relative;
    margin-top: -1px;

    @media (max-width: 1024px) {
      margin-bottom: 26px;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: $color-text-secondary;

    @media (max-width: 1250px) {
      margin-top: 83px;
    }

    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 23px;
    }
  }

  &-subtitle {
    max-width: 500px;
    margin: auto;

    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 21px;

    @media (max-width: 1023px) {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      margin-top: 11px;
    }
  }

  &-break {
    margin-bottom: 76px;
  }

  &-break-second {
    position: absolute;
    right: 0;
    margin-top: 590px;
    z-index: 1;

    @media (max-width: 1300px) {
      bottom: -80px;
    }

    @media (max-width: 700px) {
      bottom: -20px;
    }
  }
}
