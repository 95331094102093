@import '../../../assets/styles/mixin';

.global-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;

  @include for-size(laptop) {
    margin: 0;
    max-width: 100%;
  }

  @include for-size(phone) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column-reverse;
  }
  @include for-size(tablet) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin: 0 auto;
  }

  &__left {
    @include for-size(tablet) {
      margin: auto;
    }
    &-title {
      font-weight: 500;
      font-size: 26px;
      line-height: 37px;
      @include for-size(phone) {
        width: 100%;
        font-size: 22px;
        margin-top: 30px;
      }

      &-back {
        cursor: pointer;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        position: relative;
        padding-left: 16px;

        @include for-size(phone) {
          margin-top: 30px;
        }

        &::after {
          content: url('../../../assets/images/vectors/back.svg');
          position: absolute;
          left: 0;
          top: 2px;
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    @include for-size(phone) {
      width: 100%;
      padding: 27px 12px;
      background: #fff;
      justify-content: flex-end;
    }
  }
}
