.language-select {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 30px;
  position: relative;

  &__arrow {
    margin-left: 7px;
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 60px;
    position: absolute;
    z-index: 1000;
    top: 35px;

    &-item {
      padding: 10px 0 0 0;
      color: black;

      &:hover {
        @media (min-width: 1200px) {
          color: red;
          -moz-transition: color 0.1s ease-in;
          -o-transition: color 0.1s ease-in;
          -webkit-transition: color 0.1s ease;
        }
      }
    }
  }
}
