.auth-form__back {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &-icon {
    width: 30px;
    height: 30px;
    fill: #fff;
    margin-right: 20px;
  }
  &-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;
  }
}
