@import '../../../../../../assets/styles/variables.scss';

.accordion {
  width: 100%;
  position: relative;
  background: #f0f2f8;

  @media (min-width: 426px) and (max-width: 767px) {
    padding: 0 20px;
  }

  &__item {
    width: 100%;
    border-bottom: 1px solid #dddfe5;

    &:nth-child(3) {
      border-bottom: none;
    }

    &-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: none;
      background-color: transparent;
      padding: 0px;
      cursor: pointer;
      padding: 40px;
      font-weight: 500;
      font-size: 22px;

      @media (max-width: 1023px) {
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
      }

      @media (max-width: 767px) {
        padding: 20px;
      }

      &:after {
        content: url(../../../../../../assets/images/landing/case/arrow.svg);
        width: 32px;
        height: 32px;
        background: #ff3f74;
        border-radius: 50%;

        @media (max-width: 1023px) {
          padding-top: 3px;
        }
      }

      &:hover {
        color: #ff3f74;
      }

      &.font-weight-bold {
        background: #fff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: relative;
        padding-bottom: 20px;
        &::after {
          transform: rotate(180deg);
          transition: transform 300ms ease-out;

          @media (max-width: 1023px) {
            padding-top: 3px;
          }
        }

        &:before {
          border-top: 1px solid #f0f2f8;
          width: 100%;
          height: 1px;
          display: block;
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
        }
      }
    }
  }

  &__desc {
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-out;
    color: #8d91ab;
    font-size: 16px;
    line-height: 26px;

    &-subtitle {
      color: #afb3ce;
      font-weight: 400;
    }

    &-bottom {
      display: flex;
      margin-top: 45px;

      @media (max-width: 1105px) {
        margin-top: 20px;
      }

      &-coverage,
      &-likes {
        font-size: 48px;
        line-height: 26px;
        color: $color-text-secondary;
        font-family: $font-bebasneue;
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) and (max-width: 1105px) {
          font-size: 39px;
        }

        @media (max-width: 1023px) {
          font-size: 35px;
          line-height: 26px;
        }

        &-title {
          font-size: 13px;
          line-height: 26px;
          text-transform: uppercase;
          margin-bottom: 10px;
          color: #afb3ce;

          @media (max-width: 1023px) {
            font-size: 12px;
          }
        }
      }

      &-coverage {
        margin-right: 92px;

        @media (max-width: 1023px) {
          margin-right: 62px;
        }

        @media (max-width: 767px) {
          margin-right: 112px;
        }
      }
    }

    &.show-description {
      height: 275px;
      background: #fff;
      margin-bottom: 40px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      font-weight: 400;
      padding: 0 82px 70px 44px;

      @media (max-width: 1023px) {
        padding: 0 44px;
        height: 300px;
      }

      @media (max-width: 767px) {
        padding: 0px 20px 20px;
        height: 255px;
      }

      @media (max-width: 330px) {
        height: 275px;
      }
    }
  }
}
