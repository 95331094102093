@import '../../../../../assets/styles/mixin';

.custom-select {
  flex-grow: 1;
  position: relative;
  padding: 14px 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #332256;
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;

  background: #ffffff;
  border: 1px solid #e3e4ec;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff url('../../../../../assets/images/vectors/select.svg')
    right 20px center no-repeat;

  cursor: pointer;
  @include for-size(tablet-phone) {
    width: 100%;
    background: #ffffff url('../../../../../assets/images/vectors/select.svg')
      right 10px center no-repeat;
  }

  &__label {
    margin-bottom: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    @include for-size(phone) {
      margin: 0 0 6px 0;
    }
    color: #332256;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &:focus {
    outline: none;
    border: 1px solid #6443a7;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;
  }
}
