.customer-case-main {
    width: 100%;
    height:  fit-content;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
}

.customer-case-block-text{
    font-size: 15px;
    position: relative;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
    margin-top: 15px;
}
.customer-case-block {
    position: relative;
    border-radius: 100px;
    border: 1px solid black;
    height: 54px;
    width: 165px;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
}
.customer-case-title {
    position: relative;
    font-size: 55px;
    line-height: 66px;
    text-align: center;
    font-weight: 600;
    color: rgba(51, 34, 86, 1);
    height: 132px;
    width: 428px;
    margin-left: auto;
    margin-right: auto;
}


.customer-case-img {
    width: 600.5px;
    height: 592px;
    position: relative;
}

.customer-case-cards {
    margin: auto;
    margin-top: 75px;
    width: 1201px;
    height: 1926px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Используем repeat() для установки 2 столбцов */
    grid-template-rows: repeat(3, 1fr); /* Используем repeat() для установки 3 строк */
}


@media screen and (min-width: 1024px) and (max-width: 1439px) {
    .customer-case-title {
        font-size: 55px;
        line-height: 66px;
    }
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .customer-case-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .customer-case-title {
        font-size: 55px;
        line-height: 66px;
    }
}

@media screen and (min-width: 392px) and (max-width: 639px) {
    .customer-case-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .customer-case-title {
        font-size: 40px;
        line-height: 48px;
    }
}

@media screen and (min-width: 320px) and (max-width: 391px) {
    .customer-case-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .customer-case-title {
        font-size: 40px;
        line-height: 48px;
    }
    
}

@media screen and (min-width: 320px) and (max-width: 1200px) {

    .customer-case-main{
    }
    .customer-case-cards {
        margin: auto;
        margin-top: 75px;
        width: 90%;
        height: fit-content;
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Используем repeat() для установки 2 столбцов */
        grid-template-rows: repeat(6, 1fr); /* Используем repeat() для установки 3 строк */
    }
    .customer-case-img {
        margin: auto;
    }
    .customer-case-title {
        position: relative;

        text-align: left;
        font-weight: 600;
        color: rgba(51, 34, 86, 1);
        height: fit-content;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}