@import '../../assets/styles/mixin';

.main {
  display: flex;
  min-height: 100vh;
  background: #f0f2f8;

  &__right {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    background: #f0f2f8;

    &-body {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      @include for-size(tablet-phone) {
        display: block;
      }

      &-content {
        @media (min-width: 1601px) {
          width: 1128px;
        }
        @include for-size(laptop) {
          width: 100%;
        }
      }
    }
  }
}
