.mobile-finance {
  display: none;
  width: 100%;
  height: auto;

  @media (max-width: 767px) {
    display: block;
    &-container {
      display: flex;
      flex-wrap: wrap;
    }

  }

  &__sort-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &-item {
      height: 50px;
      width: 50px;
      margin: 0 15px 15px 0;
      &:last-child{
        margin: 0;
      }
    }

  }

  &__item {
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;

    @media (max-width: 767px) {
      width: calc(50% - 10px);
      margin-right: 20px;
      margin-bottom: 20px;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media (max-width: 620px) {
      width: 100%;
      margin-right: 0;
      width: 100%;
      margin-bottom: 20px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      border-bottom: 1px solid #ededf3;
      padding: 20px 20px 15px 20px;

      &-title {
        span {
          color: #9599b5;
          margin-right: 10px;
        }
      }

      &-id {
        border: 1px solid #9599b5;
        padding: 5px;
        border-radius: 10px;
        margin-left: 10px;
        min-width: 64px;
        text-align: center;
      }
    }

    &-footer {
      &-item {
        padding: 15px 20px;

        span {
          color: #9599b5;
          margin-right: 10px;
        }

        &:first-child {
          padding-bottom: 0;
        }

        &:last-child {
          text-align: center;
          border-top: 1px solid #ededf3;
          padding: 15px 20px 20px 20px;
        }
      }
    }
  }
}

.finance-item__tbody {
  tr {
    &:last-child {
      td {
        &:first-child {
          border-radius: 0;
        }
      }
    }
  }
}
