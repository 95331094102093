.statistic {
  &-item {
    border-top: 1px solid #ededf3;
    &:hover {
      transform: scale(1.01);
      box-shadow: 0 0 10px 0 #708090;
      -webkit-box-shadow: 0 0 10px 0 #708090;
      -moz-box-shadow: 0 0 10px 0 #708090;
      -o-box-shadow: 0 0 10px 0 #708090;
      -ms-box-shadow: 0 0 10px 0 #708090;
      cursor: pointer;
      border-top: none;
    }
  }
}

.statistic-item:hover > td {
  border-radius: 0 !important;
}
