@import '../../../assets/styles/mixin';

.form-section {
  margin-bottom: 27px;
}

.form-block {
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 10px 0;
  }

  &-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include for-size(phone) {
      flex-direction: column;
    }
  }

  &-part {
    flex-basis: 48%;
  }

  &-money {
    &::after {
      display: block;
      content: '';
      width: calc(100% + 76px);
      width: -moz-calc(100% + 76px);
      width: -webkit-calc(100% + 76px);
      height: 1px;
      background: #ededf3;
      margin: 30px 0 -10px -38px;
    }
  }
}
