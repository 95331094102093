.show-verification-code{
  background:#fff;
  padding: 40px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p{
    font-size: 17px;
    a{
      font-size: 18px;
      color: #332256;
      font-weight: bold;
    }
  }
}