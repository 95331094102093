.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  border-radius: 6px;
  max-height: 56px;
  &-disabled{
    background: #e5e8f2;
    color: #757995;

  }
  &[disabled] {
    cursor: pointer;
    background: #e5e8f2;
    color: #757995;

  }

  &:hover {
    opacity: 0.9;
  }
  &__icon {
    &-s {
      width: 8px;
      height: 8px;
      margin-left: 6px;
    }

    &-m,
    &-m-s {
      width: 12px;
      height: 12px;
      margin-left: 17px;
    }
  }

  &-s {
    padding: 11px 18px;
  }

  &-m {
    padding: 18px 28px;
  }

  &-m-s {
    padding: 18px 15px;
  }

  &-l {
    padding: 18px 33px;
    width: 100%;
  }

  &-primary {
    cursor: pointer;

    background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%), #ff1b71;
    color: #ffffff;

    font-size: 14px;
    line-height: 20px;
  }

  &-secondary {
    cursor: pointer;

    background: #e5e8f2;
    color: #757995;
    font-size: 14px;
    line-height: 20px;
  }

  &-light {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ff2a5e;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid #e3e4ec;
  }
}
