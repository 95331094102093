.stories-custom {
  &__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #332256;
  }

  &__header {
    display: flex;

    &-text {
      width: 43%;
      font-size: 12px;
      line-height: 20px;
      color: #9599b5;
      margin: 11px 57px 0 0;
    }
  }

  &__footer {
    &-background,
    &-background__errors {
      background-color: rgba(240, 242, 248, 0.6);
      border-radius: 6px;
      width: 196px;
      height: 196px;
      position: relative;
      margin-top: 32px;

      &__errors {
        border: 2px solid red;
      }

      &-display {
        position: absolute;
        top: 40%;
        padding: 0 15px;
        font-weight: 500;
        color: #ff2a5e;
        text-align: center;
        display: block;
        justify-content: center;
      }

      &-image {
        position: absolute;
        width: 75px;
        height: 134px;
        background-color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;
      }

      &-delete {
        position: absolute;
        right: 14px;
        top: 14px;
        cursor: pointer;
        transition: all 0.3s ease-in;

        &:hover {
          transform: rotate(90deg);
        }
      }
    }
    &-name {
      font-size: 14px;
      line-height: 20px;
      color: #9599b5;
      margin-top: 15px;
    }
  }
}

.file-upload {
  &__input {
    cursor: pointer;
    display: none;
  }

  &__button {
    cursor: pointer;
    background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    padding: 15px 30px;

    &:hover {
      opacity: 0.9;
    }

    &[disabled] {
      cursor: not-allowed;

      &:hover {
        background: #e5e8f2;
        color: #757995;
      }
    }
  }

  &__container {
    max-height: 56px;
  }
}
