.settings-form {
  width: 100%;

  &__button-container {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }

  &__add-email {
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    margin-right: 10px;
    outline: none;
    padding: 6px 12px;
    background: #fff;
    position: relative;
    cursor: pointer;
    background: white;
    border: 0;
    outline: 0;
    border-radius: 4px;
    border: 1px solid #e3e4ec;
    color: #ff2a5e;
    transition: all 0.1s ease-in-out;

    @media (max-width: 424px) {
      min-width: 93px;
    }
  }
}
