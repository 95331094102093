@import '../../../../../../../assets/styles/variables';

.work-mobile {
  display: none;
  justify-content: space-between;
  margin-top: 39px;
  padding: 0 115px;

  @media (max-width: 1200px) {
    display: flex;
  }

  @media (max-width: 1023px) {
    padding: 0 50px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  &__left {
    width: 20%;
    display: flex;
    align-items: center;
    margin-right: 28px;
    flex-direction: column;

    &-first {
      width: 94px;
      height: 94px;
      background: #ffffff;
      box-shadow: 0px 0px 16px rgba(135, 124, 156, 0.22);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-bebasneue;
      font-size: 50px;
      line-height: 60px;
      color: $color-text-secondary;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }

      @media (max-width: 767px) {
        width: 48px;
        height: 48px;
        font-size: 25px;
        line-height: 30px;
      }
    }

    &-line {
      border-right: 4px dotted #afb3ce;
      height: 400px;
      margin: 15px 0 15px 0px;

      @media (max-width: 767px) {
        height: 310px;
        margin: 8px 0;
      }

      @media (max-width: 430px) {
        height: 320px;
      }

      @media (max-width: 410px) {
        height: 335px;
      }

      @media (max-width: 340px) {
        height: 355px;
      }
    }
  }

  &__right {
    width: 80%;
    text-align: left;

    &-item {
      &-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #332256;
        margin-bottom: 15px;
      }

      &-subtitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #8d91ab;

        @media (max-width: 767px) {
          max-width: 330px;
        }
      }

      &-img {
        width: 550px;
        height: 450px;

        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }

        @media (max-width: 767px) {
          width: 260px;
          height: 260px;
        }

        @media (max-width: 374px) {
          width: 200px;
          height: 260px;
        }
      }

      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}
