.support__left {
  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  &-subtitle {
    margin: 23px 0 51px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 1023px) {
      margin: 19px 0 12px;
    }

    &::after {
      width: calc(100% + 76px);
      margin-left: -38px;
      height: 1px;
      background: #ededf3;
      display: inline-block;
      content: '';
      position: absolute;
      bottom: 0;
      bottom: -51px;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    &-top,
    &-bottom {
      color: #757995;
      font-size: 15px;
      line-height: 24px;
    }

    &-top {
      margin-bottom: 20px;
    }
  }
}
