@import '../../../../../../assets/styles/mixin';

.header-referrals {
  padding-top: 37px;
  position: relative;

  &-bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 115px;
    z-index: 2;
    color: #fff;
    margin-top: -1px;
    height: 101.7vh;

    @include for-size(fullhd) {
      width: 1920px;
      margin: -1px auto 0;
      height: 70vh;
    }

    @media (max-width: 1920px) {
      position: relative;
    }

    &__container {
      background-color: #332256;
      position: relative;
    }

    @media (max-width: 1439px) {
      height: 141vh;
    }

    @media (max-width: 1250px) {
      flex-direction: column-reverse;
      padding: 0;
      height: 100%;
    }

    &-left {
      width: 45%;
      animation: fromLeft 0.3s ease-out;

      @media (max-width: 1920px) {
        position: relative;
      }

      @media (max-width: 1250px) {
        width: 100%;
        text-align: center;
        padding: 0 20px;
        height: 400px;
      }

      @media (max-width: 500px) {
        height: 500px;
      }

      &-button {
        padding: 18px 37px;
        font-size: 18px;
        line-height: 26px;
        background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
        border-radius: 6px;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
        position: relative;
        z-index: 2;

        @media (max-width: 1250px) {
          margin-bottom: -73px;
          margin-top: 10px;
        }

        &:hover {
          opacity: 0.9;
        }
      }

      &-break {
        position: absolute;
        left: 0;
        bottom: 220px;

        @media (max-width: 1920px) {
          left: -120px;
        }

        @media (max-width: 1250px) {
          left: 0;
          height: 80px;
          width: 174px;
          bottom: 41%;
        }
      }

      &-title {
        display: block;
        font-weight: 500;
        font-size: 45px;
        line-height: 64px;
        letter-spacing: -0.01em;
        margin-top: 140px;
        color: #fff;

        @media (max-width: 1250px) {
          font-size: 25px;
          line-height: 34px;
          letter-spacing: -0.01em;
          font-weight: bold;
          margin-top: 10px;
        }
      }

      &-subtitle {
        display: block;
        font-size: 22px;
        line-height: 35px;
        margin: 22px 0 35px 0;
        color: #fff;

        @media (max-width: 1250px) {
          font-size: 16px;
          line-height: 24px;
          margin: 18px 0 50px 0;
        }
      }
    }

    &-right {
      margin-top: -80px;
      animation: fromRight 0.3s ease-out;

      @include for-size(fullhd) {
        margin-right: -300px;
      }

      @media (max-width: 1250px) {
        margin-top: -10%;
        display: flex;
        width: 100%;
      }

      @media (max-width: 374px) {
        margin-top: -12%;
      }

      &-img {
        width: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;

        @media (max-width: 1250px) {
          overflow: hidden;
        }

        &-referrals {
          width: 312.7px;
          height: 312.7px;
          border-radius: 50%;
          position: absolute;
          object-fit: cover;
          top: 43%;
          left: 62%;
          transform: translate(-50%, -50%);

          @include for-size(fullhd) {
            left: 48.5%;
            height: 360px;
            width: 360px;
          }

          @media (max-width: 1250px) {
            height: 48%;
            top: 51.5%;
            left: 53%;
            height: 37%;
            width: 41%;
          }
        }
      }
    }
  }
}
