@import '../../../../../assets/styles/mixin';

.create-accaunt__terms {
  display: flex;
  align-items: center;

  &-description {
    margin-left: 5px;
    font-family: Gordita;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;

    letter-spacing: -0.01em;

    color: #afb2c0;

    &-link {
      color: #afb2c0;
      text-decoration: underline;
    }
  }
}

.auth-form-registration {
  @include for-size(not-phone) {
    width: 600px;
    &-privacy{
      justify-content: flex-end;
    }
    .auth-form-container__inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .auth-form__title-container {
      width: 100%;
    }

    .auth-form-block {
      width: 45%
    }
    .auth-form-button-container {
      margin: 20px 0;
      width: 100%;
    }
  }
}