.auth-form-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  margin-top: 31px;

  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  &__description {
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e5e8f2;
    line-height: 0.1em;

    &-text {
      background: #fff;
      padding: 0 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #9599b5;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    fill: rgb(172, 171, 171);
    margin-right: 5px;

    &-container {
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: 15px;
      width: 48%;
      background-color: #f4f5f9;
      border-radius: 6px;
      cursor: pointer;
      border: none;
      outline: none;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
