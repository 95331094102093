.landing-new {
  text-align: center;
  font-family: 'Inter';
  user-select: none;
  overflow-y: scroll; /* Скрыть горизонтальный скролл */
  overflow-x: hidden; /* Запрет прокрутки по горизонтали */
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.button-full {
  background: linear-gradient(to left, rgba(255, 64, 114, 1), rgba(255, 111, 71, 1));
  padding : 20px 45px 20px 45px;
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
}
.button-full-white {
  background: rgba(255, 255, 255, 1);
  padding : 20px 45px 20px 45px;
  font-size: 15px;
  color: rgb(0, 0, 0);
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
}
.button-empty {
  background: transparent;
  padding : 20px 45px 20px 45px;
  font-size: 15px;
  font-weight: 700;
  color: rgba(255, 111, 71, 1);
  border: 1px solid rgba(255, 111, 71, 1);
  border-radius: 10px;
  text-align: center;

}
.button-full:hover {
  color: rgba(255, 255, 255, 0.73);
}
.button-full-white:hover {
  color: rgba(0, 0, 0, 0.652);
}
.button-empty:hover {
  color: rgba(255, 111, 71, 0.556);
}
a {
  color: black; /* Устанавливаем цвет текста */
  text-decoration: none; /* Убираем подчеркивание */
  /* Дополнительные стили, если необходимо */
}

/* Если вы хотите добавить стили для наведения на ссылку, используйте псевдокласс :hover */
a:hover {
  color: grey;
}
@media screen and (min-width: 320px) and (max-width: 1200px) {
  .landing-new {
    gap: 20px;
  }
}