@import "../../../../assets/styles/mixin";
.new-company {

  &__right {
    width: 40%;
    @include for-size(tablet-phone){
      width: 100%;
    }
  }

  &__create {
    display: flex;
    justify-content: space-between;
    @include for-size(tablet-phone){
      flex-direction: column-reverse;
    }
  }
}
