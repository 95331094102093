.support__right-top {
  height: auto;
  background: #fff;
  border-radius: 10px;
  margin: 0 0 32px 0;

  @media (max-width: 1600px) {
    margin: 0 0 20px 0;
  }

  @media (max-width: 1300px) {
    margin: 0 20px 0 0;
    min-width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
    }

    &-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;

      @media (max-width: 767px) {
        margin-bottom: 14px;
      }
    }

    &-button {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      border: none;
      outline: none;
      background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
      border-radius: 6px;
      padding: 10px 15px;
      cursor: pointer;

      @media (max-width: 767px) {
        width: 100%;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &-subtitle {
    margin: 26px 0 0 0;

    &__item {
      border: 1px solid #e3e4ec;
      border-radius: 10px;
      height: auto;
      margin: 0 0 12px 0;
      padding: 13px 20px;
      cursor: pointer;
      transition: all 0.3s linear;

      &:hover {
        border: 1px solid #ff2a5e;
      }

      &:last-child {
        margin: 0;
      }

      &-top {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &-status {
          font-size: 13px;
          line-height: 22px;
          background: #34b458;
          border-radius: 4px;
          padding: 2px 10px;
          color: #ffffff;
          margin-right: 21px;

          &.closed {
            background: #757995;
          }
        }

        &-data {
          font-size: 13px;
          line-height: 22px;
          color: #757995;
        }
      }

      &-bottom {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}
