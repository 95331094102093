.select-filter {
  width: 100%;
  height: 100%;

  position: relative;
  z-index: 5;
  &-handler {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    caret-color: transparent;
    border: 0;
    z-index: 7;
    outline: none;
    cursor: pointer;
  }

  &-container-sort {
    .react-select__control {
      .react-select__indicators {
        background-image: url('../../../../assets/images/navigation/sort.svg');
      }
    }

    .react-select__menu {
      right: 1px;
    }
  }

  &-container-sort-by {
    .react-select__control {
      .react-select__indicators {
        background-image: url('../../../../assets/images/navigation/sortBy.svg');
      }
    }

    .react-select__menu {
      right: 1px;
    }
  }

  &-container-status {
    .react-select__control {
      .react-select__indicators {
        background-image: url('../../../../assets/images/navigation/status.svg');
      }
    }

    .react-select__menu {
      left: 1px;
    }
  }

  &-container {
    border: none;

    .react-select__menu {
      min-width: 220px;
    }

    .react-select__control {
      border: none !important;
      border-radius: 0;
      width: 0;

      .react-select__indicators {
        height: 50px;
        width: 50px;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #fff;
        border-radius: 6px;
        position: relative;
        //        z-index: 1;
        svg {
          display: none;
        }

        .react-select__dropdown-indicator {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          background: transparent;
          width: 100%;
          height: 100%;

          &:hover {
            background-color: #00000013;
            border-radius: 6px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
