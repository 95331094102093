.settings-page {
  display: flex;
  width: 740px;
  margin: 85px auto 0;

  @media (min-width: 767px) and (max-width: 827px) {
    height: 82vh;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
    margin: 30px auto;
  }
}
