.moderator-comment {
  margin-bottom: 27px;
  position: relative;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;

  @media (max-width: 1300px) {
    margin-bottom: 20px;
  }

  &:hover {
    box-shadow: 0px 0px 10px 0px #708090;
  }

  &-icon {
    position: absolute;
    top: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-container {
    font-size: 15px;
    line-height: 21px;

    &-moderator {
      margin-top: 12px;
      text-align: right;

      &-name {
        font-weight: 500;
        margin-left: 10px;
      }
    }

    &-message {
      text-align: center;
      color: #989eaa;
    }
  }

  &-btn {
    color: #ff2a5e;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s ease;
    position: relative;
    display: inline-block;
    outline: none;
    margin: 45px auto -45px;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 1023px) {
      font-size: 14px;
    }
  }
}
