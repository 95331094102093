.custom-icon {
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin-left: 5px;

  @media (max-width: 767px) {
    height: 25px;
    width: 20px;
  }
}
