@import '../../../../../../assets/styles/variables';

.header {
  padding: 37px 115px 0;
  position: relative;
  z-index: 3;

  &-dark {
    background-color: $color-text-primary;
    color: #fff;
    position: relative;
    padding: 37px 115px 0;
  }
}
