@import '../../../../../../assets/styles/variables.scss';
@import '../../../../../../assets/styles/mixin';

.case {
  display: flex;
  font-weight: 500;
  padding: 120px 115px 34px;
  height: 1050px;

  &__container {
    background: $color-background-secondary;
  }

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  @media (max-width: 1023px) {
    padding: 120px 50px 34px;
    height: 1000px;
  }

  @media (max-width: 767px) {
    padding: 80px 0 34px;
    flex-direction: column;
    height: 930px;
  }

  @media (max-width: 323px) {
    height: 970px;
  }

  &-wave {
    background: $color-background-secondary;
    width: 100%;
    margin-bottom: -6px;
  }

  &-break {
    position: absolute;
    right: 0;
    margin-top: -80px;
    width: 257px;
  }

  &-left {
    width: 49%;
    margin-right: 1%;

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }

    &__title {
      p {
        line-height: 26px;
        font-size: 18px;
        color: $color-text-secondary;

        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 23px;
          padding: 0 20px;
        }
      }
    }

    &__subtitle {
      p {
        margin-top: 21px;
        margin-bottom: 38px;
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;

        @media (max-width: 767px) {
          margin: 11px 0 23px;
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          padding: 0 20px;
        }
      }
    }

    &__button {
      padding: 18px 37px;
      background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
      border-radius: 6px;
      font-weight: 500;
      color: #fff;
      cursor: pointer;

      @media (max-width: 767px) {
        display: none;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &-right {
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
}
