$black: #332256;
$grey: #332256;
$white: #FFFFFF;


.switcher {
  position: relative;
  margin: 20px auto;
  width: 225px;
  height: 45px;
  border-radius: 25px;
  display: block;
  input {
    appearance: none;

    position: relative;

    width: 230px;
    height: 45px;
    border-radius: 25px;

    background-color: $black;
    outline: none;

    font-family: 'Oswald', sans-serif;

    &:before, &:after {
      z-index: 2;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      content: 'Advertiser';
      left: 5px;
      color: $grey;
      transition: color .5s;
      padding: 9px 15px;
      background: #fff;
      border-radius: 40px;
    }

    &:after {
      content: 'Blogger';
      right: 5px;
      color: #fff;
      padding: 9px 30px;
      border-radius: 40px;


    }
  }

  label {
    z-index: 1;
    position: absolute;
    top: 10px;
    bottom: 10px;

    border-radius: 20px;
  }
}

.switcher-1 {
  input {
    transition: .25s -.1s;

    &:checked {
      &:before {
        color: $white;
        transition: color .3s .2ms;
        background: none;
      }

      &:after {
        color: $grey;
        transition: color .3s .2ms;
        background: #fff;

      }

      & + label {
        transition: left .5s, right .4s .2s;
      }
    }

    &:not(:checked) {
      background: $black;
      transition: background .5s -.1s;

      &:before {
        transition: color .3s .2ms;
      }

      &:after {
        color: $white;
        transition: color .3s .2ms;
      }

      & + label {

        transition: left .4s .2s, right .5s, background .35s -.1s;
      }
    }
  }
}
