@import '../../../../assets/styles/mixin';

.checkbox-card {
  &-wrapper {
    width: 100%;
    position: relative;
  }

  &-content {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0);
    border: solid 2px transparent;
    background: #fff;
    height: 204px;
    @include for-size(tablet-phone) {
      height: 60px;
      flex-direction: row;
      padding-left: 21px;
      svg {
        width: 25px;
        height: 25px;
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;
    }

    &__text {
      margin-top: 29px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #332256;
      @include for-size(tablet-phone) {
        margin: auto auto auto 21px;
      }

      &-container {
        @media screen and (min-width: 450px) and (max-width: 1023px) {
          display: flex;
        }
      }
    }

    &__icon {
      stroke: #332256;
    }

    & img {
      width: 230px;
      margin: 0 auto;
    }
  }

  &__label {
    & input {
      display: none;
    }

    & .icon {
      width: 25px;
      height: 25px;
      border: solid 2px #e3e3e3;
      border-radius: 50%;
      position: absolute;
      top: 17px;
      right: 17px;
      z-index: 1;
      @include for-size(tablet-phone) {
        display: none;
      }
    }

    & .icon:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      font-weight: 900;
      font-size: 12px;
      color: #000;
      text-align: center;
      opacity: 0;
      // transition: 0.2s ease-in-out all;
    }

    & input:checked + .icon {
      background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
      border: none;
      width: 29px;
      height: 29px;
    }

    & input:checked + .icon.disabled {
      background: #fff;
      border: solid 2px #e3e3e3;
      width: 25px;
      height: 25px;
    }

    & input:checked + .icon:before {
      content: url('../../../../assets/images/vectors/done.svg');

      display: flex;
      justify-content: center;
      align-items: center;
    }

    & input:checked + .icon:before {
      color: #fff;
      opacity: 1;
    }

    & input:checked ~ .checkbox-card-content {
      box-shadow: 0px 0px 6px rgba(255, 27, 113, 0.4);
      border: solid 2px #ff2d5a;
    }

    & input:checked ~ .checkbox-card-content.disabled {
      box-shadow: none;
      border: none;
    }

    & input:checked ~ .checkbox-card-content #checkbox-card-content__icon {
      stroke: #ff2d5a;
    }
  }
}
