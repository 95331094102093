// colors
$color-text-primary: #332256;
$color-text-secondary: #ff3f74;
$color-text-light: #8d91ab;

// backgrounds
$color-background-primary: #f5f6fa;
$color-background-secondary: #f0f2f8;

// fonts
$font-bebasneue: 'Bebas Neue', sans-serif;
$font-gordita: 'Gordita', sans-serif;
