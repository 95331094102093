.incomplete-functional {
  margin: 57px auto 0;
  text-align: center;

  @media (max-width: 767px) {
    padding: 0 20px;
    margin: 12px auto 0;
  }

  &__title {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    max-width: 570px;
    margin: 0 auto;
    width: 100%;
  }

  &__download {
    background-color: #fff;
    border-radius: 20px;
    max-width: 640px;
    width: 100%;
    height: 160px;
    margin: 26px auto 32px;
    padding: 0 20px;

    @media (max-width: 767px) {
      margin: 22px auto 26px;
    }

    &-title {
      padding: 33px 0 23px;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;

      @media (max-width: 767px) {
        padding: 29px 0 15px;
      }
    }

    &-img {
      display: flex;
      justify-content: center;

      &-left {
        margin-right: 23px;
        cursor: pointer;

        @media (max-width: 767px) {
          margin-right: 10px;

          @media (max-width: 374px) {
            width: 115px;
            height: 44px;
          }
        }
      }

      &-right {
        cursor: pointer;

        @media (max-width: 374px) {
          width: 115px;
          height: 44px;
        }
      }
    }
  }

  &__subtitle {
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #9599b5;
    max-width: 543px;
    width: 100%;
    margin: 0 auto 24px;
  }

  &__dummy {
    width: 100%;
    max-width: 375px;
    object-fit: cover;
  }
}
