.how-it-works-card-main {
    width: 326.67px;
    height:  fit-content;
    padding: 35px;
    position: relative;
    border: 1px solid grey;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right, rgba(255, 64, 114, 1),
    rgba(255, 111, 71, 1));
    align-items: center;
    gap:30px;
    padding: 35px;
    text-align: left;
    border-radius: 30px;
    margin: auto;
}

.how-it-works-card-title{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 36px */
}
.how-it-works-card-title1{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
}
.how-it-works-card-img{
    width: 235px;
    height: 235px;
}

@media screen and (min-width: 320px) and (max-width: 639px) {

    .how-it-works-card-main {
        width: 90%;
    }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {

    .how-it-works-card-main {
    }
}