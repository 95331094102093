.banner {
  background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
  border-radius: 22px;
  width: 100%;
  max-width: 1215px;
  width: calc(100% - 230px);
  margin: 0 115px;
  height: 363px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: center;
  letter-spacing: -0.01em;
  position: relative;

  @media (max-width: 1023px) {
    width: calc(100% - 100px);
    margin: 0 50px;
    height: 317px;
  }

  @media (max-width: 767px) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }

  &__first {
    position: absolute;
    left: 0;
    top: 90px;

    @media (max-width: 1023px) {
      left: auto;
      right: 0;
      top: 40px;
    }
  }

  &__second {
    position: absolute;
    right: 153px;
    bottom: 16px;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 45px;
    color: #fff;

    @media (max-width: 1023px) {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      padding: 0 50px;

      @media (max-width: 767px) {
        padding: 0 20px;
      }
    }
  }

  &__subtitle {
    margin-top: 11px;
    margin-bottom: 61px;
    font-size: 24px;
    line-height: 30px;
    color: #fff;

    @media (max-width: 1023px) {
      margin: 14px 0 35px;
      font-size: 17px;
      line-height: 30px;
    }
  }

  &__button {
    padding: 12px 38px;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    border: 2px solid #fff;
    background: none;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;

    @media (max-width: 1023px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      padding: 18px 37px;
    }

    &:hover {
      background-color: #fff;
      color: #ff2a5e;
    }
  }
}
