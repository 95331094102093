@import '../../../assets/styles/mixin';
.campaigns {
  width: 100%;
  max-width: 1128px;

  @include for-size(laptop) {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    padding-bottom: 30px;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  &__select {
    margin: 37px 0 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    @include for-size(tablet-laptop) {
      width: 100%;
    }

    @include for-size(phone) {
      width: 100%;
      padding-right: 20px;
      margin: 20px 0 30px;
    }

    &-left {
      display: flex;
      align-items: center;
      position: absolute;
      width: 156px;
      left: 0;

      @media (max-width: 767px) {
        padding-left: 20px;
      }
    }

    &-right {
      min-width: 33%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &-sort {
        margin: 0 0 0 30px;
        min-width: 50px;

        @media (max-width: 767px) {
          margin: 0 0 0 20px;
        }
      }
    }
  }
}
