@import '../../../../../assets/styles/mixin';

.compaign-launch {
  &__primary {
    display: flex;
    align-items: center;
    padding-bottom: 29px;
    word-break: break-word;

    @include for-size(phone) {
      flex-direction: column;
      align-items: start;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &-left,
    &-right {
      font-size: 15px;
      line-height: 21px;
    }

    &-left {
      min-width: 25%;
      color: #9599b5;
      @include for-size(phone) {
        width: 100%;
      }
    }

    &-right-active {
      font-size: 15px;
      line-height: 21px;
      color: #ff2a5e;
    }
  }

  &__secondary {
    &-container {
      display: flex;
      align-items: center;
      width: 50%;
      padding-bottom: 40px;

      &:nth-child(5),
      &:nth-child(6) {
        padding-bottom: 0;
      }

      @include for-size(phone) {
        width: 100%;
        padding-bottom: 0;
        margin-bottom: 10px;
      }

      &-item {
        font-size: 15px;
        line-height: 21px;

        &-title {
          color: #9599b5;
          padding-bottom: 8px;
          @include for-size(phone) {
            width: 100%;
            padding-bottom: 2px;
          }
        }

        &-subtitle {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}
