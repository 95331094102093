@import '../../../assets/styles/mixin';

.offers__notFound {
  width: 100%;
  p {
    text-align: center;
    font-family: Gordita;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #a1a5bf;
  }
}
.offers {
  // min-width: 65vw;

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1128px;
    width: 100%;
    margin-bottom: -20px;

    &-item {
      width: 31%;
      margin: 0 3.5% -0.7% 0;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      @include for-size(tablet-phone) {
        width: 45%;
        margin: 0 2.5%;
        &:nth-child(3n + 3) {
          margin-right: 2.5%;
        }
      }
      @media screen and (min-width: 1200px) and (max-width: 1600px) {
        width: 30%;
        margin: 0 3.5% 2.5% 0;
      }
      @include for-size(phone) {
        width: 100%;
        margin: 0 2.5%;
      }
    }
  }
}
