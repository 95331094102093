.write-us {
  width: 592px;

  .form-container-head {
    @media (max-width: 1023px) {
      max-width: 100%;
      padding: 20px;
    }
  }

  @media (max-width: 767px) {
    width: 345px;
  }

  @media (max-width: 374px) {
    width: 280px;
  }

  &__input {
    margin-bottom: 37px;
  }

  &__button {
    margin-top: 31px;
    display: flex;
    justify-content: flex-end;

    button {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__length {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    font-size: 14px;
    line-height: 19px;

    &.limit {
      color: red;
    }
  }
}
