.edit-settings {
  &__item {
    margin-bottom: 15px;
    width: 250px;

    .custom-textarea__container {
      width: 100%;
    }

    .custom-input__container {
      width: 100%;
    }

    @media (max-width: 374px) {
      width: 200px;
    }

    &:nth-child(4) {
      margin-bottom: 0;
    }
  }

  &__btn {
    margin: 25px auto 0;

    .custom-button {
      width: 100%;
    }
  }
}

.success-edit {
  &__title {
    text-align: center;
    margin: 15px;
  }
}

.edit-form {
  &__password,
  &__email {
    &-body {
      height: 50px;
      border: 1px solid #e3e4ec;
      display: flex;
      align-items: center;
      border-radius: 6px;
      cursor: text;
      margin-top: 6px;
      box-sizing: border-box;
      font-weight: 500;
      padding-left: 14px;
      outline: none;

      &:focus {
        outline: none;
        border: 1px solid #6443a7;
        box-sizing: border-box;
        box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
        border-radius: 6px;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      cursor: default;
    }
  }

  &__email {
    flex-basis: 48%;

    @media (max-width: 767px) {
      width: 100%;
      padding: 10px 0;
    }

    &-body {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}
