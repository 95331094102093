@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/mixin';

.blogger {
  padding: 103px 115px 0;

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  @media (max-width: 1023px) {
    padding: 51px 50px 0;
  }

  @media (max-width: 767px) {
    padding: 51px 20px 0;
  }

  &__top {
    text-align: center;
    letter-spacing: -0.01em;

    &-title {
      color: $color-text-secondary;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;

      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 23px;
      }
    }

    &-subtitle {
      margin: 21px auto 144px auto;
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      max-width: 800px;
      @media (max-width: 1023px) {
        font-size: 22px;
        line-height: 26px;
        margin: 11px 0 44px 0;
      }
    }
  }

  &__center {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    &-right {
      margin-left: 78px;

      @media (max-width: 1023px) {
        margin-left: 0;
        margin-top: 5px;
      }

      &-img {
        width: 100%;
        height: auto;
        object-fit: cover;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    &-left {
      width: 60%;

      @media (max-width: 1023px) {
        width: 100%;
      }

      &-item {
        display: flex;
        margin-bottom: 68px;

        @media (max-width: 1023px) {
          margin-bottom: 33px;
        }

        @media (max-width: 767px) {
          flex-direction: column;
          text-align: center;
          margin: 0 auto 33px;

          &:nth-child(3) {
            margin-bottom: 38px;
          }
        }

        &-photo {
          width: 42px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (max-width: 767px) {
            text-align: center;
            margin: 0 auto 12px;
          }

          &-img {
            @media (max-width: 1023px) {
              width: 40px;
              height: 32px;
            }
          }
        }

        &-info {
          margin-left: 42px;

          @media (max-width: 767px) {
            margin-left: 0;
          }

          &-title {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            letter-spacing: -0.01em;

            @media (max-width: 1023px) {
              font-size: 18px;
              line-height: 30px;
            }
          }

          &-subtitle {
            font-size: 16px;
            line-height: 26px;
            color: $color-text-light;

            @media (max-width: 1023px) {
              font-size: 15px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
