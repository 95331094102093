.auth-form {
  width: 400px;

  &-text {
    text-align: center;
    margin-bottom: 30px;
  }

  &__title {
    &-container {
      margin-bottom: 28px;
    }

    font-family: Gordita;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #332256;
  }
}

.auth-form-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 14px;

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.auth-form-button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &_forgot-password {
    font-size: 14px;
    text-align: center;
    color: #ff3f74;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  &-subButton {
    border: none;
    background: #f4f5f9;
    margin: 20px 0;
    cursor: pointer;
    padding: 18px 33px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    &:hover {
      opacity: 0.6;
    }
  }
}

.auth-form__description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #9599b5;
  cursor: pointer;
  margin-bottom: 15px;
}
