@import '../../../assets/styles/mixin';
.offer-card-link-button {
  outline: none;
  cursor: pointer;
  background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 1px;
  border-radius: 10px;
  transition: all 0.2s ease-in;
}
.offer-card,
.offer-statistics-card {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  width: 349px;
  margin-bottom: 43px;
  position: relative;

  @include for-size(tablet) {
    width: 98%;
    margin: 2.5% 2.5% 30px 2.5%;
  }
  @include for-size(phone) {
    width: 100%;
    margin: auto auto 30px;
  }
  @include for-size(laptop) {
    width: 100%;
    margin: auto auto 30px;
  }
  @media screen and (min-width: 1400px) and (max-width: 1600px) {
    width: 330px;
    margin: 0 3.5% 30px 0;
  }

  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px 0 #708090;
  }

  &-head {
    border-bottom: 1px solid #ededf3;
    padding: 23px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1600px) {
      padding: 23px 15px;
    }

    @media (max-width: 1022px) {
      padding: 23px 30px;
    }

    &__icons {
      position: absolute;
      display: flex;
      right: 30px;
      top: 23px;

      @media (max-width: 1600px) {
        right: 15px;
      }

      @media (max-width: 1022px) {
        right: 30px;
      }

      &-favorite {
        margin-left: 12px;
      }

      &-statistics,
      &-favorite {
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    &__img {
      width: 87px;
      height: 87px;
      border-radius: 50%;
      margin-bottom: 19px;
    }

    &__profile {
      margin-bottom: 2px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      text-align: center;

      color: #332256;
    }

    &__category {
      margin-bottom: 25px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      text-align: center;

      color: #9599b5;
    }

    &__stars {
      align-self: center;
      margin-left: auto;
    }

    &__links {
      width: 100%;
      display: flex;

      &-icon {
        margin-right: 14px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-main {
    border-bottom: 1px solid #ededf3;
    padding: 23px 30px;
    @include for-size(laptop) {
      padding: 20px 15px;
    }

    &__characteristic {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      &-desc {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #9599b5;
      }

      &-text{
        width: 20%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1; 
        -webkit-box-orient: vertical;
      }
      &-value {
        display: flex;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        text-align: right;

        color: #332256;

        &-cust {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: right;
          color: #ff2a5e;
        }
      }
    }
  }

  &-footer {
    height: 85px;
  }

  &-price,
  &-price-ads {
    display: flex;
    justify-content: space-between;
    padding: 8px 30px;

    &__desc {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #9599b5;
    }

    &__value {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      text-align: right;
      color: #ff2a5e;
    }
  }

  &-price-ads {
    padding: 18px 30px;

    @media (max-width: 1600px) {
      padding: 18px 15px;
    }

    @media (max-width: 1022px) {
      padding: 18px 30px;
    }
  }

  &-price {
    &__tertiary {
      padding: 18px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 1600px) {
        padding: 18px 15px;
      }

      @media (max-width: 1022px) {
        padding: 18px 30px;
      }

      &-desc {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #9599b5;
      }

      &-value {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        text-align: right;
        color: #ff2a5e;
      }
    }
  }

  &-price {
    @media (max-width: 1600px) {
      padding: 8px 15px;
    }

    @media (max-width: 1022px) {
      padding: 8px 30px;
    }
  }

  &-button {
    outline: none;
    cursor: pointer;
    background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
    padding: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #ffffff;
    margin: 1px;
    border-radius: 10px;
    transition: all 0.2s ease-in;

    &-selected {
      background: #34b458;

      &:after {
        content: 'Selected';
      }

      &:hover {
        background: #e5e8f2;
        color: #757995;

        &:after {
          content: 'Delete';
        }
      }

      &[disabled] {
        &:hover {
          background: #34b458;
          color: #fff;
          opacity: 0.8;

          &:after {
            content: 'offer have already accepted offer';
          }
        }
      }
    }
  }

  &-status {
    font-size: 14px;
    line-height: 20px;
    width: 100%;

    &-published {
      background: #34b458;
      color: #fff;
      padding: 17px;
      text-align: center;
    }

    &-canceled {
      background: red;
      text-align: center;
      color: #fff;
      padding: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &-comments {
        cursor: pointer;
        display: flex;
        position: absolute;
        right: 30px;
      }
    }

    &-processing {
      background: #9599b5;
      color: #fff;
      padding: 17px;
      text-align: center;
    }
    &-waiting {
      background: orange;
      color: #fff;
      padding: 17px;
      text-align: center;
    }
  }
}

.offer-card-button-tertiary {
  display: flex;
  justify-content: space-between;
  position: relative;
  // border-top: 1px solid #ededf3;

  // &:after {
  //   display: block;
  //   position: absolute;
  //   content: '';
  //   width: 1px;
  //   height: 57px;
  //   background: #ededf3;
  //   left: 50%;
  // }

  &-favorite,
  &-ban {
    margin: 20px auto;
    cursor: pointer;
  }
}

.bargain {
  padding: 0 30px;
  position: relative;

  @media (max-width: 1600px) {
    padding: 0 15px;
  }

  @media (max-width: 1022px) {
    padding: 0 30px;
  }

  &-click,
  &-disabled {
    font-size: 13px;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    right: 31px;
  }

  &-disabled {
    display: none;
  }

  &-input {
    display: flex;
    align-items: center;
    position: relative;

    &-price {
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: #ff2a5e;
    }

    &-desired {
      display: flex;
      justify-content: space-between;
    }
  }
}
.offer-solution{
  width: fit-content;
  margin: auto auto;
  margin: 20px auto 20px auto;
}
.offer-statistics {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ededf3;
  margin-top: -1px;
  box-sizing: border-box;
  transition: all 0.4s ease;

  &:hover {
    cursor: pointer;
  }

  &__left {
    display: flex;
    align-items: center;

    &-title {
      font-size: 14px;
      line-height: 20px;
      color: #332256;
      margin-left: 12px;
    }
  }

  &__right {
    border: solid #d8dbe1;
    border-width: 0 2.5px 2.5px 0;
    display: inline-block;
    padding: 4.5px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    cursor: pointer;
    max-width: 5px;
    max-height: 10px;
  }
}

.offer-statistics-card {
  margin-bottom: 36px;

  @include for-size(tablet) {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @include for-size(phone) {
    width: 100%;
    margin: auto auto 20px;
  }

  @media screen and (min-width: 1400px) and (max-width: 1600px) {
    width: 48%;
    margin: 0 4% 4% 0;

    &:nth-child(2n + 2) {
      margin: 0 0 4% 0;
    }
  }

  @media (max-width: 1399px) {
    margin-bottom: 20px;
  }
}
