.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  min-width: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow-x: auto;
  &-hidden {
    opacity: 0;
    visibility: hidden;
    transition: 0.55s opacity, 0.55s visibility;
  }
  &-visual {
    opacity: 1;
    visibility: visible;
    transition: 0.55s opacity, 0.55s visibility;
  }
}

.modal {
  max-width: 740px;
  max-height: 100vh;
  position: relative;
  &-not-show {
    display: none;
  }
  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in;

    &:hover {
      transform: rotate(90deg);
    }
  }

  &__error {
    text-align: center;
    margin-top: 10px;
    line-height: 20px;
    font-size: 18px;
  }
}

.backdrop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.backdrop::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.backdrop::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
}
