@import '../../../../../../assets/styles/variables.scss';
@import '../../../../../../assets/styles/mixin.scss';

.join {
  padding: 0 115px;
  letter-spacing: -0.01em;

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  @media (max-width: 1023px) {
    padding: 0 50px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: $color-text-secondary;
    text-align: center;

    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 23px;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    margin-top: 21px;
    text-align: center;

    @media (max-width: 1023px) {
      font-size: 22px;
      line-height: 26px;
      margin-top: 11px;
    }
  }

  &__content {
    margin-top: 112px;
    display: flex;
    justify-content: space-around;

    @media (max-width: 1023px) {
      margin-top: 50px;
      flex-direction: column-reverse;
    }

    &-left {
      margin-right: 150px;

      @media (max-width: 1350px) {
        margin-right: 50px;
      }

      @media (max-width: 1023px) {
        margin: 0 auto;
      }

      &-girl {
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }

        @media (max-width: 1350px) {
          width: 100%;
        }
      }
    }

    &-right {
      &-block {
        margin-bottom: 106px;

        @media (max-width: 1023px) {
          margin-bottom: 52px;
        }

        &:nth-child(3) {
          margin-bottom: 0;

          @media (max-width: 1023px) {
            margin-bottom: 42px;
          }
        }

        &-title {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          position: relative;
          margin-top: -50px;
          word-wrap: break-word;
          width: 300px;

          @media (max-width: 1023px) {
            width: 100%;
            font-size: 18px;
            line-height: 24px;
          }
        }

        &-subtitle {
          font-size: 16px;
          margin-top: 19px;
          line-height: 26px;
          color: $color-text-light;
          word-wrap: break-word;
          width: 469px;

          @media (max-width: 1023px) {
            width: 100%;
            margin-top: 15px;
            font-size: 15px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
