@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/mixin';

.earnings {
  &__wave {
    &-top {
      background-color: $color-background-primary;
      width: 100%;
    }
    &-bottom {
      width: 100%;
      margin-top: -100px;
    }
  }

  &__top {
    background-color: $color-background-primary;
    margin-top: -6px;
    text-align: center;

    @media (max-width: 1023px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }

    &-title {
      color: $color-text-secondary;
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      letter-spacing: -0.01em;

      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 23px;
        padding-top: 30px;
      }
    }

    &-subtitle {
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      letter-spacing: -0.01em;
      max-width: 500px;
      margin:21px auto 0;
      @media (max-width: 1023px) {
        font-size: 19px;
        line-height: 26px;
        padding: 11px 0 49px;
        margin-top: 0;
      }
    }
  }

  &__center {
    padding: 0 115px;
    position: relative;

    @include for-size(fullhd) {
      width: 1920px;
      margin: 0 auto;
    }

    &-container {
      background-color: $color-background-primary;
    }

    @media (max-width: 1023px) {
      padding: 0 50px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }

    &-left {
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media (max-width: 1023px) {
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
      }

      &-photo {
        margin-right: 2.5%;

        @media (max-width: 1023px) {
          margin-right: 0;
        }

        &-img {
          max-width: 100%;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      &-block {
        &-title {
          font-weight: 500;
          font-size: 30px;
          line-height: 30px;
          letter-spacing: -0.01em;

          @media (max-width: 1023px) {
            font-size: 20px;
            line-height: 30px;
          }
        }

        &-subtitle {
          color: $color-text-light;
          margin-top: 22px;
          max-width: 500px;
          word-wrap: break-word;

          @media (max-width: 1023px) {
            font-size: 15px;
            line-height: 22px;
            max-width: 100%;
          }
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: space-around;

      @media (max-width: 1023px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }

      &-photo {
        margin-left: 5%;

        @media (max-width: 1023px) {
          margin-left: 0;
          padding-bottom: 30px;
        }

        &-img {
          max-width: 100%;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.05);
          }
        }
      }

      &-block {
        @media (max-width: 1023px) {
          margin-bottom: 21px;
        }

        &-title {
          font-weight: 500;
          font-size: 30px;
          line-height: 30px;
          letter-spacing: -0.01em;

          @media (max-width: 1023px) {
            font-size: 20px;
            line-height: 30px;
          }
        }

        &-subtitle {
          color: $color-text-light;
          margin-top: 22px;
          max-width: 500px;
          word-wrap: break-word;

          @media (max-width: 1023px) {
            font-size: 15px;
            line-height: 22px;
            max-width: 100%;
          }
        }
      }
    }
  }
}
