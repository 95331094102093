@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/mixin';

.brands {
  padding: 81px 115px 155px 115px;

  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  @media (max-width: 1023px) {
    padding: 54px 50px 81px;
  }

  @media (max-width: 767px) {
    padding: 54px 20px 51px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.01em;
    margin: 0 0 69px;

    @media (max-width: 1023px) {
      text-align: center;
      justify-content: center;
      font-size: 22px;
      line-height: 26px;
      margin: 0 0 30px;
    }
  }

  &__center {
    display: flex;

    @media (max-width: 1250px) {
      flex-direction: column-reverse;
    }

    &-arrow-left {
      background-color: #d6d8e3;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      position: relative;
      cursor: pointer;

      &-img {
        transform: rotate(90deg);
        position: absolute;
        left: 20px;
        top: 25px;

        @media (max-width: 767px) {
          width: 10px;
          left: 10px;
          top: 12px;
        }
      }
    }

    &-arrow-right {
      background-color: $color-text-secondary;
      margin-left: 19px;
      margin-right: 62px;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      position: relative;
      cursor: pointer;

      @media (max-width: 1250px) {
        margin-right: 0;
      }

      &-img {
        transform: rotate(270deg);
        position: absolute;
        left: 25px;
        top: 25px;

        @media (max-width: 767px) {
          width: 10px;
          left: 10px;
          top: 12px;
        }
      }
    }

    &-container {
      display: flex;
      width: 100%;

      @media (max-width: 1250px) {
        margin-bottom: 30px;
      }

      &-item,
      &-item-right,
      &-item-left {
        background: #fff;
        box-shadow: 0px 0px 25px rgba(149, 153, 181, 0.15);
        border-radius: 14px;
        width: 25%;
        height: 161px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2.041%;
        object-fit: cover;
        padding: 30px;

        @media (max-width: 767px) {
          width: 46%;
          height: 80px;
          margin-left: 2%;
          margin-right: 0;

          &:nth-child(2) {
            margin-left: 3%;
            margin-right: 0;
          }
        }
        cursor: pointer;
        &:nth-child(4) {
          margin-right: 0;
        }

        &:hover {
          box-shadow: 0 0 10px 0 #708090;

        }

        &-img {
          width: 80%;
          height: 80%;

          @media (max-width: 767px) {
            width: 100%;
            height: 27px;
          }
        }

        &:nth-child(4) {
          margin-right: 0;
        }

        &-right {
          animation: leftBrandsClick 0.3s ease-in-out;

          &:first-child {
            animation: appearLeftBrands 0.3s ease-in-out;
          }
        }

        &-left {
          animation: rightBrandsClick 0.3s ease-in-out;

          &:last-child {
            animation: appearRightBrands 0.3s ease-in-out;
          }
        }
      }
    }
  }

  &__wave {
    position: absolute;
    right: 0;
    display: none;
  }
}

.arrow-left,
.arrow-right {
  background-color: $color-text-secondary;
  margin: 0 0 0 19px;
  border-radius: 50%;
  min-width: 60px;
  height: 60px;
  cursor: pointer;
  position: relative;
  transition: 0.3s;

  @media (max-width: 767px) {
    height: 30px;
    min-width: 30px;
    margin: 0 10px 0 0;
  }

  &:hover,
  &:focus {
    background-color: $color-text-light;
    top: 0.1em;
    left: 0.1em;
  }
}

.arrow-right {
  margin-right: 62px;

  @media (max-width: 1250px) {
    margin-right: 0;
  }
}

.faded-left,
.faded-right {
  background-color: $color-text-light;
}

@keyframes rightBrandsClick {
  0% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes leftBrandsClick {
  0% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes appearRightBrands {
  0% {
    transform: translateX(30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes appearLeftBrands {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.arrow-container {
  display: flex;
  @media (max-width: 1250px) {
    justify-content: center;
  }
}
