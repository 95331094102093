@import '../../../../assets/styles/mixin';

.navigation-for-news {
  display: flex;
  align-items: center;
  justify-content: space-between;
flex-direction: row-reverse;
  @include for-size(fullhd) {
    width: 1920px;
    margin: 0 auto;
  }

  &-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-img,
    &-img-active {
      margin-right: 10px;

      height: 29px;
      width: 125px;
      @media (max-width: 1150px) {
        width: 120px;
        height: 28px;
      }

      &-active {
        cursor: pointer;
      }
    }

    &-title {
      color: #a1a5bf;
      font-weight: 700;
      position: relative;
      border-left: 2px solid #a1a5bf;
      padding-left: 10px;
      height: 38px;
      display: flex;
      align-items: center;

      &.active {
        cursor: pointer;
      }

      @media (max-width: 1150px) {
        height: 28px;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  &-right {
    &-button {
      font-size: 15px;
      line-height: 26px;
      outline: none;
      padding: 10px 22px;
      text-align: center;
      cursor: pointer;
      background: #f0f2f8;
      border: 1.5px solid #a1a5bf;
      box-sizing: border-box;
      border-radius: 6px;
      font-weight: 500;
      &:after {
        content: 'Back to Home';

        @media (max-width: 1023px) {
          content: 'Home';
        }
      }

      &.white {
        background: #fff;
      }

      &:hover {
        background: linear-gradient(90deg, #ff502c 0%, #ff1b71 100%);
        padding: 11px 23px;
        border: none;
        color: #fff;
      }
    }
  }
}
