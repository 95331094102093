.referral-table {
  width: 100%;



  &__body {
    border-right: 20px;
    min-height: 500px;
    &-title {
      background: #8d91ab34;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

    }

    &-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #8d91ab34;

    }

    &-left-item {
      width: 50%;
      border-right: 1px solid #8d91ab34;
      padding: 15px;

      p {
        text-align: center;
      }

      &__title {
        padding: 20px;
        cursor: pointer;
        &:hover{
          opacity: 0.5;
        }
      }
    }

    &-right-item {
      width: 50%;

      p {
        text-align: center;
      }
    }
  }
}
