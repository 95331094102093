@import '../../../../assets/styles/mixin';

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &__photo {
    width: 38px;
    height: 38px;
    object-fit: cover;
    border-radius: 50%;
  }

  &__title {
    margin: 0 7px 0 11px;

    @include for-size(phone) {
      display: none;
    }
  }
  &__arrow {
    transition: all 0.2s linear;

    @include for-size(phone) {
      display: none;
    }

    &-active {
      transition: all 0.2s linear;
      transform: rotate(180deg);

      @include for-size(phone) {
        display: none;
      }
    }
  }

  &__dropdown {
    cursor: default;
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 25px;
    z-index: 20;

    @media (max-width: 767px) {
      right: -5px;
      width: 50px;
    }

    &-item {
      width: 50px;
      height: 40px;
      position: absolute;
      align-self: center;
      &-title {
        padding: 0 7px 0 11px;

        @media (max-width: 767px) {
          font-size: 12px;
        }
        &:hover {
          color: #ff2a5e;
          cursor: pointer;
        }
      }
    }
  }
}
