@import '../../../../../../assets/styles/mixin';

.header-bloggers {
  padding-top: 37px;
  position: relative;

  &-bottom {
    height: 91.5vh;
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 115px;

    @include for-size(fullhd) {
      width: 1920px;
      margin: 0 auto;
      height: auto;
    }

    @media (max-width: 1439px) {
      height: 141vh;
    }

    @media (max-width: 1250px) {
      flex-direction: column-reverse;
      padding: 0;
      height: 100%;
    }

    &-left {
      width: 45%;
      animation: fromLeft 0.3s ease-out;

      @media (max-width: 1250px) {
        width: 100%;
        text-align: center;
        padding: 0 115px;
        margin-bottom: 82px;
      }

      @media (max-width: 767px) {
        padding: 0 50px;
      }

      @media (max-width: 767px) {
        padding: 0 20px;
      }

      &-button {
        padding: 18px 37px;
        font-size: 18px;
        line-height: 26px;
        background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
        border-radius: 6px;
        font-weight: 500;
        color: #fff;

        &:hover {
          opacity: 0.9;
        }
      }

      &-title {
        display: block;
        font-weight: 500;
        font-size: 45px;
        line-height: 64px;
        letter-spacing: -0.01em;
        margin-top: 140px;

        @media (max-width: 1023px) {
          font-size: 25px;
          line-height: 34px;
          letter-spacing: -0.01em;
          font-weight: bold;
          margin-top: 10px;
        }
      }

      &-subtitle {
        display: block;
        font-size: 22px;
        line-height: 35px;
        margin: 22px 0 35px 0;

        @media (max-width: 1023px) {
          font-size: 16px;
          line-height: 24px;
          margin: 16px 0 50px 0;
        }
      }
    }

    &-right {
      margin-top: -80px;
      animation: fromRight 0.3s ease-out;

      @media (max-width: 1250px) {
        margin-top: -10%;
        display: flex;
        justify-content: center;
        width: 100%;
      }

      @media (max-width: 374px) {
        margin-top: -12%;
      }

      &-img {
        width: 100%;
        max-width: 852px;
        object-fit: cover;
        position: relative;
        z-index: 1;

        @media (max-width: 1250px) {
          max-width: 1250px;
          overflow: hidden;
        }

        &-icon {
          position: absolute;
          width: 263px;
          height: 412px;
          object-fit: cover;
          top: 39.6%;
          left: 62%;
          transform: translate(-50%, -50%);

          @include for-size(fullhd) {
            top: 35.82%;
          }

          @media (max-width: 1250px) {
            top: 47.6%;
            left: 51.3%;
            width: 34%;
            height: 47%;
          }
        }
      }
    }
  }
}
