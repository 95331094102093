@import '../../../assets/styles/mixin';

#statistics {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  background: #fff;
  margin-bottom: 25px;

  @media (max-width: 767px) {
    display: none;
  }
}

tr {
  width: 100%;
  display: flex;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

th,
td {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: #332256;
  min-height: 59px;
  height: auto;
  text-align: left;
  padding-left: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 1365px) {
    padding-left: 10px;
  }
}

td {
  background-color: #fff;
  color: #9599b5;

  &:nth-child(1) {
    width: 9%;

    @media (max-width: 1365px) {
      width: 11%;
    }
  }

  &:nth-child(2) {
    width: 9%;

    @media (max-width: 1365px) {
      width: 11%;
    }
  }

  &:nth-child(3) {
    width: 27%;

    color: #332256;

    @media (max-width: 1365px) {
      width: 19%;
    }
  }

  &:nth-child(4) {
    width: 8%;

    @media (max-width: 1365px) {
      width: 9%;
    }
  }

  &:nth-child(5) {
    width: 14%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    padding-right: 12px;

    @media (max-width: 1365px) {
      width: 18%;
    }
  }

  &:nth-child(6) {
    text-transform: capitalize;
    width: 6%;

    @media (max-width: 1365px) {
      width: 7%;
    }
  }

  &:nth-child(7) {
    width: 10%;

    @media (max-width: 1365px) {
      width: 9%;
    }
  }

  &:nth-child(8) {
    width: 10%;

    @media (max-width: 1365px) {
      width: 9%;
    }
  }

  &:nth-child(9) {
    width: 7%;
    color: #fff;
    background: #332256;
    transition: all 0.2s linear;
    font-size: 13px;

    @media (max-width: 1365px) {
      width: 7%;
    }

    &:after {
      content: attr(data-after-copy);
      padding-right: 20px;
      margin: 0 auto;

      @media (max-width: 1365px) {
        padding-right: 10px;
        font-size: 12px;
      }
    }

    &:hover {
      background: #ff2a5e;
      color: #fff;
    }

    &[disabled] {
      transition: all 0.2s linear;

      &:hover {
        padding-left: 0;
        text-align: center;
        background: #a1a5bf;

        &:after {
          content: attr(data-after-not);
          max-width: 61px;
          padding-right: 0;

          @media (max-width: 1365px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

th {
  &:nth-child(1) {
    border-radius: 6px 0px 0 0px;
    width: 9%;

    @media (max-width: 1365px) {
      width: 11%;
    }
  }

  &:nth-child(2) {
    width: 9%;

    @media (max-width: 1365px) {
      width: 11%;
    }
  }

  &:nth-child(3) {
    width: 27%;

    @media (max-width: 1365px) {
      width: 19%;
    }
  }

  &:nth-child(4) {
    width: 8%;

    @media (max-width: 1365px) {
      width: 9%;
    }
  }

  &:nth-child(5) {
    width: 14%;

    @media (max-width: 1365px) {
      width: 18%;
    }
  }

  &:nth-child(6) {
    width: 6%;

    @media (max-width: 1365px) {
      width: 7%;
    }
  }

  &:nth-child(7) {
    width: 10%;

    @media (max-width: 1365px) {
      width: 9%;
    }
  }

  &:nth-child(8) {
    width: 10%;

    @media (max-width: 1365px) {
      width: 9%;
    }
  }

  &:nth-child(9) {
    width: 7%;
    border-radius: 0px 6px 0 0px;

    @media (max-width: 1365px) {
      width: 7%;
    }
  }
}

.statistics-all {
  &__options {
    margin: 37px 0 24px 0;
    display: flex;
    justify-content: space-between;

    @include for-size(tablet-laptop) {
      width: 100%;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      margin: 20px 0;
    }

    &-start-date {
      padding: 0 20px 8px 20px;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #9599b5;
      display: none;

      @media (max-width: 600px) {
        display: block;
      }
    }

    &-date {
      display: flex;
      align-items: center;
      height: auto;

      &-name {
        color: #9599b5;

        @media (max-width: 600px) {
          display: none;
        }
      }

      @media (max-width: 767px) {
        justify-content: flex-start;
        min-width: 100vw;
        padding: 0 20px;
        margin: 0 auto 20px;
        font-size: 13px;
        line-height: 19px;
      }

      &-start {
        color: #9599b5;
        font-weight: 500;
      }

      &-discharge,
      &-discharge-disabled {
        cursor: pointer;
        margin-left: 30px;
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 6px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e3e4ec;

        @media (max-width: 767px) {
          margin-left: 20px;
        }

        @media (max-width: 500px) {
          margin-left: 10px;
        }

        @media (max-width: 500px) {
          display: none;
        }

        &-disabled {
          cursor: not-allowed;
        }
      }

      &-from,
      &-to {
        margin: 0 15px;
        font-weight: 500;

        @media (max-width: 767px) {
          margin: 0 8px;
        }
      }

      &-from {
        @media (max-width: 600px) {
          margin: 0 8px 0 0;
        }

        @media (max-width: 374px) {
          margin: 0 5px 0 0;
        }
      }

      &-to {
        @media (max-width: 600px) {
          margin: 0 8px;
        }

        @media (max-width: 600px) {
          margin: 0 5px;
        }
      }
    }

    &-select {
      display: flex;

      @media (max-width: 767px) {
        padding: 0 20px;
        min-width: 100vw;
        justify-content: flex-end;
      }

      &-group {
        display: flex;

        &-discharge,
        &-discharge-disabled {
          cursor: pointer;
          margin-left: 20px;
          width: 50px;
          min-width: 50px;
          height: 50px;
          border-radius: 6px;
          background: #fff;
          align-items: center;
          justify-content: center;
          border: 1px solid #e3e4ec;
          left: 0;
          position: absolute;
          display: none;

          @media (max-width: 500px) {
            display: flex;
          }

          &-disabled {
            cursor: not-allowed;
          }
        }

        &-sort {
          margin: 0 0 0 30px;
          min-width: 50px;

          @media (max-width: 767px) {
            margin: 0 0 0 20px;
          }
        }
      }
    }
  }
}

table {
  border-collapse: collapse;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  border-style: hidden;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
  -webkit-bottom-left-radius: 6px;
}

tbody {
  &:last-child {
    tr {
      &:last-child {
        td {
          &:last-child {
            border-radius: 0px 0px 6px 0px;
            &:hover {
              border-radius: 0px;
            }
          }
        }
      }
    }
  }
}
