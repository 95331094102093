.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #332256;
  }
}

.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.custom-checkbox + label::before {
  content: '';
  display: inline-block;
  width: 18.78px;
  height: 19.78px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

// .custom-checkbox:not(:disabled):active + label::before {
//   // background-color: #ff2a5e;
// }

.custom-checkbox:checked + label::before {
  width: 18.78px;
  height: 19.78px;
  background-position: center;
  background-size: 10px;
  background-color: #ff2a5e;
  border: none;
  background-image: url('../../../../../assets/images/vectors/done.svg');
}
