.finance-item {
  &__td,
  &__th {

    &:nth-child(1) {
      width: 10%;
    }

    &:nth-child(2) {
      width: 30%;
      cursor: pointer;
      &:hover{
        opacity: 0.9;
      }
    }

    &:nth-child(3) {
      width: 20%;
      cursor: pointer;
      &:hover{
        opacity: 0.9;
      }
    }

    &:nth-child(4) {
      width: 20%;
    }

    &:nth-child(5) {
      width: 20%;
      cursor: pointer;
      &:hover{
        opacity: 0.9;
      }
    }

    &:nth-child(6) {
      width: 20%;
    }
  }

  &__td {
    &:nth-child(2) {
      color: #332256;
    }
    &:nth-child(3) {
      color: #9599b5;
    }
    &-up{
      position: relative;
      &:after{
        top: 32% ;
        left: -3px;
        position: absolute;
        content:'' ;
        border: 6px solid transparent; border-bottom: 6px solid green;
      }
    }
    &-down{
      position: relative;

      &:after{
        top: 42%;
        left: -3px;
        position: absolute;
        content:'' ;
        border: 6px solid transparent; border-top: 6px solid red;
      }
    }
  }
}
