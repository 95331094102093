.download-mobile {
  height: 470px;
  width: calc(100% - 100px);
  background: linear-gradient(90deg, #ff7245 0%, #ff3f74 100%);
  border-radius: 30px;
  margin: -216px auto 0;
  position: relative;
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }

  @media (max-width: 767px) {
    width: calc(100% - 40px);
  }

  &__title,
  &__subtitle {
    letter-spacing: -0.01em;
    color: #ffffff;
  }

  &__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    width: 100%;
    padding: 44px 50px 0;
    max-width: 40%;
    margin: auto;
    @media (max-width: 600px) {
      max-width: 80%;
    }
    @media (max-width: 767px) {
      padding: 44px 20px 0;
    }
  }

  &__subtitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    padding-top: 18px;
    text-align: center;
    padding: 18px 50px 0;
    max-width: 60%;
    margin: auto;
    @media (max-width: 600px) {
    max-width: 80%;
  }

    @media (max-width: 767px) {
      padding: 18px 20px 0;
    }
  }

  &__img {
    position: absolute;
    bottom: 0;
    left: 2.5%;
    right: 0;
    margin: 0 auto;

    @media (max-width: 767px) {
      left: 5%;
    }

    @media (max-width: 375px) {
      left: 8%;
    }
  }

  &__app {
    display: flex;
    padding-top: 28px;
    margin: 0 auto;
    justify-content: center;

    &-apple,
    &-android {
      width: 127px;
      height: 40px;
    }

    &-apple {
      margin-right: 10px;
    }
  }
}
