
.refill-popup {
  background: #fff;
  padding: 30px;
  width: 100%;
  border-radius: 6px;

  .purchase__container {
  flex-direction: column;
    width: 100%;
    .purchase__input{width: 400px}
  }

}
