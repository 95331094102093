.reviews-main {
    width: 100%;
    height:  fit-content;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
}
.reviews-card{
    display: flex;
    flex-direction: column;
    background-color: rgba(245, 245, 245, 1);
    padding: 50px;
    width: 1029px;
    height: 603px;
    border-radius: 
    30px;

}
.corousel-element{
    width: 1029px;
    height: 603px;
    background-color: rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
}

.reviews-card-image{
    width: 115px;
    height: 115px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.reviews-card-comment{
    width: 784px;
    height: 210px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    color: rgba(51, 34, 86, 1);
    font-size: 35px;
    line-height:  42px;
    text-align: center;
    font-weight: 500;
}

.reviews-card-name{
    width: 784px;
    margin-left: auto;
    margin-right: auto;
    color: rgba(51, 34, 86, 1);
    font-size: 25px;
    line-height:  30px;
    text-align: center;
    font-weight: 500;
}

.reviews-card-company{
    width: 784px;
    margin-left: auto;
    margin-right: auto;
    color: rgba(51, 34, 86, 1);
    font-size: 15px;
    line-height:  18px;
    text-align: center;
    font-weight: 500;
}

.centered-content{
    width:100%;
    height: fit-content;
}

.carousel-container {
    max-width: 80%; /* Максимальная ширина слайдера */
    margin: 50px auto; /* Центрирование слайдера на странице */
  }


.reviews-block-text{
    font-size: 15px;
    position: relative;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
    margin-top: 15px;
}
.reviews-block {
    position: relative;
   
    border-radius: 100px;
    border: 1px solid black;
    height: 54px;
    width: 165px;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
}
.reviews-title {
    position: relative;
    font-size: 55px;
    line-height: 66px;
    text-align: center;
    font-weight: 600;
    color: rgba(51, 34, 86, 1);
    height: 140px;
    width: 541px;
    margin-left: auto;
    margin-right: auto;
}


@media screen and (min-width: 1024px) and (max-width: 1439px) {

}

@media screen and (min-width: 640px) and (max-width: 1023px) {

}

@media screen and (min-width: 392px) and (max-width: 639px) {
    .reviews-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 320px) and (max-width: 391px) {
    .reviews-main{
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 320px) and (max-width: 1200px) {

    .reviews-title{
        width: 80%;
        height: fit-content;
        text-align: left;
        font-size: 40px;
        line-height: 48px;
    }
    .reviews-card{
        display: flex;
        width: 330px;
        padding: 35px;
        height: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;
    }
    .reviews-card-comment{
        color: #332256;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        width: 80%;
        text-align: left;
        height: fit-content;
        line-height: 150%; /* 30px */
    }

    .reviews-card-name{
        color: #332256;
        width: 80%;
        text-align: left;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        margin-top: 5px;

        line-height: 120%; /* 18px */
    }
    .reviews-card-image{
        width: 80px;
        height: 80px;
        margin: 20px;
        margin-left: 40px; 
    }
    .reviews-card-company{
        color: #332256;
        width: 80%;
        text-align: center;
        font-family: Inter;
        font-size: 10px;
        text-align: left;
        font-style: normal;
        font-weight: 500;
        margin-top: 5px;

        line-height: 120%; /* 12px */
    }
    
}


