@import '../../../../../assets/styles/mixin';

.custom-input,
.custom-input__erros,
.custom-input-money {
  flex-grow: 1;
  padding: 14px 18px;
  background: #ffffff;
  border: 1px solid #e3e4ec;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #332256;

  &-money {
    background: #ffffff url('../../../../../assets/images/vectors/money.svg') right 15px center no-repeat;
    padding: 7px;
    margin-left: 7px;
    width: 60%;
    right: 0;
    position: absolute;
  }

  &__label {
    margin: 6px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;

    color: #332256;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__dual {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-item {
      width: 49%;
    }
  }

  &__single {
    &-item {
      flex-grow: 1;

      &-dynamic-text {
        right: 30px;
        top: 32%;
        color: #6b6b6b;
        position: absolute;
        @include for-size(tablet) {
          right: 25px;

          top: 36%;
          font-size: 14px;
        }
        @include for-size(phone) {
          top: 36%;
          font-size: 13px;
        }
      }
    }
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #b8bac8;
  }

  &-container {
    display: flex;
    align-items: center;
    width: 100%;
    @include for-size(phone) {
      flex-direction: column;
      input {
        width: 100%;
      }
    }

    &-money {
      align-items: flex-start;
    }
  }
}

.custom-input {
  @include for-size(tablet-phone) {
    width: 100%;
  }

  &:focus {
    border: 1px solid #6443a7;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;
  }
}

.custom-input__erros {
    outline: none;
    border: 2px solid red;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;

}

input[type='url'] {
  font-family: system-ui !important;
}

// removing input background colour for chrome autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
