@import "../../../assets/styles/mixin";

.step-description {
  &__container {
    // width: 349px;
    margin-left: 53px;
    width: 100%;
    @include for-size(tablet-phone) {
      display: none;
    }
    @include for-size(laptop) {
      width: 90%;
      margin-left: 0;
      img{
        width: 100%;

      }

    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;

    color: #332256;

    margin-bottom: 18px;
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: #9599b5;
  }

  &__img {
    margin-top: 92px;
  }

}
