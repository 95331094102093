.get-demo-main {
    width: 100%;
    height:  fit-content;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
}

.get-demo-title {
    position: relative;
    font-size: 70px;
    line-height: 77px;
    text-align: center;
    font-weight: 700;
    color: rgba(51, 34, 86, 1);
    height: 231px;
    width: 861px;
    margin-left: auto;
    margin-right: auto;
}

.get-demo-title-1 {
    font-size: 70px;
    line-height: 77px;
    text-align: center;
    font-weight: 600;
    background: linear-gradient(rgba(255, 64, 114, 1), rgba(255, 111, 71, 1));
    -webkit-background-clip: text;
    color: transparent;
}

.get-demo-buttons {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: center;
}

.get-demo-img-cont {
    margin: auto;
    margin-top: 75px;
    width: 1200px;
    height: 850px;
    position: relative;
}
.get-demo-img {
    width: 100%;
    height: 100%;
    position: relative;
}
.get-demo-img-frame {
    position: absolute;
    top: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    border: 3px solid; /* Указываем ширину границы */
    border-image: linear-gradient(rgba(255, 64, 114, 1), rgba(255, 111, 71, 1)) 1;
}

.get-demo-img-brends{
    height: 120px;
    width: 1158px;
}
@media screen and (min-width: 320px) and (max-width: 1200px) {
    .get-demo-img-cont {
        margin: auto;
        margin-top: 75px;
        width: 100%;
        height: 0; /* Начальная высота будет нулевой */
        padding-bottom: 56.25%; /* Соотношение 16:9 (850 / 1200) */
        position: relative;
        overflow: hidden; /* Скрытие лишних частей изображения */
    }
    .get-demo-main {
        margin-top: 150px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {

}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .get-demo-title {
        font-size: 65px;
        line-height: 78px;
    }
    
    .get-demo-title-1 {
        font-size: 65px;
        line-height: 78px;
    }
}

@media screen and (min-width: 392px) and (max-width: 639px) {
    .get-demo-title {
        font-size: 45px;
        line-height: 54px;
    }
    
    .get-demo-title-1 {
        font-size: 45px;
        line-height: 54px;
    }
}

@media screen and (min-width: 320px) and (max-width: 391px) {

    .get-demo-title {
        font-size: 45px;
        line-height: 54px;
    }
    
    .get-demo-title-1 {
        font-size: 45px;
        line-height: 54px;
    }
}
@media screen and (min-width: 320px) and (max-width: 1023px) {

    .get-demo-main{
        padding-top: 22px;
        padding-bottom: 22px;
    }
    .get-demo-title {
        text-align: left;
        height: fit-content;
        width: 90%;
        font-weight: 700;
    }
    .get-demo-title-1 {
        text-align: left;
        height: fit-content;
        width: 90%;
        font-weight: 700;
    }

      
      .get-demo-img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .get-demo-buttons {
        flex-direction: column;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .get-demo-img-brends{
        height: 120px;
        width: 100%;
    }
    
}

