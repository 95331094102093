.add_instagram_account{
  padding: 40px 40px 20px;
  background:#fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  button{
    width: 100%;
    margin: 30px 0 0;
  }
  &__or{
    margin: 10px 0;
    position: relative;
    font-size: 17px;
    &:before{
      content: '';
      height: 1px;
      background: #757995;
      width: 70px;
      position: absolute;
      right: 40px;
      top:10px

    }
    &:after{
      content: '';
      height: 1px;
      background: #757995;
      position: absolute;
      width: 70px;
      left: 40px;

      top:10px
    }
  }
  &__button-insta{
    img{
    width: 45px;
    height: 45px;
      cursor: pointer;
      &:hover{
        opacity: 0.5;
      }
  }}
}