@import '../../../../../../assets/styles/variables';

.header {
  &-top {
    position: relative;
    z-index: 3;
    &-navigation {
      width: 70%;

      &-item {
        margin-right: 40px;
        position: relative;
        cursor: pointer;
        background: none;
        border-radius: 0;
        font-weight: 400;

        @media (min-width: 1024px) and (max-width: 1100px) {
          margin-right: 25px;
        }

        &-btn {
          background: none;
          border-radius: 0px;
          font-weight: 400;
          color: $color-text-primary;
          transition: all 0.1s linear;

          &:hover {
            color: #ff3f74;
          }

          &.active {
            &:hover {
              color: #332355;
            }
          }
        }

        &-btn-dark {
          color: #fff;
          background: none;

          &:hover {
            color: #ff3f74;
          }

          &.active {
            &:hover {
              color: #fff;
            }
          }
        }
      }

      &-item > .active {
        &::after {
          content: '';
          width: 7px;
          height: 7px;
          border-radius: 50%;
          position: absolute;
          z-index: 5;
          top: 25px;
          left: 0;
          right: 0;
          margin: 0 auto;
          background-color: #ff3f74;
        }
      }
    }
  }
}
