.custom-textarea,
.custom-textarea__erros {
  padding: 14px 18px;
  height: 150px;
  outline: none;
  resize: none;
  border: 1px solid #e3e4ec;
  box-sizing: border-box;
  border-radius: 6px;
  background: #fff;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  color: #332256;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #b8bac8;
  }

  &__label {
    margin-bottom: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;

    color: #332256;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }
}

.custom-textarea_success {
  &:focus {
    &:focus {
      outline: none !important;
      border: 1px solid #6443a7;
      box-sizing: border-box;
      box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
      border-radius: 6px;
    }
  }
}

.custom-textarea_errors {
  outline: none !important;
  outline-color: red;
  border: 2px solid red;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
  border-radius: 6px;

  &:focus {
    outline: none !important;
    outline-color: red;

    border: 2px solid red;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px rgba(100, 67, 167, 0.25);
    border-radius: 6px;
  }
}
