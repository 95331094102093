@import '../../../assets/styles/variables.scss';

.support {
  margin: 36px 0 0 0;
  display: flex;

  @media (max-width: 1300px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 767px) {
    padding: 0 20px 20px;
    margin: 20px 0 0 0;
  }

  &__left {
    width: 65.5%;
    margin: 0 32px 0 0;

    @media (max-width: 1600px) {
      margin: 0 20px 0 0;
    }

    @media (max-width: 1300px) {
      width: 100%;
      margin: 20px 0 0 0;
    }

    &-info {
      display: none;

      @media (max-width: 1023px) {
        display: block;
        margin: 0 0 20px 0;
      }
    }

    &-write {
      display: none;

      @media (max-width: 767px) {
        display: block;
        margin-top: 20px;
      }
    }
  }

  &__right {
    width: 34.5%;

    @media (max-width: 1300px) {
      width: 100%;
      // display: flex; // return after added support AddTicket
      // align-items: center; // return after added support AddTicket
      // justify-content: space-between; // return after added support AddTicket
    }

    &-write {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}
